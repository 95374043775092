import { Spin } from "antd";
import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    Dispatch,
    SetStateAction,
} from "react";
import { Navigate, useLocation } from "react-router-dom";
import { User } from "../types/UserGroup";
import { getMe } from "./api/UserClient";

const UserStateContext = createContext({
    me: {} as Partial<User>,
    setMe: {} as Dispatch<SetStateAction<Partial<User>>>,
});

const loginPath = "/login";
const isLocalhost = window.location.hostname === "localhost";

/***
 *
 * exclude these paths from authentication check
 */
const localPaths = isLocalhost ? ["/internal"] : [];
const excludedPaths = [loginPath, "/signup", "/app/signup", "/invite", ...localPaths];

const UserStateProvider = ({
    children,
    value = {
        name: "Waiting name",
        avatar: "https://joeschmoe.io/api/v1/random",
    } as User,
}: {
    children: React.ReactNode;
    value?: Partial<User>;
}) => {
    const [me, setMe] = useState(value);
    const [location] = useState(useLocation());
    const [redirect, setRedirect] = useState(false);
    const currentLocation = useLocation();
    const isExcluded = excludedPaths
        .map((p) => currentLocation.pathname.indexOf(p) !== -1)
        .reduce((p, v) => p || v, false);

    useEffect(() => {
        getMe()
            .then((rMe) => setMe(rMe))
            .catch((error) => {
                if (error.code === 401) {
                    setRedirect(true);
                }
            });
    }, []);

    return redirect && !isExcluded ? (
        <Navigate
            to={{
                pathname: loginPath,
                search: `?path=${location.pathname}`,
            }}
        />
    ) : me.email || isExcluded ? (
        <UserStateContext.Provider value={{ me, setMe }}>{children}</UserStateContext.Provider>
    ) : (
        <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
            <Spin size="large" style={{ top: "45%", position: "absolute" }}></Spin>
        </div>
    );
};

const useUserState = () => {
    const context = useContext(UserStateContext);
    if (!context) {
        throw new Error("useUserState must be used within a UserStateContext");
    }
    return context;
};

export { UserStateProvider, useUserState };
