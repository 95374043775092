import React, { CSSProperties, useEffect, useState } from "react";
import { Spin } from "antd";
import { VendorHeatmap } from "./components/VendorHeatmap";
import { PeriodDates } from "../date/TimePeriods";
import { getIndustryStatistics } from "../../common/api/DashboardClientV2";
import { handleError } from "../../common/ErrorHandling";
import { Industry, IndustryStatistics, Sector } from "../../types/DashboardTypes";
import { getYearStartEnd } from "../../types/TimeTypes";

interface VendorHeatmapPanelProps {
    style?: CSSProperties;
    selectedYear?: number;
}

const formatteHeatmapData = (industries: IndustryStatistics[]): Sector[] => {
    const industryMap = {} as Record<string, Industry>;
    for (const industry of industries) {
        if (!industryMap[industry.industryName]) {
            industryMap[industry.industryName] = {
                sectorName: industry.sectorName,
                name: industry.industryName,
                value: industry.industryTotal,
                companies: [],
            };
        }
        industryMap[industry.industryName].companies.push({
            name: industry.vendorName,
            value: industry.vendorTotal,
            esgScore: industry.esgScore,
            simulationScore: industry.simulationScore,
        });
    }

    const sectorMap = {} as Record<string, Sector>;
    for (const industry of Object.values(industryMap)) {
        if (!sectorMap[industry.sectorName]) {
            sectorMap[industry.sectorName] = {
                name: industry.sectorName,
                industries: [],
            };
        }
        sectorMap[(industry as any).sectorName].industries.push(industry);
    }

    return Object.values(sectorMap);
};

export const VendorHeatmapPanel: React.FC<VendorHeatmapPanelProps> = ({ style, selectedYear }) => {
    const [loading, setLoading] = useState(true);
    const [sectors, setSectors] = useState([] as Sector[]);

    const refreshData = async (dates: PeriodDates) => {
        if (!dates.startDate || !dates.endDate) return;

        setLoading(true);

        try {
            const data = await getIndustryStatistics(dates);
            setSectors(formatteHeatmapData(data));
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!selectedYear) return;
        const dates = getYearStartEnd(selectedYear);
        refreshData(dates);
    }, [selectedYear]);

    return (
        <div style={style}>
            <Spin spinning={loading}>
                <VendorHeatmap sectors={sectors} />
            </Spin>
        </div>
    );
};
