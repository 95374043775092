import { PeriodDates } from "../../components/date/TimePeriods";
import { FilterGroup, PageRequest, PagerObject, Sort } from "../../types/DataFetchingTypes";
import { AverageSimulationScore } from "../../types/NegotiationTypes";
import {
    OFOPerationType,
    OrderForm,
    OrderFormAnalysis,
    OrderFormAnalysisResponse,
    OrderFormGroupOperation,
    OrderFormGroupOperationResponse,
    OrderFormLever,
    OrderFormLeverDetail,
    OrderFormResponse,
    OrderFormSKU,
    OrderFormUpdateResponse,
} from "../../types/OrderFormTypes";
import { SortFilterPaged } from "../CommonInterfaces";
import { convertDateToString } from "../DateUtil";
import { safeDELETE, safeGET, safePOST, safePUT } from "./SafeClient";

export function listOrdersA(
    //pageRequest: PageRequest,
    //sorter: Sort[],
    //filter: FilterGroup[],
    pager: SortFilterPaged<OrderForm>,
    abort?: any
): Promise<PagerObject<OrderForm[]>> {
    const sorting = pager.sort
        .map((v) => `sort[]=${v.attribute}&order[]=${v.order}`)
        .reduce((p, v) => `${p}&${v}`, "");
    const paging = `current=${pager.pager.pagination.current}&pageSize=${pager.pager.pagination.pageSize}`;
    const filtering = pager.filter
        .map((v) => {
            return v.filter
                .map((fv) => {
                    return `filter[]=${fv.attribute}&operand[]=${fv.operand}&value[]=${encodeURIComponent(fv.value)}&group[]=${v.groupId}&gop[]=${v.groupType}`;
                })
                .join("&");
        })
        .reduce((p, v) => `${p}&${v}`, "");
    return safeGET(`/api/orderform?${paging}${sorting}${filtering}`, abort);
}
export function listOrders(
    pageRequest: PageRequest,
    sorter: Sort[],
    filter: FilterGroup[],
    expired?: boolean,
    vendorKeys?: string[],
    notificationStatus?: string[],
    tags?: string[],
    timespan?: PeriodDates,
    abort?: any
): Promise<PagerObject<OrderForm[]>> {
    const sorting = sorter
        .map((v) => `sort[]=${v.attribute}&order[]=${v.order}`)
        .reduce((p, v) => `${p}&${v}`, "");
    const paging = `current=${pageRequest.current}&pageSize=${pageRequest.pageSize}`;
    const filtering = filter
        .map((v) => {
            return v.filter
                .map((fv) => {
                    return `filter[]=${fv.attribute}&operand[]=${fv.operand}&value[]=${fv.value}&group[]=${v.groupId}&gop[]=${v.groupType}`;
                })
                .join("&");
        })
        .reduce((p, v) => `${p}&${v}`, "");
    let expiredQuery = "";
    if (expired === true || expired === false) {
        expiredQuery = `&expired=${expired}`;
    }
    let vendorQuery = "";
    if (vendorKeys?.length) {
        vendorQuery = vendorKeys
            .map((v) => `vendorKey[]=${encodeURIComponent(v)}`)
            .reduce((p, v) => `${p}&${v}`, "");
    }
    let notificationQuery = "";
    if (notificationStatus?.length) {
        notificationQuery = notificationStatus
            .map((v) => `notificationStatus[]=${v}`)
            .reduce((p, v) => `${p}&${v}`, "");
    }
    let tagQuery = "";
    if (tags?.length) {
        tagQuery = tags.map((v) => `tag[]=${v}`).reduce((p, v) => `${p}&${v}`, "");
    }

    let timespanQuery = "";
    if (timespan) {
        const startDate = convertDateToString(timespan.startDate);
        const endDate = convertDateToString(timespan.endDate);
        timespanQuery = `&startDate=${startDate}&endDate=${endDate}`;
    }
    return safeGET(
        `/api/orderform?${paging}${sorting}${filtering}${expiredQuery}${vendorQuery}${notificationQuery}${tagQuery}${timespanQuery}`,
        abort
    );
}

export function getOrderForms(orderIds: number[], abort?: any): Promise<OrderFormResponse[]> {
    const ids = orderIds.map((oid) => `orderFormId[]=${oid}`).join("&");
    return safeGET(`/api/orderform/m?${ids}`);
}

export function getOrderForm(orderId: number, abort?: any): Promise<OrderFormResponse> {
    return safeGET(`/api/orderform/${orderId}`, abort);
}

export function updateOrderForm(
    orderForm: OrderForm,
    abort?: any
): Promise<OrderFormUpdateResponse> {
    return safePUT(`/api/orderform/${orderForm.id}`, orderForm, abort);
}

export function createOrderForm(orderForm: OrderForm, abort?: any): Promise<OrderFormResponse> {
    return safePOST("/api/orderform", orderForm);
}

export function getSkus(orderFormId: number, abort?: any): Promise<OrderFormSKU[]> {
    return safeGET(`/api/orderform/${orderFormId}/sku`);
}

export function updateSKU(sku: OrderFormSKU, abort?: any): Promise<OrderFormSKU> {
    return safePUT(`/api/orderform/${sku.orderFormId}/sku/${sku.id}`, sku);
}

export function updateSKUs(
    orderFormId: number,
    skus: OrderFormSKU[],
    abort?: any
): Promise<OrderFormSKU[]> {
    return safePUT(`/api/orderform/${orderFormId}/sku/batch`, skus);
}

export function deleteSKU(sku: OrderFormSKU, abort?: any): Promise<OrderFormSKU> {
    return safeDELETE(`/api/orderform/${sku.orderFormId}/sku/${sku.id}`, sku);
}
export function newSKU(orderFormId: number, sku: OrderFormSKU, abort?: any): Promise<OrderFormSKU> {
    return safePOST(`/api/orderform/${orderFormId}/sku`, sku);
}

export function getOrderGroups(orderFormId: number, abort?: any): Promise<number[]> {
    return safeGET(`/api/orderform/${orderFormId}/groups`, abort);
}

export function addGroupToOrder(
    orderFormId: number,
    groups: number[],
    abort?: any
): Promise<OrderFormGroupOperationResponse> {
    const request = {
        type: OFOPerationType.ADD,
        values: groups,
    } as OrderFormGroupOperation;
    return safePUT(`/api/orderform/${orderFormId}/groups`, request, abort);
}

export function removeGroupFromOrder(
    orderFormId: number,
    groups: number[],
    abort?: any
): Promise<OrderFormGroupOperationResponse> {
    const request = {
        type: OFOPerationType.REMOVE,
        values: groups,
    } as OrderFormGroupOperation;
    return safeDELETE(`/api/orderform/${orderFormId}/groups`, request, abort);
}

export function getPdfFile(orderFormId: number): Promise<OrderFormGroupOperationResponse> {
    return safeGET(`/api/orderform-document/${orderFormId}`);
}

export function getLevers(orderFormId: number, abort?: any): Promise<OrderFormLeverDetail[]> {
    return safeGET(`/api/orderform/${orderFormId}/lever`, abort);
}

export function deleteOrderForm(orderFormId: number, abort?: any): Promise<OrderForm> {
    return safeDELETE(`/api/orderform/${orderFormId}`, abort);
}

export function getAverageOrderScore(abort?: any): Promise<AverageSimulationScore> {
    return safeGET(`/api/orderform-score/average`, abort);
}

export function addOrderLever(lever: OrderFormLever, abort?: any): Promise<OrderFormLever> {
    return safePOST(`/api/orderform/${lever.orderFormId}/lever`, lever, abort);
}

export function updateOrderLever(lever: OrderFormLever, abort?: any): Promise<OrderFormLever> {
    return safePUT(`/api/orderform/${lever.orderFormId}/lever/${lever.id}`, lever, abort);
}

export function deleteOrderLever(lever: OrderFormLever, abort?: any): Promise<OrderFormLever> {
    return safeDELETE(`/api/orderform/${lever.orderFormId}/lever/${lever.id}`, lever, abort);
}

export function getExpiringOrders(
    timespan: PeriodDates,
    abort?: any
): Promise<{ data: OrderForm[]; total: number }> {
    const startDate = convertDateToString(timespan.startDate);
    const endDate = convertDateToString(timespan.endDate);

    return safeGET(`/api/orderform/expiring?startDate=${startDate}&endDate=${endDate}`, abort);
}

export function getOrderFormAnalyses(
    orderFormId: number,
    abort?: any
): Promise<OrderFormAnalysisResponse[]> {
    return safeGET(`/api/orderform/${orderFormId}/analysis`, abort);
}

export function createOrderFormAnalyses(
    orderFormId: number,
    analyses: OrderFormAnalysis[],
    abort?: any
): Promise<OrderFormAnalysis[]> {
    return safePOST(`/api/orderform/${orderFormId}/analysis`, analyses, abort);
}

export function updateOrderFormAnalyses(
    orderFormId: number,
    analyses: OrderFormAnalysis[],
    abort?: any
): Promise<OrderFormAnalysis[]> {
    return safePUT(`/api/orderform/${orderFormId}/analysis`, analyses, abort);
}

export function getOrdersByOrgId(orgId: number): Promise<OrderForm[]> {
    return safeGET(`/api/orderform/org/${orgId}`);
}

export function deleteOrderOfAnOrg(
    orgId: number,
    orderFormId: number,
    abort?: any
): Promise<OrderForm> {
    return safeDELETE(`/api/orderform/${orderFormId}/org/${orgId}`, abort);
}
