import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Space, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { NewVendorModal } from "../../vendor/NewVendorModal";
import { Vendor } from "../../../types/VendorTypes";

interface SupplierCellProps {
    vendorKey?: string;
    name?: string;
    onCreate?: (vendor: Vendor) => void;
}

export const SupplierCell: React.FC<SupplierCellProps> = ({ vendorKey, name, onCreate }) => {
    const [showNewVendorModal, setShowNewVendorModal] = useState(false);

    const newVendorModal = showNewVendorModal ? (
        <NewVendorModal
            onCreate={(vendor) => {
                onCreate && onCreate(vendor);
                setShowNewVendorModal(false);
            }}
            onCancel={() => setShowNewVendorModal(false)}
            value={name}
        />
    ) : (
        <></>
    );

    return (
        <>
            {vendorKey ? (
                <Link to={`/vendor/${vendorKey}`}>{name}</Link>
            ) : (
                <Typography.Link
                    onClick={() => setShowNewVendorModal(true)}
                    data-testid="add-supplier"
                >
                    <Space>
                        <PlusOutlined />
                        Add supplier
                    </Space>
                </Typography.Link>
            )}
            {newVendorModal}
        </>
    );
};
