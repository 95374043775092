import { EChartsOption } from "echarts";
import { formatNumberWithSuffix } from "../../../common/Number";
import { IncomeStatement } from "../../../types/FinanceTypes";
import { Period } from "../../../types/SupplierTypes";
import { getQuarterDate } from "../../../types/TimeTypes";

enum SeriesName {
    Revenue = "Revenue",
    NetIncome = "Net income",
}

export interface ChartOptionProps {
    incomes: IncomeStatement[];
    period: Period;
}

export function getChartOption(props: ChartOptionProps): EChartsOption {
    return {
        tooltip: {
            trigger: "item",
            axisPointer: {
                type: "cross",
            },
        },
        grid: {
            left: 0,
            right: 0,
            top: 10,
            bottom: 0,
            containLabel: true,
        },
        xAxis: [
            {
                type: "category",
                boundaryGap: false,
                data: props.incomes.map((item, index) => {
                    const isQuterly = props.period === Period.Quarterly;
                    const label = isQuterly ? getQuarterDate(new Date(item.date)) : item.fiscalYear;
                    const firstPadding = isQuterly ? [0, 0, 0, 40] : [0, 0, 0, 20];
                    const lastPadding = isQuterly ? [0, 46, 0, 0] : [0, 30, 0, 0];
                    const padding =
                        index === 0
                            ? firstPadding
                            : index === props.incomes.length - 1
                              ? lastPadding
                              : 0;
                    return {
                        value: label,
                        textStyle: {
                            padding,
                        },
                    };
                }),
                axisLabel: {
                    color: "#666666",
                    fontSize: 10,
                },
                axisLine: {
                    lineStyle: {
                        color: "#BFBFBF",
                    },
                },
                axisTick: {
                    show: false,
                },
            },
        ],
        yAxis: [
            {
                type: "value",
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: "#BFBFBF",
                    },
                },
                axisLabel: {
                    color: "#666666",
                    fontSize: 10,
                    formatter: (value: any) => {
                        return formatNumberWithSuffix(value) as string;
                    },
                },
                splitLine: {
                    show: false,
                },
            },
        ],
        series: [
            {
                name: SeriesName.NetIncome,
                type: "line",
                stack: "Total",
                smooth: true,
                symbol: "none",
                lineStyle: {
                    color: "#1890FF",
                },
                areaStyle: {
                    color: "#1890FF",
                },
                data: props.incomes.map((item) => item.netIncome),
            },
            {
                name: SeriesName.Revenue,
                type: "line",
                stack: "Total",
                smooth: true,
                symbol: "none",
                lineStyle: {
                    color: "#C1F2A9",
                },
                areaStyle: {
                    color: "#C1F2A9",
                },
                data: props.incomes.map((item) => item.revenue - item.netIncome),
            },
        ],
    };
}
