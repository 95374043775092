import React, { useState, useEffect, CSSProperties } from "react";
import moment from "moment";
import { Popover, Calendar, Spin, Row, Col, Space, Select, Segmented } from "antd";
import { CalendarMode, CalendarProps } from "antd/lib/calendar/generateCalendar";
import { useSearchParams } from "react-router-dom";
import { VendorSummary } from "../../common/DashboardInterfaces";
import { getDashboardData } from "../../common/api/DashboardClient";
import { DashboardVendorTimeSeries } from "../../types/OrderFormTypes";
import { getVendors } from "../../common/api/VendorClient";
import { extractUnit, TimeUnit } from "../../types/TimeTypes";
import { Vendor } from "../../types/VendorTypes";
import { VendorSelectInput } from "../vendor/VendorInput";
import { VendorMiniPanel } from "./VendorMiniPanel";
import { OrderFormMiniPanel } from "./OrderFormMiniPanel";
import styles from "./CalendarDashboard.module.less";

export interface CalData {
    [key: string]: VendorSummary[];
}

const SHORT_MONTH_NAMES = moment.monthsShort();
const MONTH_OPTIONS = SHORT_MONTH_NAMES.map((m, i) => ({
    label: m,
    value: i,
}));

interface CalendarDashboardProps {
    style?: CSSProperties;
}

export const CalendarDashboard: React.FC<CalendarDashboardProps> = ({ style }) => {
    const [searchParams] = useSearchParams();
    const [calendarConfig, setCalendarConfig] = useState({
        date: moment(),
        timeUnit: searchParams.get("timeUnit") === TimeUnit.DAY ? TimeUnit.DAY : TimeUnit.MONTH,
    });
    const [calendarData, setCalendarData] = useState({
        data: [] as DashboardVendorTimeSeries[],
        vendors: [] as Vendor[],
        value: calendarConfig.date,
    });
    const [vendors, setVendors] = useState([] as Vendor[]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const unit = calendarConfig.timeUnit === TimeUnit.DAY ? "month" : "year";
        const dateStart = calendarConfig.date.clone().startOf(unit).toDate();
        const dateEnd = calendarConfig.date.clone().endOf(unit).toDate();
        getDashboardData(calendarConfig.timeUnit, vendors, dateStart, dateEnd)
            .then(async (data) => {
                const vendorIds = data.timeSeries
                    .map((s) => {
                        return s.values.map((v) => v.vendorKey);
                    })
                    .flat();
                const vendors = vendorIds.length > 0 ? await getVendors(vendorIds) : [];
                setCalendarData({
                    data: data.timeSeries,
                    vendors: vendors,
                    value: calendarConfig.date.clone(),
                });
            })
            .finally(() => setLoading(false));
    }, [vendors, calendarConfig]);

    const dateCellRender = (value: moment.Moment) => {
        if (calendarConfig.timeUnit === TimeUnit.MONTH) {
            if (calendarConfig.date.year() !== value.year()) {
                return;
            }
        } else if (calendarConfig.timeUnit === TimeUnit.DAY) {
            if (calendarConfig.date.month() !== value.month()) {
                return;
            }
        }
        const monthYear = value.format("MMMM YYYY");
        const unit = extractUnit(calendarConfig.timeUnit, value.toDate());
        const data = calendarData.data.filter((c) => c.unit === unit);
        if (data.length <= 0) {
            return <></>;
        }
        const items = data
            .map((uv) => uv.values)
            .flat()
            .map((vd) => {
                const vendor = calendarData.vendors.filter((v) => v.vendorKey === vd.vendorKey)[0];
                return (
                    <VendorMiniPanel
                        key={`calendar-row-${vendor.vendorKey}`}
                        vendor={vendor}
                        orders={vd.orderForms}
                        style={{ marginTop: 10 }}
                    />
                );
            });

        const orderForms = data
            .map((uv) => uv.values)
            .flat()
            .map((vd) => vd.orderForms)
            .flat();
        const orderMiniPanel = (
            <OrderFormMiniPanel
                vendorKey={"dontcare"}
                title={`Orders for ${monthYear}`}
                orderForms={orderForms}
            />
        );
        return (
            <>
                <Popover content={orderMiniPanel} placement="topLeft">
                    {items}
                </Popover>
            </>
        );
    };

    const headerRender: CalendarProps<moment.Moment>["headerRender"] = ({
        value,
        type,
        onChange,
        onTypeChange,
    }) => {
        const month = value.month();
        const year = value.year();

        const yearOptions = Array.from({ length: 21 }, (_, i) => {
            const option = year + i - 10;
            return {
                label: option,
                value: option,
            };
        });

        const monthSelect =
            type === "month" ? (
                <Select
                    value={month}
                    options={MONTH_OPTIONS}
                    onChange={(newMonth) => {
                        const newDate = value.clone().month(newMonth);
                        onChange(newDate);
                    }}
                />
            ) : null;

        return (
            <Row align="middle">
                <Col flex={1} className={styles.title}>
                    Calendar
                </Col>
                <Col>
                    <Space>
                        <div className={styles.vendorBox}>
                            <VendorSelectInput onSelect={setVendors} />
                        </div>
                        <Select
                            value={year}
                            options={yearOptions}
                            onChange={(newYear: number) => {
                                const newDate = value.clone().year(newYear);
                                onChange(newDate);
                            }}
                        ></Select>
                        {monthSelect}
                        <Segmented
                            value={type}
                            options={[
                                { label: "Month", value: "month" },
                                { label: "Year", value: "year" },
                            ]}
                            onChange={(value) => onTypeChange(value as CalendarMode)}
                        />
                    </Space>
                </Col>
            </Row>
        );
    };

    return (
        <div style={style}>
            <Spin spinning={loading}>
                <Calendar
                    headerRender={headerRender}
                    dateCellRender={dateCellRender}
                    monthCellRender={dateCellRender}
                    value={calendarData.value}
                    onPanelChange={(date: moment.Moment, mode: CalendarMode) => {
                        setCalendarConfig({
                            date: date,
                            timeUnit: mode === "year" ? TimeUnit.MONTH : TimeUnit.DAY,
                        });
                    }}
                    mode={calendarConfig.timeUnit === TimeUnit.MONTH ? "year" : "month"}
                />
            </Spin>
        </div>
    );
};
