import {
    BalanceSheetStatement,
    CashFlowStatement,
    EmployeeCount,
    FinancialResponse,
    FinancialStatements,
    Quote,
    Ratio,
    StockNews,
} from "../../types/FinanceTypes";
import { safeGET } from "./SafeClient";

export function getInsightsBySymbol(symbol: string, limit = 5): Promise<StockNews[]> {
    return safeGET(`/api/finance/insights/${symbol}?limit=${limit}`);
}
export function getFinancialsBySymbol(symbol: string): Promise<FinancialResponse> {
    return safeGET(`/api/finance/financials/${symbol}`);
}

export function getRatiosBySymbol(symbol: string): Promise<Ratio[]> {
    return safeGET(`/api/finance/ratios/${symbol}`);
}

export function getBalanceSheetBySymbol(symbol: string): Promise<BalanceSheetStatement[]> {
    return safeGET(`/api/finance/balance/${symbol}`);
}

export function getCashFlowBySymbol(symbol: string): Promise<CashFlowStatement[]> {
    return safeGET(`/api/finance/cash/${symbol}`);
}

export function getQuotesBySymbol(symbol: string): Promise<Quote[]> {
    return safeGET(`/api/finance/quote/${symbol}`);
}

export function getEmployeeCountsBySymbol(symbol: string): Promise<EmployeeCount[]> {
    return safeGET(`/api/finance/employee/${symbol}`);
}

export function getFinancialStatementsBySymbol(symbol: string): Promise<FinancialStatements> {
    return safeGET(`/api/finance/statements/${symbol}`);
}
