import React, { useEffect, useState } from "react";
import { Space, Table } from "antd";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { GenericModal, GenericModalProps } from "../../../common/components/GenericModal";
import { OrderForm } from "../../../types/OrderFormTypes";
import { Pagination, SortOrder } from "../../../types/DataFetchingTypes";
import { Vendor } from "../../../types/VendorTypes";
import { handleError } from "../../../common/ErrorHandling";
import { listOrders } from "../../../common/api/OrderFormClient";
import { getVendors } from "../../../common/api/VendorClient";
import { SvgIcon } from "../../../common/components/SvgIcon";
import { roundToTwoDecimals } from "../../../common/Number";
import { CompanyImageCell } from "../../../common/components/CompanyImageCell";
import { ExpirationDateCell } from "../../../common/components/ExpirationDateCell";
import { SimulationScore } from "./SimulationScore";
import styles from "./OrderFormsModal.module.less";

interface OrderFormsModalProps extends GenericModalProps {}

const { Column } = Table;

export const OrderFormsModal: React.FC<OrderFormsModalProps> = ({ ...restProps }) => {
    const [data, setData] = useState([] as OrderForm[]);
    const [loading, setLoading]: any[] = useState(true);
    const [vendors, setVendors] = useState({} as Map<string, Vendor>);
    const [pagination, setPagination] = useState({
        pages: 0,
        current: 1,
        total: 0,
        pageSize: 10,
    } as Pagination);
    const [reload, setReload] = useState(0);

    const loadData = async () => {
        setLoading(true);
        try {
            const pager = await listOrders(
                pagination,
                [{ attribute: "vendorKey", order: SortOrder.ASC }],
                []
            );
            const vendors = await getVendors(pager.data.map((v) => v.vendorKey));
            const vendorMap = new Map(vendors.map((v) => [v.vendorKey, v]));
            setVendors(vendorMap);
            setData(pager.data);
            setPagination(pager.pagination);
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleTableChange = async (pagination: any) => {
        setPagination(pagination);
        setReload(reload + 1);
    };

    useEffect(() => {
        loadData();
    }, [reload]);

    return (
        <GenericModal {...restProps}>
            <Table
                size="small"
                dataSource={data}
                pagination={{
                    ...pagination,
                    size: "small",
                    className: styles.pager,
                }}
                loading={loading}
                rowKey="id"
                onChange={handleTableChange}
            >
                <Column
                    title="Company name"
                    dataIndex="vendorKey"
                    key="vendorKey"
                    render={(value) => {
                        const vendor = vendors.get(value);
                        return (
                            <CompanyImageCell
                                name={vendor?.name || ""}
                                logo={vendor?.hero}
                                link={`/vendor/${vendor?.vendorKey}`}
                            />
                        );
                    }}
                />
                <Column
                    title="Contract Score"
                    dataIndex="orderScore"
                    key="orderScore"
                    render={(value, row: OrderForm) => {
                        return (
                            <Space>
                                <Link to={`/order/score/${row.id}`}>
                                    <SvgIcon name="group" size={12} />
                                </Link>
                                <SimulationScore score={roundToTwoDecimals(value)} />
                            </Space>
                        );
                    }}
                />
                <Column
                    title="Optimized Score"
                    dataIndex="optimizedScore"
                    key="optimizedScore"
                    render={(value) => {
                        return <SimulationScore score={roundToTwoDecimals(value)} />;
                    }}
                />
                <Column
                    title="Value"
                    dataIndex="value"
                    key="value"
                    render={(value) => {
                        return (
                            <NumberFormat
                                value={value}
                                thousandSeparator={true}
                                displayType={"text"}
                                prefix={"$"}
                            />
                        );
                    }}
                />
                <Column
                    title="Potential cost savings"
                    dataIndex="decrease"
                    key="decrease"
                    render={(value) => {
                        return (
                            <NumberFormat
                                value={value}
                                thousandSeparator={true}
                                displayType={"text"}
                                prefix={"$"}
                            />
                        );
                    }}
                />
                <Column
                    title="Expiration"
                    dataIndex="expirationDate"
                    key="expirationDate"
                    render={(value, row: OrderForm) => {
                        return <ExpirationDateCell orderFormId={row.id} date={value} showLink />;
                    }}
                />
            </Table>
        </GenericModal>
    );
};
