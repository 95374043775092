import React, { useState } from "react";
import { Button, Col, Row, Segmented, Space, Table } from "antd";
import csvDownload from "json-to-csv-export";
import {
    formatNumberWithSuffix,
    getTrendDataColor,
    getTrendDataPrefix,
} from "../../../common/Number";
import {
    BalanceSheetStatement,
    CashFlowStatement,
    IncomeStatement,
    PeriodData,
} from "../../../types/FinanceTypes";
import { Period, StatementType } from "../../../types/SupplierTypes";
import { SvgIcon } from "../../../common/components/SvgIcon";
import { formatDateString } from "../../date/DateOp";
import { RowType, getFinancialStatementDataSource } from "./FinancialStatementssDataSources";
import styles from "./FinancialStatements.module.less";

const { Column } = Table;

const periodOptions = Object.keys(Period);
const statementOptions = Object.values(StatementType);

function getStatementData(
    statementType: StatementType,
    income?: PeriodData<IncomeStatement>,
    balance?: PeriodData<BalanceSheetStatement>,
    cash?: PeriodData<CashFlowStatement>
) {
    switch (statementType) {
        case StatementType.Income:
            return income;
        case StatementType.Cash:
            return cash;
        case StatementType.Balance:
            return balance;
        default:
            return;
    }
}

function getFormattedMetricData(current: number, previous?: number) {
    const formattedMetric = formatNumberWithSuffix(current);
    if (!previous) {
        return {
            formattedMetric,
        };
    }

    const change = current - previous;
    const changePercentage = Math.round(Math.abs((change / previous) * 100));
    const prefix = getTrendDataPrefix(change);
    const changeColor = getTrendDataColor(change);
    const formattedPercentage = `(${prefix}${changePercentage}%)`;
    return {
        formattedMetric,
        changeColor,
        formattedPercentage,
    };
}

export interface FinancialStatementsProps {
    incomes?: PeriodData<IncomeStatement>;
    balanceSheets?: PeriodData<BalanceSheetStatement>;
    cashFlows?: PeriodData<CashFlowStatement>;
    showDownload?: boolean;
}

export const FinancialStatements: React.FC<FinancialStatementsProps> = ({
    incomes,
    balanceSheets,
    cashFlows,
    showDownload = true,
}) => {
    const [period, setPeriod] = useState(Period.Annually);
    const [statementType, setStatementType] = useState(StatementType.Income);
    const [start, setStart] = useState(0);
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const dataSource = getFinancialStatementDataSource(statementType);

    const statementData = getStatementData(statementType, incomes, balanceSheets, cashFlows);
    const periodData =
        period === Period.Annually ? statementData?.annually : statementData?.quarterly;
    const periodDataMap = new Map(periodData?.map((item) => [item.date, item]));

    const allColumns = periodData?.map((item) => ({
        columnIndex: item.date,
        year: item.fiscalYear,
        quarter: `${item.fiscalYear} ${item.period}`,
    }));

    const symbol = periodData?.[0]?.symbol;

    const handleCSVDownload = () => {
        const headers = [
            "Year",
            ...(allColumns?.map((item) =>
                period === Period.Annually ? item.year : item.quarter
            ) || []),
        ];

        const csvRows = dataSource.reduce((pre, cur) => {
            const result = pre.concat(cur);
            if (cur.children) {
                return result.concat(cur.children);
            }
            return result;
        }, [] as RowType[]);

        const data = csvRows.map((item) => {
            const row: Array<string | number> = [item.label];
            allColumns?.forEach((ele, index) => {
                const currentPeriod = periodDataMap.get(ele.columnIndex) as unknown as Record<
                    string,
                    number | string
                >;
                const currentData = (currentPeriod?.[item.dataIndex] || 0) as number;

                const previousPeriod = periodDataMap.get(
                    allColumns[index + 1]?.columnIndex
                ) as unknown as Record<string, number | string>;
                const previousData = (previousPeriod?.[item.dataIndex] || 0) as number;

                const { formattedMetric, formattedPercentage } = getFormattedMetricData(
                    currentData,
                    previousData
                );
                if (!formattedPercentage) {
                    return row.push(formattedMetric);
                }
                return row.push(`${formattedMetric}${formattedPercentage}`);
            });
            return row;
        });

        csvDownload({
            headers,
            data,
            filename: `${symbol}_${statementType}_${period}_${formatDateString(new Date())}`,
            delimiter: ",",
        });
    };
    const prevColumn = (
        <Column
            title={
                start <= 0 ? null : (
                    <Button
                        className={styles.iconButton}
                        type="primary"
                        size="small"
                        onClick={() => setStart(start - 1)}
                        icon={<SvgIcon name="left" size={12} />}
                    ></Button>
                )
            }
            key="prev"
            width={48}
        ></Column>
    );

    const nextColumn = (
        <Column
            title={
                start > Number(allColumns?.length) - 5 ? null : (
                    <Button
                        className={styles.iconButton}
                        type="primary"
                        size="small"
                        onClick={() => setStart(start + 1)}
                        icon={<SvgIcon name="right" size={12} />}
                    ></Button>
                )
            }
            key="next"
            width={48}
        ></Column>
    );

    const displayedColumns = allColumns?.map((item, index) => {
        if (index < start) return null;
        if (index >= start + 4) return null;

        const currentPeriod = periodDataMap.get(item.columnIndex);
        const previousPeriod = periodDataMap.get(allColumns[index + 1]?.columnIndex);

        return (
            <Column
                title={period === Period.Annually ? item.year : item.quarter}
                key={item.columnIndex}
                width={182}
                render={(_, row: RowType) => {
                    const currentRowData = ((currentPeriod as Record<string, any>)?.[
                        row.dataIndex
                    ] || 0) as number;

                    const previousRowData = ((previousPeriod as Record<string, any>)?.[
                        row.dataIndex
                    ] || 0) as number;

                    const { formattedMetric, changeColor, formattedPercentage } =
                        getFormattedMetricData(currentRowData, previousRowData);
                    if (!formattedPercentage) {
                        return formattedMetric;
                    }
                    return (
                        <>
                            <span>{formattedMetric}</span>
                            <span style={{ color: changeColor }}>{formattedPercentage}</span>
                        </>
                    );
                }}
            ></Column>
        );
    });

    const csvButton =
        periodData?.length && showDownload ? (
            <Button type="link" onClick={handleCSVDownload}>
                Download csv
            </Button>
        ) : null;

        const currency = periodData && periodData.length > 0 && periodData?.[0].reportedCurrency !== "USD" ?
        <small>
            (Financial figures are in <b>{periodData?.[0]?.reportedCurrency}</b>)
        </small> :
        <></>

    return (
        <div className={styles.container}>
            <Row className={styles.titleRow}>
                <Col flex={1}>
                    <Space size={28}>
                        <div className={styles.title}>Financial statements {currency}</div>
                        <Segmented
                            options={periodOptions}
                            value={period}
                            onChange={(value) => setPeriod(value as Period)}
                        />
                        <Segmented
                            options={statementOptions}
                            value={statementType}
                            onChange={(value) => setStatementType(value as StatementType)}
                        />
                    </Space>
                </Col>
                <Col>{csvButton}</Col>
            </Row>
            <Table
                className={styles.statementsTable}
                dataSource={dataSource}
                pagination={false}
                size="small"
                rowKey="dataIndex"
                expandable={{
                    expandedRowKeys,
                    expandIcon: () => null,
                }}
            >
                <Column
                    title="Year"
                    key="year"
                    dataIndex="label"
                    render={(value: string, row: RowType) => {
                        if (!row.children?.length) return value;

                        const expanded = expandedRowKeys.includes(row.dataIndex);
                        return (
                            <Row className={styles.expandedRow}>
                                <Col flex={1}>{value}</Col>
                                <Col>
                                    {expanded ? (
                                        <Button
                                            className={styles.iconButton}
                                            type="link"
                                            icon={<SvgIcon name="up" size={12} />}
                                            size="small"
                                            onClick={() =>
                                                setExpandedRowKeys((pre) =>
                                                    pre.filter((key) => key !== row.dataIndex)
                                                )
                                            }
                                        ></Button>
                                    ) : (
                                        <Button
                                            className={styles.iconButton}
                                            type="link"
                                            icon={<SvgIcon name="down" size={12} />}
                                            size="small"
                                            onClick={() =>
                                                setExpandedRowKeys((pre) =>
                                                    pre.concat(row.dataIndex)
                                                )
                                            }
                                        ></Button>
                                    )}
                                </Col>
                            </Row>
                        );
                    }}
                ></Column>
                {prevColumn}
                {displayedColumns}
                {nextColumn}
            </Table>
        </div>
    );
};
