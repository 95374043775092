import { Skeleton } from "antd";
import { useEffect, useState } from "react";
import { handleError } from "../../common/ErrorHandling";
import { Vendor } from "../../types/VendorTypes";
import { getVendors } from "../api/VendorClient";
import { CompanyImageCell } from "./CompanyImageCell";

interface CompanyCardProps {
    vendorkey?: string;
    size?: number;
    onClick?: () => void;
}

export const CompanyCard: React.FC<CompanyCardProps> = ({ vendorkey, onClick, size }) => {
    const [vendor, setVendor] = useState({} as Vendor);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const fn = async () => {
            try {
                if (!vendorkey) {
                    return;
                }
                const res = await getVendors([vendorkey]);
                setVendor(res[0]);
            } catch (error: any) {
                handleError(error);
            } finally {
                setLoading(false);
            }
        };
        fn();
    }, [vendorkey]);

    return (
        <Skeleton loading={loading}>
            <CompanyImageCell
                logo={vendor.hero}
                name={vendor.name}
                link={`/vendor/${vendor.vendorKey}`}
            />
        </Skeleton>
    );
};
