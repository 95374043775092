import React from "react";
import { RetainedEarningSchedule } from "./components/RetainedEarningSchedule";
import { PPESchedule } from "./components/PPESchedule";
import { FinancialStatements, FinancialStatementsProps } from "./components/FinancialStatements";

interface SupplierStatementsPanelProps extends FinancialStatementsProps {}

export const SupplierStatementsPanel: React.FC<SupplierStatementsPanelProps> = (props) => {
    return (
        <>
            <FinancialStatements {...props} />
            <RetainedEarningSchedule
                balanceSheets={props.balanceSheets?.annually}
                cashFlows={props.cashFlows?.annually}
                style={{ marginTop: 16 }}
            />
            <PPESchedule
                balanceSheets={props.balanceSheets?.annually}
                cashFlows={props.cashFlows?.annually}
                style={{ marginTop: 16 }}
            />
        </>
    );
};
