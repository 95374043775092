import React from "react";
import { Col, Row } from "antd";
import {
    SupplierOverviewLeftPanel,
    SupplierOverviewLeftPanelProps,
} from "./SupplierOverviewLeftPanel";
import {
    SupplierOverviewRightPanel,
    SupplierOverviewRightPanelProps,
} from "./SupplierOverviewRightPanel";

interface SupplierOverviewPanelProps {
    leftPanenlProps: SupplierOverviewLeftPanelProps;
    rightPanelProps: SupplierOverviewRightPanelProps;
}

export const SupplierOverviewPanel: React.FC<SupplierOverviewPanelProps> = ({
    leftPanenlProps,
    rightPanelProps,
}) => {
    return (
        <Row gutter={16}>
            <Col span={18}>
                <SupplierOverviewLeftPanel {...leftPanenlProps} />
            </Col>
            <Col span={6}>
                <SupplierOverviewRightPanel {...rightPanelProps} />
            </Col>
        </Row>
    );
};
