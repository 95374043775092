import { RemoteLocation } from "./DataFetchingTypes";
import { Document, AnnotationValue, AnnotationSKU } from "./Document";
import { Error } from "./Error";

export const ReviewType = "Review";

export enum ReviewStatus {
    NOT_STARTED = "not_started",
    SUBMITTED = "submitted",
    REVIEW = "review",
    COMPLETED = "completed",
    FAILED = "failed",
}

export interface DocumentReview {
    reviewerId: number;
    documentSerialId: string;
    objectLocation: RemoteLocation;
    transcriptionJobId?: string;
    remarks: string;
    isOrderForm: boolean;
    isLigible: boolean;
    assignedOn?: Date;
    startedOn?: Date;
    completedOn?: Date;
    status: ReviewStatus;
    transcriptionStatus: TranscriptionStatus;
    attributes?: AnnotationValue[];
    skus?: AnnotationSKU[];
    document?: Document;
    failedReason?: Error;
}

export enum TranscriptionStatus {
    NOT_REQUESTED = "not_requested",
    REQUESTED = "requested",
    COMPLETED = "completed",
}

export interface DocumentTranscript {
    documentSerialId: string;
    pageCount: number;
    processedDate?: Date;
    pages?: DocumentPageTranscript[];
}

export interface DocumentPageTranscript {
    page: number;
    blocks?: any;
}

export interface BoundingBox {
    Width: number;
    Height: number;
    Left: number;
    Top: number;
}

export interface Coordinates {
    X: number;
    Y: number;
}

export interface AnnotationLabelRegion {
    Text: string;
    BoundingBox: BoundingBox;
    Polygon: Coordinates[];
}

export interface ReviewSubmitResponse {
    success: boolean;
    completedOn: Date;
}

export interface SaveReviewResponse {
    attributes?: AnnotationValue[];
    skus?: AnnotationSKU[];
}

export interface PageImage {
    page: number;
    url: string;
}
export interface DocumentReviewResponse {
    review: DocumentReview;
    document: Document;
    images: PageImage[];
}

export interface TranscriptRequestResponse {
    jobId: string;
    success: boolean;
}

export interface ExpandedDocumentReview extends DocumentReview {
    orderFormId: number;
    vendorKey: string;
    score: number;
}
