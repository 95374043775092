import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { Negotiation } from "../../types/NegotiationTypes";

interface NegotiationDetailBreadcrumbProps {
    negotiation: Negotiation;
}
export const NegotiationDetailBreadcrumb: React.FC<NegotiationDetailBreadcrumbProps> = ({
    negotiation,
}) => {
    return (
        <Breadcrumb key="negotiationDetailBread" separator=">">
            <Breadcrumb.Item key="negotiations">
                <Link to={"/negotiations"} key="negotiations">
                    Negotiations
                </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="negotiation-details">
                <Link
                    to={`/negotiations/${negotiation.id}`}
                    key={`negotiation-details-${negotiation.id}`}
                >
                    {negotiation.name}
                </Link>
            </Breadcrumb.Item>
        </Breadcrumb>
    );
};
