import { FC, useEffect, useState } from "react"
import { CustomerContext } from "./CustomerContext"
import { Card } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router";
import PurchaseOrderExtraction from "./PurchaseOrderExtraction";
import { PurchaseOrdersList } from "./PurchaseOrdersList";
import { safeGET } from "../../common/api/SafeClient";
import { UploadedFilesList } from "./UploadedFilesList";

export const CustomerContainer: FC = () => {

    const [customer, setCustomer] = useState<Customer>({
        id: 0,
        name: "",
        domain: ""
    });

    const params = useParams();

    const navigate = useNavigate();

    interface Customer {
        id: number,
        name: string,
        domain: string,
    }

    const deselectCustomer = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();

        navigate("/superadmin/extraction");
    }

    useEffect(() => {
        (async () => {
            const customerIdParamValue = params["customerId"];

            if (!customerIdParamValue) {
                navigate("/superadmin/extraction");

                return;
            }
    
            const customers = await safeGET<Customer[]>("/api/organization");

            const customerId = parseInt(customerIdParamValue);
    
            const filteredCustomers = customers.filter(customer => customer.id === customerId);

            if (filteredCustomers.length !== 1) {
                navigate("/superadmin/extraction");
            }

            setCustomer(filteredCustomers[0]);
        })();
    }, []);

    return (
        <>
            <CustomerContext.Provider value={customer}>
                <Card title={<>{customer.name} <a href="#" onClick={deselectCustomer}><EditOutlined/></a></>}>
                    <PurchaseOrdersList />
                    <UploadedFilesList />
                    <PurchaseOrderExtraction/>
                </Card>
            </CustomerContext.Provider>
        </>
    )
}
