import React from "react";
import { Card } from "antd";
import { Row, Col } from "antd";
import { BreadcrumbLocation } from "../../common/CommonInterfaces";
import { BreadcrumbsBar } from "../../common/components/BreadcrumbsBar";

interface OrderFormScreenLayout {
    contentPanel: any;
    toolbox: any;
    currentPath: BreadcrumbLocation[];
}

const OrderFormLayout: React.FC<OrderFormScreenLayout> = ({
    currentPath,
    contentPanel,
    toolbox,
}) => {
    return (
        <div>
            <Row key="orderContent">
                <Col span={24}></Col>
                <Col span={24}>
                    <Card bodyStyle={{ paddingTop: 10 }}>
                        <Row key="orderBread">
                            <Col span={22}>
                                <BreadcrumbsBar breadcrumbs={currentPath}></BreadcrumbsBar>
                            </Col>
                            <Col span={2}>
                                <div style={{ textAlign: "right" }}>{toolbox}</div>
                            </Col>
                        </Row>
                        {contentPanel}
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default OrderFormLayout;
