import React, { useState, useEffect, createRef, useContext } from "react";
import { Link } from "react-router-dom";
import { Table, Input, Space, Button, InputRef, Card } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { formatDetailTimeString } from "../date/DateOp";
import { getVendorsByOrgId } from "../../common/api/VendorClient";
import { Vendor } from "../../types/VendorTypes";
import { handleError } from "../../common/ErrorHandling";
import { ExpandedDocumentReview } from "../../types/ReviewTypes";
import { getReviewsByOrgId } from "../../common/api/ReviewClient";
import { ReviewStatusToTag } from "../review/ReviewStatus";
import { CustomerContext } from "./CustomerContext";

const { Column } = Table;

interface UploadedFilesListProps {}

export const UploadedFilesList: React.FC<UploadedFilesListProps> = () => {
    const customer = useContext(CustomerContext);
    const [data, setData] = useState<ExpandedDocumentReview[]>([]);
    const [loading, setLoading] = useState(true);
    const [vendors, setVendors] = useState<Map<string, Vendor>>();
    const searchRef = createRef<InputRef>();

    const loadData = async () => {
        if (!customer.id) return;
        setLoading(true);
        try {
            const res = await getReviewsByOrgId(customer.id);
            const vendors = await getVendorsByOrgId(
                customer.id,
                res.map((v) => v.vendorKey)
            );

            const vendorMap = new Map(vendors.map((v) => [v.vendorKey, v]));
            setVendors(vendorMap);
            setData(res);
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData();
    }, [customer.id]);

    return (
        <Card title="Uploaded Files">
            <Table size="small" dataSource={data} rowKey="id" loading={loading}>
                <Column
                    title="File Name"
                    dataIndex="document.name"
                    key="name"
                    render={(value, row: ExpandedDocumentReview) => {
                        return <Link to={`/order/${row.orderFormId}`}>{row.document?.name}</Link>;
                    }}
                    filterIcon={<SearchOutlined />}
                    onFilter={(_value, _record) => {
                        const regex = new RegExp(_value as string, "i");
                        return regex.test(_record.document?.name || "");
                    }}
                    filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                        return (
                            <div style={{ padding: 8 }}>
                                <Input
                                    ref={searchRef}
                                    placeholder={`Search `}
                                    value={selectedKeys[0]}
                                    onChange={(e) =>
                                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                                    }
                                    style={{ marginBottom: 8, display: "block" }}
                                />
                                <Space>
                                    <Button
                                        type="primary"
                                        onClick={() => confirm({ closeDropdown: true })}
                                        icon={<SearchOutlined />}
                                        size="small"
                                        style={{ width: 90 }}
                                    >
                                        Search
                                    </Button>
                                    <Button
                                        onClick={() => clearFilters?.()}
                                        size="small"
                                        style={{ width: 90 }}
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        type="link"
                                        size="small"
                                        onClick={() => {
                                            confirm({ closeDropdown: true });
                                        }}
                                    >
                                        Close
                                    </Button>
                                </Space>
                            </div>
                        );
                    }}
                    onFilterDropdownVisibleChange={(visible) => {
                        if (visible) {
                            setTimeout(() => searchRef.current?.focus(), 100);
                        }
                    }}
                />
                <Column
                    title="Vendor"
                    dataIndex="vendorKey"
                    key="vendorKey"
                    render={(value) => {
                        const vendor = vendors?.get(value);
                        return <Link to={`/vendor/${vendor?.vendorKey}`}>{vendor?.name}</Link>;
                    }}
                />
                <Column
                    title="Upload Date"
                    dataIndex="document.uploadDate"
                    key="uploadDate"
                    render={(_, row: ExpandedDocumentReview) => {
                        return formatDetailTimeString(row.document?.uploadDate || "");
                    }}
                />
                <Column
                    title="Pages"
                    dataIndex="document.pageCount"
                    key="pageCount"
                    render={(_, row: ExpandedDocumentReview) => {
                        return row.document?.pageCount;
                    }}
                />
                <Column
                    title="Status"
                    dataIndex="status"
                    key="status"
                    render={(_, row: ExpandedDocumentReview) => {
                        return <>{ReviewStatusToTag(row.status)}</>;
                    }}
                />
            </Table>
        </Card>
    );
};
