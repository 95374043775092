import React from "react";
import { SupplierFullCompetitor } from "../../../types/SupplierTypes";
import { CompetitorsTable, CompetitorsTableProps } from "./CompetitorsTable";
import styles from "./Competitors.module.less";

interface CompetitorsProps extends Omit<CompetitorsTableProps, "data"> {
    data?: SupplierFullCompetitor[];
}

export const Competitors: React.FC<CompetitorsProps> = ({ data, ...restProps }) => {
    if (!data?.length) return null;

    return (
        <>
            <div className={styles.title}>Top 3 competitors - Ranked by performance</div>
            <CompetitorsTable data={data} {...restProps} />
        </>
    );
};
