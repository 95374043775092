import { createContext } from "react"

export type Customer = {
    id: number,
    name: string,
    domain: string,
}

export const CustomerContext = createContext<Customer>({
    id: 0,
    name: "",
    domain: ""
});
