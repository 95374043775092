import { Col, Row } from "antd";
import React, { CSSProperties, useEffect, useState } from "react";
import { getNegotiationIntelligence } from "../../common/api/DashboardClientV2";
import { listUsers } from "../../common/api/UserClient";
import { handleError } from "../../common/ErrorHandling";
import { formatNumberWithSuffix, roundToTwoDecimals } from "../../common/Number";
import { NegotiationIntelligence } from "../../types/DashboardTypes";
import { User } from "../../types/UserGroup";
import { getGrade } from "../negotiations/config/grades";
import { OrderFormsModal } from "./components/OrderFormsModal";
import { UsersModal } from "./components/UsersModal";
import styles from "./NegotiationIntelligencePanel.module.less";

interface NegotiationIntelligencePanelProps {
    style?: CSSProperties;
}

export const NegotiationIntelligencePanel: React.FC<NegotiationIntelligencePanelProps> = ({
    style,
}) => {
    const [contractData, setContractData] = useState({
        totalSpend: 0,
        orderCount: 0,
        avgOrderScore: 0,
        avgOptimizedScore: 0,
        totalSavings: 0,
        totalAvgScore: 0,
    } as NegotiationIntelligence);
    const [users, setUsers] = useState([] as User[]);
    const [showUsersModal, setShowUsersModal] = useState(false);
    const [showOrdersModal, setShowOrdersModal] = useState(false);
    const [orderModalTitle, setOrderModalTitle] = useState("");

    const totalSpend = `$${formatNumberWithSuffix(contractData.totalSpend)}`;
    const avgOrderScoreGrade = getGrade(contractData.avgOrderScore);
    const avgOrderScore = `${roundToTwoDecimals(contractData.avgOrderScore)}% (${avgOrderScoreGrade?.grade})`;

    const avgOptimizedScoreGrade = getGrade(contractData.avgOptimizedScore);
    const avgOptimizedScore = `${roundToTwoDecimals(contractData.avgOptimizedScore)}% (${avgOptimizedScoreGrade?.grade})`;

    const totalSavings = `$${formatNumberWithSuffix(contractData.totalSavings)}`;

    const scoreDiff = roundToTwoDecimals(contractData.avgOrderScore - contractData.totalAvgScore);
    const diffPrefix = scoreDiff > 0 ? "Over" : "Below";

    const savingsShare =
        contractData.totalSpend !== 0
            ? roundToTwoDecimals((contractData.totalSavings / contractData.totalSpend) * 100)
            : 0;

    const openOrderModal = (title: string) => {
        setOrderModalTitle(title);
        setShowOrdersModal(true);
    };

    const metrics = [
        {
            name: "Total spend",
            data: totalSpend,
            onClick: (title: string) => openOrderModal(title),
        },
        {
            name: "Number of contracts",
            data: contractData.orderCount,
            onClick: (title: string) => openOrderModal(title),
        },
        { name: "POC users", data: users.length, onClick: () => setShowUsersModal(true) },
        {
            name: "Average contract score",
            data: avgOrderScore,
            dataColor: avgOrderScoreGrade?.color,
            derivedData: `${diffPrefix} average by ${Math.abs(scoreDiff)}%`,
            onClick: (title: string) => openOrderModal(title),
        },
        {
            name: "Average optimized contract score",
            data: avgOptimizedScore,
            dataColor: avgOptimizedScoreGrade?.color,
            onClick: (title: string) => openOrderModal(title),
        },
        {
            name: "Total savings opportunity",
            data: totalSavings,
            dataColor: "#4FBD19",
            derivedData: `${savingsShare}% of uploaded spend`,
            onClick: (title: string) => openOrderModal(title),
        },
    ];

    const loadData = async () => {
        try {
            const [contractData, userData] = await Promise.all([
                getNegotiationIntelligence(),
                listUsers(),
            ]);
            setContractData({
                ...contractData,
                avgOptimizedScore: 100 // Hard-coding 100% for this because we haven't defined what optimized contract score even is yet!
            });
            setUsers(userData);
        } catch (error) {
            handleError(error);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div style={style}>
            <div className={styles.title}>Negotiation Intelligence</div>
            <div className={styles.subtitle}>
                Unlock smarter deal making with data-driven negotiation intelligence.
            </div>
            <div className={styles.container}>
                <Row>
                    {metrics.map((item) => {
                        return (
                            <Col
                                span={8}
                                className={styles.metricCol}
                                key={item.name}
                                onClick={() => item.onClick(item.name)}
                            >
                                <div className={styles.metricBox}>
                                    <div className={styles.name}>{item.name}</div>
                                    <div className={styles.data} style={{ color: item.dataColor }}>
                                        {item.data}
                                    </div>
                                    {item.derivedData ? (
                                        <div className={styles.derivedData}>{item.derivedData}</div>
                                    ) : null}
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </div>
            <UsersModal
                title="POC Users"
                width={1000}
                users={users}
                open={showUsersModal}
                onCancel={() => setShowUsersModal(false)}
                footer={null}
            />
            <OrderFormsModal
                title={orderModalTitle}
                width={1200}
                open={showOrdersModal}
                onCancel={() => setShowOrdersModal(false)}
                footer={null}
            />
        </div>
    );
};
