import React from "react";
import styles from "./Description.module.less";

interface DescriptionProps {
    content?: string;
}
export const Description: React.FC<DescriptionProps> = ({ content }) => {
    if (!content) return null;

    return (
        <>
            <div className={styles.title}>Description</div>
            <div className={styles.content}>{content}</div>
        </>
    );
};
