import React from "react";
import { Col, Row } from "antd";
import styles from "./ContractStatistics.module.less";

interface ContractStatisticsProps {
    contractCount?: number;
    supplierCount?: number;
}

export const ContractStatistics: React.FC<ContractStatisticsProps> = ({
    contractCount = 0,
    supplierCount = 0,
}) => {
    return (
        <Row className={styles.container}>
            <Col span={12}>
                <div className={styles.item}>
                    <div className={styles.title}>Number of total suppliers</div>
                    <div className={styles.data}>{supplierCount}</div>
                </div>
            </Col>
            <Col span={12}>
                <div className={styles.item}>
                    <div className={styles.title}>Number of total files</div>
                    <div className={styles.data}>{contractCount}</div>
                </div>
            </Col>
        </Row>
    );
};
