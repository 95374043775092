import { Input, Modal, ModalProps } from "antd";
import React, { useEffect, useState } from "react";

interface HtmlSummaryEditModalProps extends Omit<ModalProps, "onOk"> {
    summary?: string;
    onOk?: (summary: string) => void;
}

export const HtmlSummaryEditModal: React.FC<HtmlSummaryEditModalProps> = ({
    summary,
    onOk,
    ...restProps
}) => {
    const [content, setContent] = useState("");

    useEffect(() => {
        setContent(summary || "");
    }, [summary]);

    return (
        <Modal
            {...restProps}
            onOk={() => {
                onOk?.(content);
            }}
        >
            <Input.TextArea
                rows={30}
                value={content}
                onChange={(e) => setContent(e.target.value)}
            ></Input.TextArea>
        </Modal>
    );
};
