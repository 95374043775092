import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { useParams } from "react-router";
import { OrderForm } from "../../types/OrderFormTypes";
import { VendorSummaryPanel } from "./VendorSummaryPanel";
import { getOrderForm } from "../../common/api/OrderFormClient";
import { handleError } from "../../common/ErrorHandling";

export const POCReportView: React.FC = () => {
    const params = useParams();
    const [orderForm, setOrderForm] = useState({} as OrderForm);

    const orderFormId = Number(params.orderFormId) || 0;
    const loadData = async () => {
        if (!orderFormId) return;
        try {
            const data = await getOrderForm(orderFormId);
            setOrderForm(data.orderForm);
        } catch (error) {
            handleError(error);
        }
    };
    useEffect(() => {
        loadData();
    }, []);

    return (
        <Card title={orderForm.name}>
            <VendorSummaryPanel orderFormId={orderFormId} editable />
        </Card>
    );
};
