import React, { CSSProperties } from "react";
import { Table } from "antd";
import { formatNumberWithSuffix } from "../../../common/Number";
import { BalanceSheetStatement, CashFlowStatement } from "../../../types/FinanceTypes";
import styles from "./PPESchedule.module.less";

const { Column } = Table;

interface PPERow {
    label: string;
    value: string | number;
}

interface PPEScheduleProps {
    balanceSheets?: BalanceSheetStatement[];
    cashFlows?: CashFlowStatement[];
    style?: CSSProperties;
}

export const PPESchedule: React.FC<PPEScheduleProps> = ({ balanceSheets, cashFlows, style }) => {
    const balance = balanceSheets?.[0];
    const cash = cashFlows?.[0];

    const dataSource: PPERow[] = [
        {
            label: "Gross PPE",
            value: formatNumberWithSuffix(balance?.totalAssets || 0),
        },
        {
            label: "Annual depreciation",
            value: formatNumberWithSuffix(cash?.depreciationAndAmortization || 0),
        },
        {
            label: "Capital expenditure",
            value: formatNumberWithSuffix(cash?.capitalExpenditure || 0),
        },
        {
            label: "Net PPE",
            value: formatNumberWithSuffix(balance?.propertyPlantEquipmentNet || 0),
        },
    ];
    return (
        <div className={styles.container} style={style}>
            <div className={styles.title}>PPE Schedule</div>
            <Table dataSource={dataSource} pagination={false} size="small">
                <Column title="Year" key="year" dataIndex="label"></Column>
                <Column
                    title={balance?.fiscalYear}
                    key="value"
                    dataIndex="value"
                    width={230}
                ></Column>
            </Table>
        </div>
    );
};
