import React from "react";
import { Link } from "react-router-dom";
import { Divider, List, Modal, ModalProps } from "antd";
import { OrderForm } from "../../../types/OrderFormTypes";
import { Negotiation } from "../../../types/NegotiationTypes";

interface VendorDeleteModalProps extends ModalProps {
    orderForms?: OrderForm[];
    negotiations?: Negotiation[];
}
export const VendorDeleteModal: React.FC<VendorDeleteModalProps> = ({
    orderForms,
    negotiations,
    ...restProps
}) => {
    return (
        <Modal title="Manually migrate or remove related data first" width={600} {...restProps}>
            <>
                {orderForms?.length ? (
                    <>
                        <Divider orientation="left">Order forms</Divider>
                        <List
                            dataSource={orderForms}
                            renderItem={(item) => (
                                <List.Item>
                                    <Link to={`/order/${item.id}`}>{item.name}</Link>
                                </List.Item>
                            )}
                        />
                    </>
                ) : null}
                {negotiations?.length ? (
                    <>
                        <Divider orientation="left">Negotiations</Divider>
                        <List
                            dataSource={negotiations}
                            renderItem={(item) => (
                                <List.Item>
                                    <Link to={`/negotiations/${item.id}`}>{item.name}</Link>
                                </List.Item>
                            )}
                        />
                    </>
                ) : null}
            </>
        </Modal>
    );
};
