import React, { CSSProperties, useEffect, useState } from "react";
import { Alert, Card, Space } from "antd";
import {
    getReport,
    getStrategies,
    upsertReport,
    upsertStrategy,
} from "../../common/api/ReportClient";
import { handleError } from "../../common/ErrorHandling";
import { NegotiationStrategy, POCReport } from "../../types/ReportTypes";
import { PlainHtmlSummary } from "./components/PlainHtmlSummary";
import { NegotiationStrategySummary } from "./components/NegotiationStrategySummary";
import styles from "./VendorSummaryPanel.module.less";

interface VendorSummaryPanelProps {
    orderFormId?: number;
    expandAll?: boolean;
    editable?: boolean;
    style?: CSSProperties;
}

export const VendorSummaryPanel: React.FC<VendorSummaryPanelProps> = ({
    orderFormId,
    expandAll,
    editable,
    style,
}) => {
    const [report, setReport] = useState({} as POCReport);
    const [strategies, setStrategies] = useState([] as NegotiationStrategy[]);

    const loadReport = async () => {
        if (!orderFormId) return;
        try {
            const data = await getReport(orderFormId);
            setReport(data);
        } catch (error) {
            handleError(error);
        }
    };

    const loadStrategies = async () => {
        if (!orderFormId) return;
        try {
            const data = await getStrategies(orderFormId);
            setStrategies(data);
        } catch (error) {
            handleError(error);
        }
    };

    const loadData = async () => {
        await Promise.all([loadReport(), loadStrategies()]);
    };

    const handleReportChange = async (report: POCReport) => {
        if (!orderFormId) return;
        await upsertReport({ ...report, orderFormId });
        loadReport();
    };

    const handleStrategyChange = async (strategy: NegotiationStrategy) => {
        if (!orderFormId) return;
        await upsertStrategy({ ...strategy, orderFormId });
        loadStrategies();
    };

    useEffect(() => {
        loadData();
    }, [orderFormId]);

    const showContent = editable || (report.contractSummary && strategies.length > 0);

    return (
        <div style={style}>
            <Space className={styles.summaryContainer} direction="vertical">
                { showContent ? (
                    <><PlainHtmlSummary
                        title="Contract summary"
                        description="Here’s a summary of your contract."
                        html={report.contractSummary}
                        onUpdate={(value) => {
                            handleReportChange({ ...report, contractSummary: value });
                        }}
                        open={expandAll}
                        editable={editable}
                    />
                    <NegotiationStrategySummary
                        title="Negotiation strategy"
                        description="Potential cost-savings strategy checklist."
                        html={report.negotiationStrategy}
                        onUpdate={(value) => {
                            handleReportChange({ ...report, negotiationStrategy: value });
                        }}
                        strategies={strategies}
                        onChange={(data) => handleStrategyChange(data)}
                        totalOpportunity={report}
                        onOpportunityChange={(data) => handleReportChange({ ...report, ...data })}
                        open={expandAll}
                        editable={editable}
                    />
                    <PlainHtmlSummary
                        title="Top legal priorities"
                        description="Critical legal issues and strategies that demand immediate attention."
                        html={report.topLegalPriorities}
                        onUpdate={(value) => {
                            handleReportChange({ ...report, topLegalPriorities: value });
                        }}
                        open={expandAll}
                        editable={editable}
                    />
                    </>
                ) : (
                    <><Card>
                        <Alert type="info" message="The analysis for this vendor is currently underway. Stay tuned for updates!" showIcon={true}/>
                    </Card></>
                )
                }   
            </Space>
        </div>
    );
};
