import React from "react";
import { Row, Col, Space } from "antd";
import { IncomeStatement } from "../../../types/FinanceTypes";
import { Period } from "../../../types/SupplierTypes";
import { EarningsChart } from "./EarningChart";
import { IncomeChart } from "./IncomeChart";
import styles from "./FinancialCharts.module.less";

interface FinancialChartsProps {
    period: Period;
    incomes: IncomeStatement[];
}

export const FinancialCharts: React.FC<FinancialChartsProps> = ({ incomes, period }) => {
    const incomeData = [...incomes].slice(0, 4).reverse();
    const earningsData = [...incomes].slice(0, 8).reverse();

    return (
        <div className={styles.main}>
            <div className={styles.chartContainer}>
                <Row className={styles.titleRow}>
                    <Col flex={1} className={styles.title}>
                        Financial statement
                    </Col>
                    <Col>
                        <Space size={18}>
                            <span className={styles.legendGreen}>Revenue</span>
                            <span className={styles.legendBlue}>Net Income</span>
                        </Space>
                    </Col>
                </Row>
                <IncomeChart incomes={incomeData} period={period} />
            </div>
            <div className={styles.chartContainer}>
                <Row className={styles.titleRow}>
                    <Col flex={1} className={styles.title}>
                        Earnings
                    </Col>
                    <Col>
                        <Space size={18}>
                            <span className={styles.legendBlue}>EPS consensus</span>
                        </Space>
                    </Col>
                </Row>
                <EarningsChart incomes={earningsData} period={period} />
            </div>
        </div>
    );
};
