import React, { useState, useEffect, createRef, useContext } from "react";
import { Link } from "react-router-dom";
import { Table, Input, Space, Button, InputRef, Card, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import { formatUTCDate } from "../date/DateOp";
import { deleteOrderOfAnOrg, getOrdersByOrgId } from "../../common/api/OrderFormClient";
import { getVendorsByOrgId } from "../../common/api/VendorClient";
import { Vendor } from "../../types/VendorTypes";
import { OrderForm } from "../../types/OrderFormTypes";
import { handleError } from "../../common/ErrorHandling";
import { SimulationScore } from "../dashboard-v2/components/SimulationScore";
import { roundToTwoDecimals } from "../../common/Number";
import { SvgIcon } from "../../common/components/SvgIcon";
import { CustomerContext } from "./CustomerContext";

const { Column } = Table;

interface PurchaseOrderListProps {}

export const PurchaseOrdersList: React.FC<PurchaseOrderListProps> = () => {
    const customer = useContext(CustomerContext);
    const [data, setData]: any[] = useState([]);
    const [loading, setLoading]: any[] = useState(true);
    const [vendors, setVendors] = useState({} as Map<string, Vendor>);
    const searchRef = createRef<InputRef>();

    const loadData = async () => {
        if (!customer.id) return;
        setLoading(true);
        try {
            const res = await getOrdersByOrgId(customer.id);
            const vendors = await getVendorsByOrgId(
                customer.id,
                res.map((v) => v.vendorKey)
            );

            const vendorMap = new Map(vendors.map((v) => [v.vendorKey, v]));
            setVendors(vendorMap);
            setData(res);
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = (orderFormId: number) => {
        if (!customer.id) return;

        Modal.confirm({
            title: "Remove Purchase Order?",
            content: "Are you sure you want to remove the purchase order?",
            onOk: async () => {
                await deleteOrderOfAnOrg(customer.id, orderFormId);
                loadData();
            },
            okText: "Remove",
        });
    };

    useEffect(() => {
        loadData();
    }, [customer.id]);

    return (
        <Card title="Purchase Orders">
            <Table size="small" dataSource={data} rowKey="id" loading={loading}>
                <Column
                    title="Name"
                    dataIndex="name"
                    key="name"
                    render={(value, row: OrderForm) => {
                        return <Link to={"/order/" + row.id + ""}>{value}</Link>;
                    }}
                    filterIcon={<SearchOutlined />}
                    onFilter={(_value, _record) => {
                        const regex = new RegExp(_value as string, "i");
                        return regex.test(_record.name);
                    }}
                    filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                        return (
                            <div style={{ padding: 8 }}>
                                <Input
                                    ref={searchRef}
                                    placeholder={`Search `}
                                    value={selectedKeys[0]}
                                    onChange={(e) =>
                                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                                    }
                                    style={{ marginBottom: 8, display: "block" }}
                                />
                                <Space>
                                    <Button
                                        type="primary"
                                        onClick={() => confirm({ closeDropdown: true })}
                                        icon={<SearchOutlined />}
                                        size="small"
                                        style={{ width: 90 }}
                                    >
                                        Search
                                    </Button>
                                    <Button
                                        onClick={() => clearFilters?.()}
                                        size="small"
                                        style={{ width: 90 }}
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        type="link"
                                        size="small"
                                        onClick={() => {
                                            confirm({ closeDropdown: true });
                                        }}
                                    >
                                        Close
                                    </Button>
                                </Space>
                            </div>
                        );
                    }}
                    onFilterDropdownVisibleChange={(visible) => {
                        if (visible) {
                            setTimeout(() => searchRef.current?.focus(), 100);
                        }
                    }}
                />
                <Column
                    title="Vendor"
                    dataIndex="vendorKey"
                    key="vendorKey"
                    render={(value) => {
                        const vendor = vendors.get(value);
                        return <Link to={`/vendor/${vendor?.vendorKey}`}>{vendor?.name}</Link>;
                    }}
                />
                <Column
                    title="Renewal date"
                    dataIndex="expirationDate"
                    key="expirationDate"
                    render={(value) => {
                        return formatUTCDate(value);
                    }}
                />
                <Column
                    title="Value"
                    dataIndex="value"
                    key="centsValue"
                    render={(value) => {
                        return (
                            <NumberFormat
                                value={value}
                                thousandSeparator={true}
                                displayType={"text"}
                                prefix={"$"}
                            />
                        );
                    }}
                />
                <Column
                    title="Contract Score"
                    dataIndex="orderScore"
                    key="orderScore"
                    render={(value, row: OrderForm) => {
                        return (
                            <Space>
                                <Link to={`/order/score/${row.id}`}>
                                    <SvgIcon name="group" size={12} />
                                </Link>
                                <SimulationScore score={roundToTwoDecimals(value)} />
                            </Space>
                        );
                    }}
                />
                <Column
                    dataIndex="id"
                    key="notify"
                    render={(value: number) => {
                        return (
                            <Button
                                type="link"
                                icon={<SvgIcon name="trash" size={12} />}
                                onClick={() => handleDelete(value)}
                            ></Button>
                        );
                    }}
                ></Column>
            </Table>
        </Card>
    );
};
