import React from "react";
import { IncomeStatement } from "../../types/FinanceTypes";
import { Period, SupplierFullCompetitor, SupplierWithEmployee } from "../../types/SupplierTypes";
import { Competitors } from "./components/Competitors";
import { Description } from "./components/Description";
import { Drivers } from "./components/Drivers";
import { FinancialCharts } from "./components/FinancialCharts";
import { FinancialOverview } from "./components/FinancialOverview";
import { Offerings } from "./components/Offerings";

export interface SupplierOverviewLeftPanelProps {
    profile: SupplierWithEmployee;
    period: Period;
    competitors?: SupplierFullCompetitor[];
    showCompetitorEdit?: boolean;
    onEditCompetitor?: (data: SupplierFullCompetitor) => void;
    onPeriodChange?: (overviewOption: Period) => void;
    incomes?: IncomeStatement[];
}

export const SupplierOverviewLeftPanel: React.FC<SupplierOverviewLeftPanelProps> = ({
    profile,
    competitors,
    period,
    onPeriodChange,
    incomes,
    showCompetitorEdit,
    onEditCompetitor,
}) => {
    return (
        <>
            <Description content={profile.description} />
            <Offerings data={profile.products} />
            <FinancialOverview data={incomes} period={period} onPeriodChange={onPeriodChange} />
            <Competitors
                data={competitors}
                showEdit={showCompetitorEdit}
                onEdit={onEditCompetitor}
            />

            <Drivers data={profile.businessDrivers} />
            {incomes?.length ? <FinancialCharts incomes={incomes} period={period} /> : null}
        </>
    );
};
