import React from "react";
import { SupplierProduct } from "../../../types/SupplierTypes";
import styles from "./Offerings.module.less";

interface OfferingsProps {
    data?: SupplierProduct[];
}

export const Offerings: React.FC<OfferingsProps> = ({ data }) => {
    if (!data?.length) return null;

    return (
        <>
            <div className={styles.title}>Key offerings</div>

            <ul className={styles.content}>
                {data.map((item) => (
                    <li key={item.id}>
                        <span className={styles.itemName}>{item.name}: </span>
                        {item.description}
                    </li>
                ))}
            </ul>
        </>
    );
};
