import React, { useEffect, useState } from "react";
import { Avatar, Form, Input, Modal, ModalProps } from "antd";
import { SupplierFullCompetitor } from "../../../types/SupplierTypes";

interface CompetitorEditModalProps extends Omit<ModalProps, "onOk"> {
    competitor: SupplierFullCompetitor;
    onOk?: (data: SupplierFullCompetitor) => void;
}

export const CompetitorEditModal: React.FC<CompetitorEditModalProps> = ({
    competitor,
    onOk,
    ...restProps
}) => {
    const [form, setForm] = useState({} as SupplierFullCompetitor);

    useEffect(() => {
        setForm(competitor);
    }, [competitor]);

    return (
        <Modal
            title={form.name}
            {...restProps}
            onOk={() => {
                onOk?.(form);
            }}
        >
            <Form>
                <Form.Item label="Logo">
                    <Input
                        value={form.logo}
                        addonAfter={<Avatar shape="square" src={form.logo || ""} />}
                        onChange={(e) =>
                            setForm((old) => ({
                                ...old,
                                logo: e.target.value,
                            }))
                        }
                    ></Input>
                </Form.Item>
            </Form>
        </Modal>
    );
};
