import { safeGET, safePOST } from "./SafeClient";
import { NegotiationStrategy, POCReport } from "../../types/ReportTypes";

export async function getReport(orderFormId: number, abort?: any): Promise<POCReport> {
    return safeGET(`/api/report/${orderFormId}`, abort);
}

export async function upsertReport(report: POCReport, abort?: any) {
    return safePOST(`/api/report/${report.orderFormId}`, report, abort);
}

export async function getStrategies(
    orderFormId: number,
    abort?: any
): Promise<NegotiationStrategy[]> {
    return safeGET(`/api/report/${orderFormId}/strategy`, abort);
}

export async function upsertStrategy(strategy: NegotiationStrategy, abort?: any) {
    return safePOST(`/api/report/${strategy.orderFormId}/strategy`, strategy, abort);
}
