import { Navigate, Outlet } from "react-router"
import { useUserState } from "../../common/UserContext"
import { UserRole } from "../../types/UserGroup"

interface ProtectedRouteProps {
    accessType: "GRANT" | "DENY",
    roles: UserRole[]
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
    const accessDeniedElement = <Navigate to="/" />

    const userRoles = useUserState().me.roles ?? []

    // Condition is satisfied if user has ANY of the roles provided. This is the only condition type right now.
    // If we want to add a new type of condition, e.g. if the user has ALL of the roles provided, then 
    // we'll have to add an additional prop such as conditionType: "ANY" | "ALL
    const conditionSatisfied = userRoles.filter(r => props.roles.includes(r)).length > 0

    // If the access type is GRANT, you're authorized if you satisfy the condition.
    // If the access type is DENY, you're authorized if you do NOT satisfy the condition.
    const authorized = props.accessType === "GRANT" ? conditionSatisfied : !conditionSatisfied;
    
    return authorized ? <Outlet/> : accessDeniedElement
}

export default ProtectedRoute