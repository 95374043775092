import React, { CSSProperties } from "react";
import { Col, Row, Space, Tooltip, Typography, Avatar, Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getTrendDataColor, getTrendDataPrefix, roundToTwoDecimals } from "../../../common/Number";
import { StockNews } from "../../../types/FinanceTypes";
import styles from "./FinancialNews.module.less";

export interface FinancialNewsProps {
    price?: number;
    changePercentage?: number;
    news?: StockNews[];
    tip?: string;
    style?: CSSProperties;
    showLoadButton?: boolean;
    onLoadMore?: (limit: number) => void;
}

export const FinancialNews: React.FC<FinancialNewsProps> = ({
    price = 0,
    changePercentage = 0,
    news,
    tip,
    style,
    showLoadButton = true,
    onLoadMore,
}) => {
    const prefix = getTrendDataPrefix(changePercentage);
    const formattedPercentage = roundToTwoDecimals(Math.abs(changePercentage));
    const color = getTrendDataColor(changePercentage);

    const loadButton =
        showLoadButton && Number(news?.length) < 15 ? (
            <Button
                type="primary"
                size="large"
                className={styles.loadMore}
                onClick={() => onLoadMore?.(Number(news?.length) + 5)}
            >
                Load more
            </Button>
        ) : null;

    const newsBlock = news?.length ? (
        <>
            <div className={styles.subtitle}>Latest news</div>
            <Space direction="vertical" size={16}>
                {news?.map((item) => (
                    <Space size={16} key={item.url} onClick={() => window.open(item.url, "_blank")}>
                        <Avatar shape="square" size={67} src={item.image} />
                        <div>
                            <Typography.Paragraph
                                ellipsis={{ rows: 2 }}
                                className={styles.newsTitle}
                            >
                                {item.title}
                            </Typography.Paragraph>
                            <div className={styles.newsDate}>{item.publishedDate}</div>
                        </div>
                    </Space>
                ))}
            </Space>
            {loadButton}
        </>
    ) : null;

    return (
        <div style={style} className={styles.main}>
            <div className={styles.title}>
                <Space>
                    Key financial news
                    <Tooltip title={tip}>
                        <ExclamationCircleOutlined className={styles.tipIcon} />
                    </Tooltip>
                </Space>
            </div>
            <div className={styles.newsContainer}>
                <div className={styles.priceContainer}>
                    <div>Stock performance/Funding</div>
                    <Row align="bottom">
                        <Col flex={1} style={{ color }} className={styles.percentageContainer}>
                            <span className={styles.percentagePrefix}>{prefix}</span>
                            <span className={styles.percentage}>{formattedPercentage}%</span>
                        </Col>
                        <Col>${price} today</Col>
                    </Row>
                </div>
                {newsBlock}
            </div>
        </div>
    );
};
