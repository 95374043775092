import { Skeleton, Table } from "antd";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { getOrderForms, listOrdersA } from "../../common/api/OrderFormClient";
import { SortFilterPaged } from "../../common/CommonInterfaces";
import { SortableFilteringTable } from "../../common/components/SortableFilteringTable";
import { handleError } from "../../common/ErrorHandling";
import { Sort, FilterGroup, FilterGroupType, FilterOperand } from "../../types/DataFetchingTypes";
import { OrderForm, OrderFormStatus } from "../../types/OrderFormTypes";
import { Vendor } from "../../types/VendorTypes";
import { formatDateString } from "../date/DateOp";
import { orderStatusToTag } from "../orderform/OrderStatus";
import styles from "./OrderFormMiniPanel.module.less";

const columns = [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (value: any, row: OrderForm) => {
            return <Link to={"/order/" + row.id + ""}>{value}</Link>;
        },
    },
    {
        title: "Renewal date",
        dataIndex: "expirationDate",
        key: "expirationDate",
        render: (value: any) => {
            return formatDateString(value);
        },
    },
    {
        title: "Value",
        dataIndex: "value",
        key: "value",
        render: (value: any) => {
            return (
                <NumberFormat
                    value={value}
                    thousandSeparator={true}
                    displayType={"text"}
                    prefix={"$"}
                />
            );
        },
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (value: any) => {
            return orderStatusToTag(value);
        },
    },
];

interface OrderFormMiniPanelArguments {
    vendorKey?: string;
    title: string;
    orderForms?: OrderForm[];
    filterStatus?: OrderFormStatus[];
}

export const OrderFormMiniPanel: React.FC<OrderFormMiniPanelArguments> = ({
    vendorKey,
    title,
    orderForms,
    filterStatus = [],
}) => {
    const [paging] = useState({
        filter: [
            {
                groupId: "statusFilter",
                groupType: FilterGroupType.OR,
                filter: filterStatus.map((status) => {
                    return {
                        attribute: "status",
                        value: status,
                        operand: FilterOperand.EQUALS,
                    };
                }),
            } as FilterGroup,
            {
                groupId: "vendorKey",
                groupType: FilterGroupType.AND,
                filter: [
                    {
                        attribute: "vendorKey",
                        value: vendorKey,
                        operand: FilterOperand.EQUALS,
                    },
                ],
            } as FilterGroup,
        ],
        sort: [] as Sort[],
        pager: {
            pagination: {
                current: 1,
                pageSize: 10,
            },
        },
    });

    let formsTable;
    if (orderForms !== undefined && orderForms.length > 0) {
        formsTable = (
            <Table
                dataSource={orderForms}
                columns={columns}
                rowKey={"id"}
                size={"small"}
                style={{ minHeight: 500 }}
            />
        );
    } else {
        formsTable = (
            <SortableFilteringTable<OrderForm>
                load={listOrdersA}
                size={"small"}
                style={{ minHeight: 500 }}
                columns={columns}
                startingFilter={paging as SortFilterPaged<OrderForm>}
            />
        );
    }
    return (
        <>
            <div className={styles.tableTitle}>{title}</div>
            {formsTable}
        </>
    );
};

interface LoadableMiniPanelArgs {
    orderFormIds: number[];
    vendor: Vendor;
    title: string;
}

export const LoadableOrderMiniPanel: React.FC<LoadableMiniPanelArgs> = ({
    orderFormIds,
    vendor,
    title,
}) => {
    const [loading, setLoading] = useState(false);
    const [orderForms, setOrderForms] = useState([] as OrderForm[]);

    useEffect(() => {
        setLoading(true);
        const fn = async () => {
            const forms = await getOrderForms(orderFormIds);
            setOrderForms(forms.map((f) => f.orderForm));
        };
        try {
            fn();
        } catch (error: any) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    }, [orderFormIds]);

    return (
        <Skeleton loading={loading}>
            <OrderFormMiniPanel
                vendorKey={vendor.vendorKey}
                orderForms={orderForms}
                title={title}
            />
        </Skeleton>
    );
};
