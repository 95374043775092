import React from "react";
import { Space } from "antd";
import { Link } from "react-router-dom";
import { getFullUrl } from "../Url";
import { CompanyImage } from "./CompanyImage";
import { SvgIcon } from "./SvgIcon";

interface CompanyImageCellProps {
    logo?: string;
    name: string;
    link?: string;
    linkTarget?: string;
    domain?: string;
    linkedin?: string;
    twitter?: string;
    nameWrap?: boolean;
}

export const CompanyImageCell: React.FC<CompanyImageCellProps> = ({
    logo,
    name,
    link,
    linkTarget,
    domain,
    linkedin,
    twitter,
    nameWrap,
}) => {
    return (
        <div style={{ display: "flex", justifyContent: "space-between", gap: 16 }}>
            <Space style={{ flex: 1 }}>
                <CompanyImage size="small" logo={logo} name={name} />
                <span style={{ whiteSpace: nameWrap ? "unset" : "nowrap" }}>
                    {link ? (
                        <Link to={link} target={linkTarget}>
                            {name}
                        </Link>
                    ) : (
                        name
                    )}
                </span>
            </Space>
            <Space>
                {domain && (
                    <a
                        href={getFullUrl(domain)}
                        target="_blank"
                        rel="noreferrer"
                        data-testid="company-domain"
                    >
                        <SvgIcon name="global" />
                    </a>
                )}
                {linkedin && (
                    <a
                        href={linkedin}
                        target="_blank"
                        rel="noreferrer"
                        data-testid="company-linkedin"
                    >
                        <SvgIcon name="linkedin" />
                    </a>
                )}
                {twitter && (
                    <a
                        href={twitter}
                        target="_blank"
                        rel="noreferrer"
                        data-testid="company-twitter"
                    >
                        <SvgIcon name="x" />
                    </a>
                )}
            </Space>
        </div>
    );
};
