import React from "react";
import { Table } from "antd";
import { GenericModal, GenericModalProps } from "../../../common/components/GenericModal";
import { User, UserRole } from "../../../types/UserGroup";
import { userRoleToTag } from "../../user/UserAttributes";

interface UsersModalProps extends GenericModalProps {
    users?: User[];
}

const { Column } = Table;

export const UsersModal: React.FC<UsersModalProps> = ({ users, ...restProps }) => {
    return (
        <GenericModal {...restProps}>
            <Table dataSource={users} pagination={false}>
                <Column title="Email" dataIndex="email" />
                <Column title="First Name" dataIndex="firstName" />
                <Column title="Last Name" dataIndex="lastName" />
                <Column
                    title="Roles"
                    dataIndex="roles"
                    render={(value: any) => {
                        return value
                            ? value
                                  .filter((role: UserRole) => role !== UserRole.POC)
                                  .map((role: UserRole) => userRoleToTag(role))
                            : "";
                    }}
                />
            </Table>
        </GenericModal>
    );
};
