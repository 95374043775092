import { FC, useContext } from "react";
import { CustomerContext } from "./CustomerContext";
import { Button, Card, Checkbox, Descriptions, Divider, Form } from "antd";
import TextArea from "antd/lib/input/TextArea";

const PurchaseOrderExtraction: FC = () => {

    const customer = useContext(CustomerContext);

    return (
        <>
            <Card title="Example-SOW-12345.pdf">
                <Descriptions title="Files" column={1}>
                    <Descriptions.Item>Example-MSA-12345.pdf</Descriptions.Item>
                    <Descriptions.Item>Example-SOW-12345.pdf</Descriptions.Item>
                    <Descriptions.Item>Example-Admendment-12345.pdf</Descriptions.Item>
                </Descriptions>
                <Descriptions bordered={true} layout="vertical" title="Basic information">
                    <Descriptions.Item label="Start Date">2025-01-01</Descriptions.Item>
                    <Descriptions.Item label="End Date">2025-01-01</Descriptions.Item>
                    <Descriptions.Item label="Price">$1,000,000.00</Descriptions.Item>
                </Descriptions>
                <br></br>
                <Descriptions bordered={true} title="Levers" column={1}>
                    <Descriptions.Item label="Lever 1" labelStyle={{textAlign: "right", display: "inline-block"}} contentStyle={{textAlign: "left", display: "inline-block"}}>Lever 1 value</Descriptions.Item>
                    <Descriptions.Item label="Lever 1" labelStyle={{textAlign: "right", display: "inline-block"}} contentStyle={{textAlign: "left", display: "inline-block"}}>Lever 1 value</Descriptions.Item>
                    <Descriptions.Item label="Lever 1" labelStyle={{textAlign: "right", display: "inline-block"}} contentStyle={{textAlign: "left", display: "inline-block"}}>Lever 1 value</Descriptions.Item>
                </Descriptions>
            </Card>
            <Divider></Divider>
            <Card title="Assistant">
                <Form layout="vertical">
                    <Form.Item label="System prompt">
                        <TextArea value="You are a corporate vendor procurement analyst who will read and understand business-to-business vendor procurement contracts and extract information from them for analysis">
                        </TextArea>
                    </Form.Item>
                    <Form.Item label="User query">
                        <TextArea value="What is the purchase order's start date, end date, and total contract value in US Dollars?">
                        </TextArea>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary">Send query</Button>
                    </Form.Item>
                    <Form.Item label="Output">
                        <TextArea value="The purchase order's start date is 2025-01-01, the end date is 2025-01-01, and the total contract value is $1,000,000.00">
                        </TextArea>
                    </Form.Item> 
                    <Form.Item label="From last output, extract">
                        <Checkbox>Start date</Checkbox><br/>
                        <Checkbox>End date</Checkbox><br/>
                        <Checkbox>Price</Checkbox><br/>
                        <Divider></Divider>
                        <Checkbox>Lever 1</Checkbox><br/>
                        <Checkbox>Lever 2</Checkbox><br/>
                        <Checkbox>Lever 3</Checkbox><br/>
                        <Checkbox>Lever 4</Checkbox><br/>
                        <Checkbox>Lever 5</Checkbox><br/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary">Extract output</Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    )
}

export default PurchaseOrderExtraction;