import React, { useEffect, useState } from "react";
import { Button, Col, Collapse, Row } from "antd";
import { SvgIcon } from "../../../common/components/SvgIcon";
import { HtmlSummaryEditModal } from "./HtmlSummaryEditModal";
import styles from "./PlainHtmlSummary.module.less";

const { Panel } = Collapse;
const PANEL_KEY = "singlePanel";

interface PlainHtmlSummaryProps {
    title: string;
    description: string;
    html: string;
    onUpdate?: (summary: string) => void;
    open?: boolean;
    editable?: boolean;
}

export const PlainHtmlSummary: React.FC<PlainHtmlSummaryProps> = ({
    title,
    description,
    html,
    onUpdate,
    open,
    editable = false,
}) => {
    const [showEdit, setShowEdit] = useState(false);
    const [activeKey, setActiveKey] = useState([] as string[]);

    useEffect(() => {
        if (open) {
            setActiveKey([PANEL_KEY]);
        } else {
            setActiveKey([]);
        }
    }, [open]);

    const editButton = editable ? (
        <Col>
            <Button
                className={styles.edit}
                type="link"
                onClick={(e) => {
                    setShowEdit(true);
                    e.stopPropagation();
                }}
            >
                Edit
            </Button>
        </Col>
    ) : null;

    return (
        <>
            <HtmlSummaryEditModal
                open={showEdit}
                title={title}
                summary={html}
                onOk={(value) => {
                    onUpdate?.(value);
                    setShowEdit(false);
                }}
                onCancel={() => setShowEdit(false)}
                width={1200}
            />
            <Collapse
                className={styles.summary}
                bordered={false}
                collapsible="header"
                expandIconPosition="end"
                expandIcon={() => <SvgIcon name="down" size={20} className={styles.expandIcon} />}
                activeKey={activeKey}
                onChange={(value) => setActiveKey(value as string[])}
            >
                <Panel
                    header={
                        <Row align="middle">
                            <Col flex={1}>
                                <div className={styles.headerTitle}>{title}</div>
                                <div>{description}</div>
                            </Col>
                            {editButton}
                        </Row>
                    }
                    key={PANEL_KEY}
                >
                    <div dangerouslySetInnerHTML={{ __html: html }}></div>
                </Panel>
            </Collapse>
        </>
    );
};
