import { FilterGroup, PageRequest, PagerObject, Sort } from "../../types/DataFetchingTypes";
import { DeleteVendorResponse, Vendor, VendorIndustry } from "../../types/VendorTypes";
import { safeDELETE, safeGET, safePOST, safePUT } from "./SafeClient";

export function listVendors(
    pageRequest: PageRequest,
    sorter: Sort[],
    filter: FilterGroup[],
    tags?: string[],
    abort?: any
): Promise<PagerObject<Vendor[]>> {
    const sorting = sorter
        .map(
            (v) =>
                `sort[]=${v.attribute}&order[]=${v.order}${
                    v.prefix !== undefined ? "&orderpf[]=" + v.prefix : ""
                }`
        )
        .reduce((p, v) => `${p}&${v}`, "");
    const paging = `current=${pageRequest.current}&pageSize=${pageRequest.pageSize}`;
    const filtering = filter
        .map((v) => {
            return v.filter
                .map((fv) => {
                    return `filter[]=${fv.attribute}&operand[]=${fv.operand}&value[]=${fv.value}&group[]=${v.groupId}&gop[]=${v.groupType}`;
                })
                .join("&");
        })
        .reduce((p, v) => `${p}&${v}`, "");

    let tagQuery = "";
    if (tags?.length) {
        tagQuery = tags.map((v) => `tag[]=${v}`).reduce((p, v) => `${p}&${v}`, "");
    }

    return safeGET(`/api/vendor?${paging}${sorting}${filtering}${tagQuery}`, abort);
}

export async function getVendors(vendorIds: string[], abort?: any): Promise<Vendor[]> {
    const ids = vendorIds.reduce((p, v) => `${p}&vendorKey[]=${encodeURIComponent(v)}`, "");
    return safeGET(`/api/vendor/m?${ids}`, abort);
}

export function loadVendorSuggestions(match: string, abort?: any): Promise<Vendor[]> {
    return safeGET(`/api/vendor/s?q=${match}`, abort);
}

export function createVendor(vendor: Vendor, abort?: any): Promise<Vendor> {
    return safePOST(`/api/vendor/${vendor.vendorKey}`, vendor, abort);
}

export function updateVendor(vendor: Vendor, abort?: any): Promise<Vendor> {
    return safePUT(`/api/vendor/${encodeURIComponent(vendor.vendorKey)}`, vendor, abort);
}

export function getVendorIndustry(vendor: Vendor, abort?: any): Promise<VendorIndustry> {
    return safeGET(`/api/vendor-industry/${encodeURIComponent(vendor.name)}`, abort);
}

export function manageVendorIndustry(
    industry: VendorIndustry,
    abort?: any
): Promise<VendorIndustry> {
    return safePOST(`/api/vendor-industry`, industry, abort);
}

export function deleteVendor(vendor: Vendor, abort?: any): Promise<DeleteVendorResponse> {
    return safeDELETE(`/api/vendor/${encodeURIComponent(vendor.vendorKey)}`, vendor, abort);
}

export async function getVendorsByOrgId(
    orgId: number,
    vendorKeys: string[],
    abort?: any
): Promise<Vendor[]> {
    if (!vendorKeys.length) return [];
    const ids = vendorKeys.reduce((p, v) => `${p}&vendorKey[]=${encodeURIComponent(v)}`, "");
    return safeGET(`/api/vendor/org/${orgId}?${ids}`, abort);
}
