import { Button, Card, Form, Select, Space } from "antd";
import { FC, useEffect, useState } from "react";
import { Customer } from "./CustomerContext";
import { useNavigate } from "react-router";
import { safeGET } from "../../common/api/SafeClient";
import { impersonateOrgBot } from "../../common/api/UserClient";

export const CustomerSelector:FC = () => {

    const [customers, setCustomers] = useState<Customer[]>([])

    const [selectedCustomer, setSelectedCustomer] = useState<Customer>()

    const customerSelected = (customerId: number) => {
        const filteredCustomers = customers.filter(customer => customer.id === customerId);

        if (filteredCustomers.length === 0 || filteredCustomers.length > 1) return;

        setSelectedCustomer(filteredCustomers[0])
    }

    const navigate = useNavigate();

    const confirmCustomerSelection = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();

        if (selectedCustomer) {
            await impersonateOrgBot(selectedCustomer.id);
            navigate("/superadmin/extraction/customer/" + selectedCustomer.id);
        }
    }

    useEffect(() => {
        (async () => {
            try {
                const customers = await safeGET<Customer[]>("/api/organization");

                setCustomers(customers);
            } catch (error) {
                console.error(error);
            }
        })();
    }, [])

    return (
        <>
        <Card title="Select customer">
            <Form name="customer">
                <Form.Item
                    label="Customer"
                    name="customerId"
                    rules={[{ required: true, message: 'Select customer' }]}
                    >
                    <Select
                        placeholder="Select a customer"
                        onChange={customerSelected}
                    >
                        {
                            customers.map(customer => {
                                return <Select.Option key={customer.id} value={customer.id}>{customer.id}: {customer.name} ({customer.domain})</Select.Option>
                            })
                        },
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button 
                            type="primary" 
                            htmlType="submit"
                            onClick={confirmCustomerSelection}
                        >Submit</Button>
                    </Space>
                </Form.Item>
            </Form>
        </Card>
        </>
    )
}
