import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Tabs } from "antd";
import { usePDF } from "react-to-pdf";
import {
    Period,
    SupplierFullCompetitor,
    SupplierInsight,
    SupplierWithEmployee,
} from "../../types/SupplierTypes";
import {
    getSupplierById,
    getSupplierCompetitors,
    getSupplierInsights,
    updateSupplierCompetitor,
} from "../../common/api/SupplierClient";
import {
    getEmployeeCountsBySymbol,
    getFinancialStatementsBySymbol,
    getInsightsBySymbol,
    getQuotesBySymbol,
    getRatiosBySymbol,
} from "../../common/api/FinanceClient";
import { SupplierDetailTopPanel } from "./SupplierDetailTopPanel";
import { handleError } from "../../common/ErrorHandling";
import { EmployeeCount, Ratio, Quote, FinancialStatements } from "../../types/FinanceTypes";
import { PdfPage } from "../../common/components/PdfPage";
import { useUserState } from "../../common/UserContext";
import { isPoc, isSuperAdmin } from "../../types/UserGroup";
import { CompetitorEditModal } from "./components/CompetitorEditModal";
import { SupplierBase } from "./components/SupplierBase";
import { SupplierOverviewPanel } from "./SupplierOverviewPanel";
import { SupplierOverviewRightPanel } from "./SupplierOverviewRightPanel";
import { SupplierOverviewLeftPanel } from "./SupplierOverviewLeftPanel";
import { FinancialNewsProps } from "./components/FinancialNews";
import { SupplierRatiosPanel } from "./SupplierRatiosPanel";
import { SupplierStatementsPanel } from "./SupplierStatementsPanel";

enum TabKey {
    Overview = "overview",
    Statements = "statements",
    Ratios = "ratios",
}

export const SupplierDetailViewV2: React.FC = () => {
    const params = useParams();
    const { me } = useUserState();
    const [supplierId] = useState(Number(params.id));
    const [supplierProfie, setSupplierProfile] = useState({} as SupplierWithEmployee);
    const [employees, setEmployees] = useState([] as EmployeeCount[]);
    const [supplierCompetitors, setSupplierCompetitors] = useState([] as SupplierFullCompetitor[]);
    const [supplierInsights, setSupplierInsights] = useState([] as SupplierInsight[]);
    const [period, setPeriod] = useState(Period.Annually);
    const [supplierNews, setSupplierNews] = useState({} as FinancialNewsProps);
    const [creating, setCreating] = useState(false);
    const { toPDF, targetRef } = usePDF({ canvas: { useCORS: true } });

    const [competitor, setCompetitor] = useState({} as SupplierFullCompetitor);
    const [showCompetitorModal, setShowCompetitorModal] = useState(false);
    const [ratios, setRatios] = useState([] as Ratio[]);
    const [currentTab, setCurrentTab] = useState(TabKey.Overview);
    const [quotes, setQuotes] = useState([] as Quote[]);
    const [financialStatements, setFinancialStatements] = useState({} as FinancialStatements);

    const createPdf = async () => {
        setCreating(true);
        try {
            await toPDF();
        } catch (error) {
            handleError(error);
        } finally {
            setCreating(false);
        }
    };

    const refreshSupplier = async (id: number) => {
        const [profile, competitors, insights] = await Promise.all([
            getSupplierById(id),
            getSupplierCompetitors(id),
            getSupplierInsights(id),
        ]);
        setSupplierProfile(profile);
        setSupplierCompetitors(competitors);
        setSupplierInsights(insights);

        if (profile.symbol) {
            try {
                const [news, financialRatios, quotes, employeeCounts, financialStatements] =
                    await Promise.all([
                        getInsightsBySymbol(profile.symbol),
                        getRatiosBySymbol(profile.symbol),
                        getQuotesBySymbol(profile.symbol),
                        getEmployeeCountsBySymbol(profile.symbol),
                        getFinancialStatementsBySymbol(profile.symbol),
                    ]);
                setEmployees(employeeCounts);
                setRatios(financialRatios);
                setQuotes(quotes);
                setFinancialStatements(financialStatements);

                setSupplierNews({
                    price: quotes[0]?.price || 0,
                    changePercentage: quotes[0]?.changePercentage || 0,
                    news,
                });
            } catch (error) {
                handleError(error);
            }
        }
    };

    const employeeCount = employees?.[0]?.employeeCount;
    const previousEmployeeCount = employees?.[1]?.employeeCount;
    const employeeChange =
        Number((employeeCount - previousEmployeeCount) / previousEmployeeCount) || 0;

    useEffect(() => {
        refreshSupplier(supplierId);
    }, [supplierId]);

    const handleEditClick = (data: SupplierFullCompetitor) => {
        setCompetitor(data);
        setShowCompetitorModal(true);
    };

    const refreshCompetitors = async () => {
        try {
            const data = await getSupplierCompetitors(supplierId);
            setSupplierCompetitors(data);
        } catch (error) {
            handleError(error);
        }
    };

    const handleUpdate = async (data: SupplierFullCompetitor) => {
        try {
            await updateSupplierCompetitor(data);
            await refreshCompetitors();
        } catch (error) {
            handleError(error);
        } finally {
            setShowCompetitorModal(false);
        }
    };

    const loadMoreNews = async (limit: number) => {
        if (!supplierProfie.symbol) return;
        try {
            const news = await getInsightsBySymbol(supplierProfie.symbol, limit);
            setSupplierNews((pre) => ({
                ...pre,
                news,
            }));
        } catch (error) {
            handleError(error);
        }
    };

    const incomeStatements =
        period === Period.Annually
            ? financialStatements.incomes?.annually
            : financialStatements.incomes?.quarterly;

    const panel = (
        <div>
            <Card>
                <SupplierDetailTopPanel
                    supplierName={supplierProfie.name}
                    supplierId={supplierId}
                />
                <div ref={targetRef} style={{ position: "fixed", left: "-1000rem" }}>
                    <PdfPage page={1} total={4}>
                        <SupplierBase
                            {...supplierProfie}
                            currentEmployee={employeeCount}
                            employeeDifference={employeeChange}
                            showPdfButton={false}
                        />
                        <SupplierOverviewLeftPanel
                            profile={supplierProfie}
                            competitors={supplierCompetitors}
                            period={period}
                            onPeriodChange={setPeriod}
                            incomes={incomeStatements}
                        />
                    </PdfPage>
                    <PdfPage page={2} total={4}>
                        <SupplierOverviewRightPanel
                            insights={supplierInsights}
                            financialNewsProps={{ ...supplierNews, showLoadButton: false }}
                        />
                    </PdfPage>
                    <PdfPage page={3} total={4}>
                        <SupplierStatementsPanel
                            incomes={financialStatements.incomes}
                            balanceSheets={financialStatements.balanceSheets}
                            cashFlows={financialStatements.cashFlows}
                        />
                    </PdfPage>
                    <PdfPage page={4} total={4}>
                        <SupplierRatiosPanel ratios={ratios} />
                    </PdfPage>
                </div>
                <SupplierBase
                    {...supplierProfie}
                    currentEmployee={employeeCount}
                    employeeDifference={employeeChange}
                    showPdfButton={!isPoc(me.roles)}
                    onPdfButtonClick={createPdf}
                    quotes={quotes}
                    showStockInfo={currentTab !== TabKey.Overview}
                />
                <Tabs
                    onChange={(value) => setCurrentTab(value as TabKey)}
                    items={[
                        {
                            label: "Overview",
                            key: TabKey.Overview,
                            children: (
                                <>
                                    <CompetitorEditModal
                                        competitor={competitor}
                                        open={showCompetitorModal}
                                        onCancel={() => setShowCompetitorModal(false)}
                                        onOk={handleUpdate}
                                    />

                                    <SupplierOverviewPanel
                                        leftPanenlProps={{
                                            profile: supplierProfie,
                                            competitors: supplierCompetitors,
                                            showCompetitorEdit: isSuperAdmin(me.roles || []),
                                            onEditCompetitor: handleEditClick,
                                            period: period,
                                            onPeriodChange: setPeriod,
                                            incomes: incomeStatements,
                                        }}
                                        rightPanelProps={{
                                            insights: supplierInsights,
                                            financialNewsProps: {
                                                ...supplierNews,
                                                onLoadMore: loadMoreNews,
                                            },
                                        }}
                                    />
                                </>
                            ),
                        },
                        {
                            label: "Financial statements",
                            key: TabKey.Statements,
                            children: (
                                <SupplierStatementsPanel
                                    incomes={financialStatements.incomes}
                                    balanceSheets={financialStatements.balanceSheets}
                                    cashFlows={financialStatements.cashFlows}
                                />
                            ),
                        },
                        {
                            label: "Financial ratios",
                            key: TabKey.Ratios,
                            children: <SupplierRatiosPanel ratios={ratios} />,
                        },
                    ]}
                ></Tabs>
            </Card>
        </div>
    );

    return panel;
};
