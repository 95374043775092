import {
    BalanceSheetStatement,
    CashFlowStatement,
    IncomeStatement,
} from "../../../types/FinanceTypes";
import { StatementType } from "../../../types/SupplierTypes";

interface BasicDataSourceRow<T> {
    label: string;
    dataIndex: keyof T;
}

export interface DataSourceRow<T> extends BasicDataSourceRow<T> {
    children?: BasicDataSourceRow<T>[];
}

export type RowType =
    | DataSourceRow<IncomeStatement>
    | DataSourceRow<CashFlowStatement>
    | DataSourceRow<BalanceSheetStatement>;

const incomeDataSource: DataSourceRow<IncomeStatement>[] = [
    {
        label: "Total revenue",
        dataIndex: "revenue",
    },
    {
        label: "Cost of revenue",
        dataIndex: "costOfRevenue",
    },
    {
        label: "Gross profit",
        dataIndex: "grossProfit",
    },
    {
        label: "Operating expenses",
        dataIndex: "operatingExpenses",
        children: [
            { label: "Research and Development", dataIndex: "researchAndDevelopmentExpenses" },
            {
                label: "Selling, General and Administrative Expenses",
                dataIndex: "sellingGeneralAndAdministrativeExpenses",
            },
            {
                label: "Selling and Marketing Expenses",
                dataIndex: "sellingAndMarketingExpenses",
            },
            {
                label: "General and Administrative Expenses",
                dataIndex: "generalAndAdministrativeExpenses",
            },
            {
                label: "Other Expenses",
                dataIndex: "otherExpenses",
            },
        ],
    },
    {
        label: "Operating income",
        dataIndex: "operatingIncome",
    },
    {
        label: "Income before taxes",
        dataIndex: "incomeBeforeTax",
        children: [
            {
                label: "Interest income",
                dataIndex: "interestIncome",
            },
            {
                label: "Interest expense",
                dataIndex: "interestExpense",
            },
            {
                label: "Other Non-Operating Income",
                dataIndex: "nonOperatingIncomeExcludingInterest",
            },
        ],
    },
    {
        label: "Net income",
        dataIndex: "netIncome",
    },
    {
        label: "Basic EPS",
        dataIndex: "eps",
    },
    {
        label: "EPS diluted",
        dataIndex: "epsDiluted",
    },
    {
        label: "Basic average shares",
        dataIndex: "weightedAverageShsOut",
    },
    {
        label: "Diluted average shares",
        dataIndex: "weightedAverageShsOutDil",
    },
    {
        label: "EBITDA",
        dataIndex: "ebitda",
    },
];

const cashFlowDataSource: DataSourceRow<CashFlowStatement>[] = [
    {
        label: "Free cash flow",
        dataIndex: "freeCashFlow",
        children: [
            {
                label: "Operating cash flow",
                dataIndex: "operatingCashFlow",
            },

            {
                label: "Capital expenditure",
                dataIndex: "capitalExpenditure",
            },
        ],
    },
    {
        label: "Operating activities",
        dataIndex: "netCashProvidedByOperatingActivities",
        children: [
            {
                label: "Net income",
                dataIndex: "netIncome",
            },
            {
                label: "Depreciation and amortization",
                dataIndex: "depreciationAndAmortization",
            },
            {
                label: "Deferred income tax",
                dataIndex: "deferredIncomeTax",
            },
            {
                label: "Stock-based compensation",
                dataIndex: "stockBasedCompensation",
            },
            {
                label: "Non-cash items",
                dataIndex: "otherNonCashItems",
            },
        ],
    },
    {
        label: "Working capital change",
        dataIndex: "changeInWorkingCapital",
        children: [
            {
                label: "Accounts receivables",
                dataIndex: "accountsReceivables",
            },
            {
                label: "Inventory",
                dataIndex: "inventory",
            },
            {
                label: "Accounts payables",
                dataIndex: "accountsPayables",
            },
            {
                label: "Other working capital",
                dataIndex: "otherWorkingCapital",
            },
        ],
    },
    {
        label: "Investing activities",
        dataIndex: "netCashProvidedByInvestingActivities",
        children: [
            {
                label: "Investments in PPE",
                dataIndex: "investmentsInPropertyPlantAndEquipment",
            },
            {
                label: "Acquisitions",
                dataIndex: "acquisitionsNet",
            },
            {
                label: "Purchases of investments",
                dataIndex: "purchasesOfInvestments",
            },
            {
                label: "Sales of investments",
                dataIndex: "salesMaturitiesOfInvestments",
            },
            {
                label: "Other investing activities",
                dataIndex: "otherInvestingActivities",
            },
        ],
    },
    {
        label: "Financing activities",
        dataIndex: "netCashProvidedByFinancingActivities",
        children: [
            { label: "Net debt", dataIndex: "netDebtIssuance" },
            {
                label: "Long term debt",
                dataIndex: "longTermNetDebtIssuance",
            },
            {
                label: "Short term debt",
                dataIndex: "shortTermNetDebtIssuance",
            },
            {
                label: "Stock",
                dataIndex: "netStockIssuance",
            },
            {
                label: "Dividends paid",
                dataIndex: "netDividendsPaid",
            },
            {
                label: "Other financing activities",
                dataIndex: "otherFinancingActivities",
            },
        ],
    },
    {
        label: "Cash at end",
        dataIndex: "cashAtEndOfPeriod",
        children: [
            { label: "Cash at beginning", dataIndex: "cashAtBeginningOfPeriod" },
            {
                label: "Net cash change",
                dataIndex: "netChangeInCash",
            },
            {
                label: "Effect of forex",
                dataIndex: "effectOfForexChangesOnCash",
            },
        ],
    },
];

const balanceDataSource: DataSourceRow<BalanceSheetStatement>[] = [
    {
        label: "Cash and short-term investments",
        dataIndex: "cashAndShortTermInvestments",
        children: [
            {
                label: "Cash and equivalents",
                dataIndex: "cashAndCashEquivalents",
            },

            {
                label: "Short-term investments",
                dataIndex: "shortTermInvestments",
            },
        ],
    },
    {
        label: "Net receivables",
        dataIndex: "netReceivables",
        children: [
            {
                label: "Receivables",
                dataIndex: "accountsReceivables",
            },
            {
                label: "Other receivables",
                dataIndex: "otherReceivables",
            },
        ],
    },
    {
        label: "Current assets",
        dataIndex: "totalCurrentAssets",
        children: [
            {
                label: "Inventory",
                dataIndex: "inventory",
            },
            {
                label: "Prepaids",
                dataIndex: "prepaids",
            },
            {
                label: "Other current assets",
                dataIndex: "otherCurrentAssets",
            },
        ],
    },
    {
        label: "Non-current assets",
        dataIndex: "totalNonCurrentAssets",
        children: [
            {
                label: "PPE",
                dataIndex: "propertyPlantEquipmentNet",
            },
            {
                label: "Long-term investments",
                dataIndex: "longTermInvestments",
            },
            {
                label: "Tax assets",
                dataIndex: "taxAssets",
            },
            {
                label: "Other non-current assets",
                dataIndex: "otherNonCurrentAssets",
            },
        ],
    },
    {
        label: "Total assets",
        dataIndex: "totalAssets",
    },
    {
        label: "Current liabilities",
        dataIndex: "totalCurrentLiabilities",
        children: [
            { label: "Payables", dataIndex: "accountPayables" },
            {
                label: "Other payables",
                dataIndex: "otherPayables",
            },
            {
                label: "Short term debt",
                dataIndex: "shortTermDebt",
            },
            {
                label: "Current lease obligations",
                dataIndex: "capitalLeaseObligationsCurrent",
            },
            {
                label: "Tax payables",
                dataIndex: "taxPayables",
            },
            {
                label: "Deferred revenue",
                dataIndex: "deferredRevenue",
            },
            {
                label: "Other current liabilities",
                dataIndex: "otherCurrentLiabilities",
            },
        ],
    },
];

const DataSource = {
    [StatementType.Income]: incomeDataSource,
    [StatementType.Cash]: cashFlowDataSource,
    [StatementType.Balance]: balanceDataSource,
};

export function getFinancialStatementDataSource(statementType: StatementType): RowType[] {
    return DataSource[statementType];
}
