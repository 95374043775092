import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button, Col, Row, Space } from "antd";
import { OrderScore } from "./components/OrderScore";
import {
    addOrderLever,
    deleteOrderLever,
    getAverageOrderScore,
    getLevers,
    getOrderForm,
    updateOrderForm,
    updateOrderLever,
} from "../../common/api/OrderFormClient";
import { OrderForm, OrderFormLeverDetail } from "../../types/OrderFormTypes";
import { createNegotiationWithLevers } from "../../common/api/NegotiationsClient";
import { Negotiation } from "../../types/NegotiationTypes";
import { useUserState } from "../../common/UserContext";
import { isSuperAdmin } from "../../types/UserGroup";

interface OrderFormScoreMainPanelProps {
    orderFormId: number;
    orderForm: OrderForm;
    onChange?: () => void;
}

export const OrderFormScoreMainPanel: React.FC<OrderFormScoreMainPanelProps> = ({
    orderFormId,
    orderForm,
    onChange,
}) => {
    const navigate = useNavigate();
    const { me } = useUserState();
    const [showSimulation, setShowSimulation] = useState(false);
    const [orderLevers, setOrderLevers] = useState([] as OrderFormLeverDetail[]);
    const [legalLeverWeight, setLegalLeverWeight] = useState(0);
    const [averageScore, setAverageScore] = useState(0);

    const simulationLevers = orderLevers.map((item) => {
        const sortedOptions = [...item.lever.options].sort((a, b) => a.weight - b.weight);
        return {
            ...item,
            isSelected: true,
            selectedOptionId: sortedOptions.pop()!.id,
        };
    });
    const colSpans = showSimulation ? [12, 12] : [24, 0];

    const refreshLevers = async () => {
        const res = await getLevers(orderFormId);
        setOrderLevers(res);
    };

    const refreshLeverWeight = async () => {
        const res = await getOrderForm(orderFormId);
        setLegalLeverWeight(res.orderForm.legalWeight);
    };

    const refreshAverageScore = async () => {
        const res = await getAverageOrderScore();
        setAverageScore(res.average);
        onChange?.();
    };

    const refreshScore = async () => {
        await refreshAverageScore();
        onChange?.();
    };

    const updateLegalLeverWeight = async (value: number) => {
        const res = await updateOrderForm({ id: orderFormId, legalWeight: value } as OrderForm);
        setLegalLeverWeight(() => res.legalWeight);
    };

    const handleToggleChange = async (value: number) => {
        await updateLegalLeverWeight(value);
        await refreshScore();
    };

    const generateNegotiation = async () => {
        const res = await createNegotiationWithLevers(
            {
                name: orderForm.name,
                vendorKey: orderForm.vendorKey,
                expirationDate: orderForm.expirationDate,
                legalWeight: legalLeverWeight,
                value: orderForm.value,
                category: orderForm.category,
            } as Negotiation,
            simulationLevers
        );
        navigate(`/negotiations/${res.id}`);
    };

    const handleCopyOrdreLevers = async () => {
        const res = await createNegotiationWithLevers(
            {
                name: orderForm.name,
                vendorKey: orderForm.vendorKey,
                expirationDate: orderForm.expirationDate,
                legalWeight: legalLeverWeight,
                value: orderForm.value,
                category: orderForm.category,
            } as Negotiation,
            orderLevers.filter((item) => item.isSelected)
        );
        navigate(`/negotiations/${res.id}`);
    };

    const handleLeverChange = async (optionId: number, lever: OrderFormLeverDetail) => {
        if (lever.id) {
            await updateOrderLever({ ...lever, selectedOptionId: optionId });
        } else {
            await addOrderLever({ ...lever, selectedOptionId: optionId });
        }
        await refreshLevers();
        await refreshScore();
    };

    const handleLeverRemove = async (lever: OrderFormLeverDetail) => {
        await deleteOrderLever(lever);
        await refreshLevers();
        await refreshScore();
    };

    useEffect(() => {
        refreshLevers();
        refreshLeverWeight();
        refreshAverageScore();
    }, []);

    return (
        <>
            <Row gutter={24} wrap={false} style={{ overflowX: "hidden" }}>
                <Col span={colSpans[0]} style={{ transition: "all 0.3s" }}>
                    <OrderScore
                        enableEditing={!showSimulation}
                        title="Contract Score"
                        description="Contract Score"
                        averageScore={averageScore}
                        orderScore={orderForm.orderScore}
                        onOptimizeButtonClick={() => setShowSimulation(true)}
                        levers={orderLevers}
                        showOptimizeButton={!showSimulation}
                        showToggle
                        legalLeverWeight={legalLeverWeight}
                        onToggleChange={setLegalLeverWeight}
                        afterToggleCahgne={handleToggleChange}
                        onChange={handleLeverChange}
                        onRemove={handleLeverRemove}
                    />
                </Col>
                <Col span={colSpans[1]}>
                    <OrderScore
                        title="Simulation Score"
                        description="Simulation Score"
                        averageScore={averageScore}
                        type="success"
                        levers={simulationLevers}
                        legalLeverWeight={legalLeverWeight}
                    />
                </Col>
            </Row>
            <Row justify="end" style={{ marginTop: 24 }}>
                <Space size={8}>
                    {isSuperAdmin(me.roles || []) ? (
                        <Button type="link" onClick={handleCopyOrdreLevers}>
                            Copy
                        </Button>
                    ) : null}
                    <Link to={`/order/new`}>
                        <Button>Go to Past deals</Button>
                    </Link>
                    <Button type="primary" onClick={generateNegotiation}>
                        Continue to Simulation
                    </Button>
                </Space>
            </Row>
        </>
    );
};
