import React, { useEffect, useRef } from "react";
import ReactECharts from "echarts-for-react";
import { ChartOptionProps, getChartOption } from "./IncomeChartOption";

interface IncomeChartProps extends ChartOptionProps {}

export const IncomeChart: React.FC<IncomeChartProps> = (props) => {
    const option = getChartOption(props);
    const chartRef = useRef<ReactECharts>(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            chartRef?.current?.getEchartsInstance().resize();
        }, 100);
        return () => clearTimeout(timer);
    }, []);

    return (
        <ReactECharts
            ref={chartRef}
            style={{
                width: "100%",
                height: 230,
            }}
            option={option}
        />
    );
};
