import { Row, Col, Table, Input, Image, Space, Button, Tag, InputRef, Popconfirm } from "antd";
import React, { createRef, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import {
    deleteVendor,
    listVendors,
    manageVendorIndustry,
    updateVendor,
} from "../../common/api/VendorClient";
import { SortFilter } from "../../common/CommonInterfaces";
import { handleError } from "../../common/ErrorHandling";
import { SearchOutlined } from "@ant-design/icons";
import {
    Filter,
    FilterGroupType,
    FilterOperand,
    Pagination,
    Sort,
    sortOrderTranslate,
} from "../../types/DataFetchingTypes";
import { getAllVendorTags, getTags } from "../../common/api/TagClient";
import { ResourceType } from "../../types/ResourceType";
import { Tag as TagType, TaggedResource } from "../../types/TagTypes";
import { DeleteVendorResponse, Vendor, VendorIndustry } from "../../types/VendorTypes";
import { convertTagListToFilter } from "../tag/TagTranslation";
import { useUserState } from "../../common/UserContext";
import { isSuperAdmin } from "../../types/UserGroup";
import { VendorUpdateModal } from "./components/VendorUpdateModal";
import { VendorDeleteModal } from "./components/VendorDeleteModal";

const { Column } = Table;

export const VendorsView: React.FC = () => {
    const { me } = useUserState();
    const [data, setData]: any[] = useState([]);
    const [pagination, setPagination] = useState({
        pages: 0,
        current: 1,
        total: 0,
        pageSize: 10,
    } as Pagination);

    const [sortFilter, setSortFilter] = useState({ sort: [], filter: [] } as SortFilter);
    const [tags, setTags] = useState(new Map<number, TaggedResource>());
    const [loading, setLoading]: any[] = useState(true);
    const [reload, setReload]: any[] = useState(0);
    const [tagFilter, setTagFilter] = useState([] as string[]);
    const [allVendorTags, setAllVendorTags] = useState([] as TagType[]);
    const [vendor, setVendor] = useState({} as Vendor);
    const [showUpdate, setShowUpdate] = useState(false);
    const [relatedData, setRelatedData] = useState({} as DeleteVendorResponse);
    const [showDelete, setShowDelete] = useState(false);

    const searchRef = createRef<InputRef>();

    const handleTableChange = (pagination: any, filters_: any, sorter: any) => {
        setPagination(pagination);

        const fs = { filter: [], sort: [] } as SortFilter;

        if (sorter.columnKey && sorter.order) {
            const sort = {
                attribute: sorter.columnKey,
                order: sortOrderTranslate(sorter.order),
            } as Sort;
            if (sorter.columnKey.indexOf(".") !== -1) {
                sort.prefix = sorter.columnKey.split(".")[0];
                sort.attribute = sorter.columnKey.split(".")[1];
            }
            fs.sort = [sort];
        }
        if (filters_.status) {
        }
        if (filters_.name) {
            const names = filters_.name.map((v: string) => {
                return {
                    attribute: "name",
                    operand: FilterOperand.SIMILAR,
                    value: v,
                } as Filter;
            });
            const nameSearch = {
                groupId: "name",
                groupType: FilterGroupType.AND,
                filter: names,
            };
            fs.filter.push(nameSearch);
        }
        if (filters_.tags) {
            setTagFilter(filters_.tags);
        } else {
            setTagFilter([]);
        }
        setSortFilter(fs);
        setReload(reload + 1);
    };

    const handleNameSearch = async (_selectedKeys: any, confirm: any) => {
        confirm({ closeDropdown: true });
    };

    const handleReset = (clearFilter: any) => {
        clearFilter();
    };

    const getAllTags = async () => {
        const data = await getAllVendorTags();
        setAllVendorTags(data);
    };

    const refreshVendors = async () => {
        setLoading(true);
        try {
            const pager = await listVendors(
                pagination,
                sortFilter.sort,
                sortFilter.filter,
                tagFilter
            );
            if (pager.pagination.total > 0) {
                const [tagged] = await Promise.all([
                    getTags(
                        pager.data.map((v) => v.id),
                        ResourceType.Vendor
                    ),
                ]);
                setTags(tagged);
            }
            setData(pager.data);
            setPagination(pager.pagination);
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        refreshVendors();
        getAllTags();
    }, [reload]);

    const handleEdit = (vendor: Vendor) => {
        setVendor(vendor);
        setShowUpdate(true);
    };

    const handleDelete = async (vendor: Vendor) => {
        try {
            const res = await deleteVendor(vendor);
            if (res.orderForms || res.negotiations) {
                setRelatedData(res);
                setShowDelete(true);
            } else {
                await refreshVendors();
            }
        } catch (error) {
            handleError(error);
        }
    };

    const handleUpdate = async (vendor: Vendor, industry?: VendorIndustry) => {
        try {
            await updateVendor(vendor);
            if (industry?.vendorName && industry?.sectorId && industry.industryId) {
                await manageVendorIndustry(industry);
            }
            await refreshVendors();
        } catch (error) {
            handleError(error);
        } finally {
            setShowUpdate(false);
        }
    };

    const vendorTable = (
        <Table
            dataSource={data}
            pagination={pagination}
            loading={loading}
            rowKey="vendorKey"
            onChange={handleTableChange}
        >
            <Column
                title=""
                dataIndex="hero"
                render={(value) => {
                    return <Image width={40} preview={false} src={value} />;
                }}
            />
            <Column
                title="Vendor Name"
                sorter={true}
                dataIndex="name"
                key="name"
                render={(value, row: Vendor) => {
                    return <Link to={`/vendor/${row.vendorKey}`}>{value}</Link>;
                }}
                filterIcon={<SearchOutlined />}
                filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                    return (
                        <div style={{ padding: 8 }}>
                            <Input
                                ref={searchRef}
                                placeholder={`Search `}
                                value={selectedKeys[0]}
                                onChange={(e) =>
                                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                                }
                                onPressEnter={() => {
                                    handleNameSearch(selectedKeys, confirm);
                                }}
                                style={{ marginBottom: 8, display: "block" }}
                            />
                            <Space>
                                <Button
                                    type="primary"
                                    onClick={() => handleNameSearch(selectedKeys, confirm)}
                                    icon={<SearchOutlined />}
                                    size="small"
                                    style={{ width: 90 }}
                                >
                                    Search
                                </Button>
                                <Button
                                    onClick={() => handleReset(clearFilters)}
                                    size="small"
                                    style={{ width: 90 }}
                                >
                                    Reset
                                </Button>
                                <Button
                                    type="link"
                                    size="small"
                                    onClick={() => {
                                        confirm({ closeDropdown: true });
                                    }}
                                >
                                    Close
                                </Button>
                            </Space>
                        </div>
                    );
                }}
            />
            <Column
                title="Renewal value"
                sorter={true}
                key="metrics.value"
                dataIndex="metrics.value"
                render={(_value, row: Vendor) => {
                    return (
                        <NumberFormat
                            value={row.metrics.value}
                            thousandSeparator={true}
                            displayType={"text"}
                            prefix={"$"}
                        />
                    );
                }}
            />
            <Column
                title="Orders for renewal"
                key="metrics.renewalOrders"
                dataIndex="metrics.renewalOrders"
                render={(_value, row: Vendor) => {
                    return <>{row.metrics.renewalOrders}</>;
                }}
            />
            <Column
                title="Total orders"
                key="metrics.orders"
                dataIndex="metrics.orders"
                render={(_value, row: Vendor) => {
                    return <>{row.metrics.orders}</>;
                }}
            />
            <Column
                title="Tags"
                dataIndex="tags"
                key="tags"
                filters={convertTagListToFilter(allVendorTags)}
                render={(_value, row: Vendor) => {
                    return (
                        <>
                            {tags.get(row.id)?.tags.map((t) => (
                                <Tag key={`${t.name}-tags-${row.id}-${t.color}`} color={t.color}>
                                    {t.name}
                                </Tag>
                            ))}
                        </>
                    );
                }}
            />
            {isSuperAdmin(me.roles || []) ? (
                <Column
                    title="Action"
                    key="action"
                    render={(_value, row: Vendor) => {
                        return (
                            <Space>
                                <Button type="primary" onClick={() => handleEdit(row)} size="small">
                                    Edit
                                </Button>
                                <Popconfirm
                                    title="Sure to delete?"
                                    onConfirm={() => handleDelete(row)}
                                >
                                    <Button danger type="ghost" size="small">
                                        Delete
                                    </Button>
                                </Popconfirm>
                            </Space>
                        );
                    }}
                />
            ) : null}
        </Table>
    );

    const panel = (
        <div style={{ paddingTop: 10 }}>
            <VendorUpdateModal
                open={showUpdate}
                vendor={vendor}
                onCancel={() => setShowUpdate(false)}
                onOk={handleUpdate}
            />
            <VendorDeleteModal
                open={showDelete}
                orderForms={relatedData.orderForms}
                negotiations={relatedData.negotiations}
                onCancel={() => setShowDelete(false)}
                onOk={() => setShowDelete(false)}
            />
            <Row justify={"center"}>
                <Col span={24}>{vendorTable}</Col>
            </Row>
        </div>
    );

    return panel;
};
