import { Col, Row, Tag } from "antd";
import { Link } from "react-router-dom";
import { formatDateString } from "../../components/date/DateOp";
import { getDayDiffFromDates } from "../../types/TimeTypes";
import { SvgIcon } from "./SvgIcon";

interface ExpirationDateCellProps {
    orderFormId: number;
    date?: string;
    showLink?: boolean;
}

const daysColorInterval = [
    {
        days: 60,
        color: "#4FBD19",
    },
    {
        days: 30,
        color: "#FF981F",
    },
    {
        days: 0,
        color: "#FD6883",
    },
];

const getColorByDays = (days?: number) => {
    if (typeof days !== "number" || !days)
        return daysColorInterval[daysColorInterval.length - 1].color;
    for (const item of daysColorInterval) {
        if (item.days < days) return item.color;
    }
    return daysColorInterval[daysColorInterval.length - 1].color;
};

export const ExpirationDateCell: React.FC<ExpirationDateCellProps> = ({
    orderFormId,
    date,
    showLink,
}) => {
    const dayDiff = date ? getDayDiffFromDates(new Date(), new Date(date)) : 365;
    const bg = getColorByDays(dayDiff);
    const label = date ? formatDateString(date) : "None";

    const link = showLink ? (
        <Col>
            <Link to={`/order/${orderFormId}`}>
                <SvgIcon name="outlink" size={15} />
            </Link>
        </Col>
    ) : null;

    return (
        <Row gutter={8} wrap={false}>
            <Col flex={1}>
                <Tag
                    style={{
                        background: bg,
                        color: "#ffffff",
                        fontWeight: 500,
                    }}
                >
                    {label}
                </Tag>
            </Col>
            {link}
        </Row>
    );
};
