import React, { useEffect, useState } from "react";
import { Form, InputNumber, Modal, ModalProps } from "antd";
import { TotalOpportunity } from "../../../types/ReportTypes";

interface TotalOpportunityModalProps extends Omit<ModalProps, "onOk"> {
    data?: TotalOpportunity;
    onOk?: (strategy: TotalOpportunity) => void;
}

export const TotalOpportunityModal: React.FC<TotalOpportunityModalProps> = ({
    data,
    onOk,
    ...restProps
}) => {
    const [form, setForm] = useState({} as TotalOpportunity);

    useEffect(() => {
        data && setForm(data);
    }, [data]);

    return (
        <Modal
            {...restProps}
            onOk={() => {
                onOk?.(form);
            }}
        >
            <Form layout="horizontal" size="small" labelCol={{ span: 12 }}>
                <Form.Item label="Minimum Total Opportunity ($)">
                    <InputNumber
                        controls={false}
                        value={form.minTotalOpportunity}
                        prefix={"$"}
                        onChange={(value) => {
                            setForm((old) => ({
                                ...old,
                                minTotalOpportunity: value || 0,
                            }));
                        }}
                    />
                </Form.Item>
                <Form.Item label="Maximum Total Opportunity ($)">
                    <InputNumber
                        controls={false}
                        value={form.maxTotalOpportunity}
                        prefix={"$"}
                        onChange={(value) => {
                            setForm((old) => ({
                                ...old,
                                maxTotalOpportunity: value || 0,
                            }));
                        }}
                    />
                </Form.Item>
                <Form.Item label="Minimum Total Opportunity (%)">
                    <InputNumber
                        controls={false}
                        value={form.minTotalOpportunityPercentage}
                        formatter={(value) => `${value}%`}
                        parser={(value) => Number(value?.replace("%", ""))}
                        onChange={(value) => {
                            setForm((old) => ({
                                ...old,
                                minTotalOpportunityPercentage: value || 0,
                            }));
                        }}
                    />
                </Form.Item>
                <Form.Item label="Maximum Total Opportunity (%)">
                    <InputNumber
                        controls={false}
                        value={form.maxTotalOpportunityPercentage}
                        formatter={(value) => `${value}%`}
                        parser={(value) => Number(value?.replace("%", ""))}
                        onChange={(value) => {
                            setForm((old) => ({
                                ...old,
                                maxTotalOpportunityPercentage: value || 0,
                            }));
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};
