import { DatePicker, Form, Input, Modal } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import moment from "moment";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { Navigate } from "react-router";
import { createOrderForm } from "../../common/api/OrderFormClient";
import { handleError } from "../../common/ErrorHandling";
import { OrderForm } from "../../types/OrderFormTypes";
import { VendorInput } from "../vendor/VendorInput";

interface OrderFormNewModalArguments {
    title?: string;
    showCategory?: boolean;
    showForm: boolean;
    onClose?: () => void;
    onOk?: <T>(form: T) => void;
}
export const OrderFormNewModal: React.FC<OrderFormNewModalArguments> = ({
    title = "New Order Form",
    showCategory = false,
    showForm,
    onClose,
    onOk,
}) => {
    const [orderForm, setOrderForm] = useState({
        value: 0,
        name: "",
        expirationDate: new Date(),
    } as OrderForm);
    const [order, setOrder] = useState(undefined as undefined | OrderForm);

    const createOrderFormFn = async () => {
        try {
            console.log("created a new copy");
            const ofr = await createOrderForm(orderForm);
            setOrder(ofr.orderForm);
        } catch (error: any) {
            handleError(error);
        }
    };

    const panel = (
        <Modal
            title={title}
            visible={showForm}
            onOk={() => {
                if (onOk) {
                    return onOk(orderForm);
                }
                createOrderFormFn();
            }}
            onCancel={() => {
                if (onClose) onClose();
            }}
        >
            <Form
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                layout="horizontal"
                size={"small" as SizeType}
            >
                <Form.Item label="Select Vendor">
                    <VendorInput
                        value={orderForm.vendorKey}
                        onSelect={(value) => {
                            orderForm.vendorKey = value;
                            setOrderForm({ ...orderForm });
                        }}
                    />
                </Form.Item>
                <Form.Item label="Order Name">
                    <Input
                        value={orderForm.name}
                        onChange={(e) => {
                            orderForm.name = e.target.value;
                            setOrderForm({ ...orderForm });
                        }}
                    />
                </Form.Item>
                <Form.Item label="Expiration date">
                    <DatePicker
                        value={moment(orderForm.expirationDate)}
                        style={{ width: "100%" }}
                        onChange={(date) => {
                            orderForm.expirationDate = date!.toDate();
                            setOrderForm({ ...orderForm });
                        }}
                    />
                </Form.Item>
                <Form.Item label="Value">
                    <NumberFormat
                        className={"ant-input"}
                        value={orderForm.value}
                        thousandSeparator={true}
                        displayType={"input"}
                        prefix={"$"}
                        onValueChange={(value) => {
                            orderForm.value = value.floatValue || 0;
                            setOrderForm({ ...orderForm });
                        }}
                    />
                </Form.Item>
              {showCategory && <Form.Item label="Category">
                <Input
                  value={orderForm.category}
                  onChange={(e) => {
                    orderForm.category = e.target.value;
                    setOrderForm({ ...orderForm });
                  }}
                />
              </Form.Item>}
            </Form>
        </Modal>
    );
    return order ? <Navigate to={`/order/${order.id}`} /> : panel;
};
