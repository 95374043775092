import React from "react";
import { Space } from "antd";
import { Link } from "react-router-dom";
import { getLogoBoxClassByType, LogoBoxType } from "../../../types/SupplierTypes";
import { getFullUrl } from "../../../common/Url";
import { SvgIcon } from "../../../common/components/SvgIcon";
import { CompanyLogo } from "./CompanyLogo";

interface CompanyNameProps {
    logo?: string;
    name: string;
    logoBoxType?: LogoBoxType;
    link?: string;
    linkTarget?: string;
    domain?: string;
    linkedin?: string;
    twitter?: string;
}

export const CompanyName: React.FC<CompanyNameProps> = ({
    logo,
    name,
    logoBoxType,
    link,
    linkTarget,
    domain,
    linkedin,
    twitter,
}) => {
    return (
        <div style={{ display: "flex", justifyContent: "space-between", gap: 16 }}>
            <Space style={{ flex: 1 }}>
                <CompanyLogo
                    logo={logo}
                    name={name}
                    className={getLogoBoxClassByType(logoBoxType)}
                />
                <span style={{ whiteSpace: "nowrap" }}>
                    {link ? (
                        <Link to={link} target={linkTarget}>
                            {name}
                        </Link>
                    ) : (
                        name
                    )}
                </span>
            </Space>
            <Space>
                {domain && (
                    <a
                        href={getFullUrl(domain)}
                        target="_blank"
                        rel="noreferrer"
                        data-testid="company-domain"
                    >
                        <SvgIcon name="global" />
                    </a>
                )}
                {linkedin && (
                    <a
                        href={linkedin}
                        target="_blank"
                        rel="noreferrer"
                        data-testid="company-linkedin"
                    >
                        <SvgIcon name="linkedin" />
                    </a>
                )}
                {twitter && (
                    <a
                        href={twitter}
                        target="_blank"
                        rel="noreferrer"
                        data-testid="company-twitter"
                    >
                        <SvgIcon name="x" />
                    </a>
                )}
            </Space>
        </div>
    );
};
