import React from "react";
import { Card } from "antd";
import { FeatureFlagTable } from "./components/FeatureFlagTable";

export const FeatureFlagsView: React.FC = () => {
    const content = (
        <div style={{ paddingTop: 10 }}>
            <Card title="Feature Flags">
                <FeatureFlagTable></FeatureFlagTable>
            </Card>
        </div>
    );
    return content;
};
