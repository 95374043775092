import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Dropdown, Menu, Row, Col, Divider, Avatar } from "antd";
import { LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import { geturl } from "../../common/api/SafeClient";
import { IntelligenceModal } from "../../common/components/IntelligenceModal";
import { User } from "../../types/UserGroup";
import "./AppHeader.less";
import { getNextMonthDates } from "../date/DateOp";
import { getExpiringOrders } from "../../common/api/OrderFormClient";

interface AppHeaderProps {
    me: Partial<User>;
    disabled?: boolean;
}

const PREFIX = "AppHeader";

export const AppHeader: React.FC<AppHeaderProps> = ({ me, disabled = false }) => {
    const [showModal, setShowModal] = useState(false);
    const [expiringCount, setExpiringCount] = useState(0);

    const timespan = getNextMonthDates();
    const getExpiraingCount = async () => {
        const res = await getExpiringOrders(timespan);
        setExpiringCount(res.total);
    };

    useEffect(() => {
        getExpiraingCount();
    }, []);

    const menu = (
        <Menu onClick={() => {}} mode="horizontal">
            <Menu.Item>Hi {me.firstName} 👋</Menu.Item>
            <Menu.Item icon={<SettingOutlined />}>
                <Link to="/preferences"> My preferences</Link>
            </Menu.Item>
            <Menu.Item icon={<LogoutOutlined />}>
                <a href={geturl("/api/logout")}>Log out</a>
            </Menu.Item>
        </Menu>
    );

    return (
        <Layout.Header className={`${PREFIX}`}>
            <IntelligenceModal
                open={showModal}
                onCancel={() => setShowModal(false)}
                name={me.firstName}
                expiringCount={expiringCount}
                timespan={timespan}
            />
            <Row>
                <Col flex={1}>
                    <Link to="/dashboard">
                        <img src="/infinity-loop-app-logo-full.svg" alt="logo" />
                    </Link>
                    {
                        // <Divider className={`${PREFIX}-divider`} type="vertical"></Divider>
                    }
                </Col>
                {
                    // <Col flex={1}>
                    //   <IntelligencPrompt
                    //     me={me}
                    //     onClick={() => !disabled && setShowModal(true)}
                    //   ></IntelligencPrompt>
                    // </Col>
                }
                <Col style={{ display: "inline-flex" }}>
                    <Dropdown overlay={menu}>
                        <Avatar
                            className={`${PREFIX}-avatar`}
                            shape="square"
                            size={64}
                            src={me.avatar || "/base-avatar.png"}
                        ></Avatar>
                    </Dropdown>
                </Col>
            </Row>
        </Layout.Header>
    );
};
