import React from "react";
import { SupplierInsight } from "../../types/SupplierTypes";
import { FinancialNews, FinancialNewsProps } from "./components/FinancialNews";
import { InsightsCard } from "./components/InsightsCard";

export interface SupplierOverviewRightPanelProps {
    insights: SupplierInsight[];
    financialNewsProps: FinancialNewsProps;
}

export const SupplierOverviewRightPanel: React.FC<SupplierOverviewRightPanelProps> = ({
    insights,
    financialNewsProps,
}) => {
    return (
        <div>
            <InsightsCard
                insights={insights}
                tip="Analytical observations or interpretations about the company's performance, strategy, market presence, or any other significant aspect that can provide a deeper understanding."
            />
            <FinancialNews
                {...financialNewsProps}
                tip="The five most significant or recent news articles or headlines pertaining to the company, which could range from product launches, financial results, to major partnerships or controversies."
                style={{
                    marginTop: 30,
                }}
            />
        </div>
    );
};
