import { Table } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { ColumnType } from "antd/lib/table";
import { ReactElement, useEffect, useState } from "react";
import {
    Filter,
    FilterGroup,
    FilterGroupType,
    FilterOperand,
    PagerObject,
    Sort,
    sortOrderTranslate,
} from "../../types/DataFetchingTypes";
import { SortFilterPaged } from "../CommonInterfaces";
import { handleError } from "../ErrorHandling";

interface TableArguments<T> {
    load: (pager: SortFilterPaged<T>, abort?: T) => Promise<PagerObject<T[]>>;
    columns: ColumnType<T>[];
    startingFilter?: SortFilterPaged<T>;
    size?: SizeType;
    style?: any;
    onLoad?: (data: T[], paging: SortFilterPaged<T>, reload: () => void) => void;
    refresh?: number;
}

export const SortableFilteringTable = <T extends object>(
    props: TableArguments<T>
): ReactElement | null => {
    const load = props.load;
    const startingFilter = props.startingFilter;
    const size = props.size;
    const style = props.style;
    const onLoad = props.onLoad;

    const [columns, setColumns] = useState(props.columns);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(0);
    const [data, setData] = useState([] as T[]);
    const [paging, setPaging] = useState(
        startingFilter !== undefined
            ? startingFilter
            : ({
                  filter: [] as FilterGroup[],
                  sort: [] as Sort[],
                  pager: {
                      pagination: {
                          current: 1,
                          pageSize: 10,
                      },
                  },
              } as SortFilterPaged<T>)
    );

    useEffect(() => {
        if (loading) return;
        setLoading(true);
        load(paging)
            .then((pager: PagerObject<T[]>) => {
                setData(pager.data);
                paging.pager.pagination = pager.pagination;
                setPaging({ ...paging });
            })
            .catch((error) => handleError(error))
            .finally(() => {
                setLoading(false);
                if (onLoad != null) {
                    onLoad(data, paging, () => {
                        setReload(reload + 1);
                    });
                }
            });
    }, [paging, reload]);

    useEffect(() => {
        setReload(reload + 1);
    }, [props.refresh]);

    const handleTableChange = async (pagination: any, filters_: any, sorter: any) => {
        if (sorter.columnKey && sorter.order) {
            paging.sort = [
                { attribute: sorter.columnKey, order: sortOrderTranslate(sorter.order) } as Sort,
            ];
        } else {
            paging.sort = [];
        }
        if (Object.entries(filters_).length > 0) {
            paging.filter = Object.entries(filters_).map((entry) => {
                if (entry[1] === null || entry[1] === undefined) {
                    return {
                        groupId: "",
                        groupType: FilterGroupType.AND,
                        filter: [],
                    } as FilterGroup;
                }
                const filter_ = (entry[1] as string[]).map((v: string) => {
                    return {
                        attribute: entry[0],
                        operand: FilterOperand.SIMILAR,
                        value: v,
                    } as Filter;
                });
                const group_ = {
                    groupId: entry[0],
                    groupType: FilterGroupType.OR,
                    filter: filter_,
                };
                return group_;
            });
        }

        columns.forEach((c) => {
            const filtering = paging.filter.filter((fi) => fi.groupId === c.key);
            if (filtering.length !== 1) {
                c.filteredValue = [];
                return;
            }

            const values = filtering[0].filter.map((f) => f.value);
            c.filteredValue = values;
        });

        setColumns(columns.concat([]));

        paging.pager.pagination = pagination;
        setPaging({ ...paging });
    };

    return (
        <Table
            dataSource={data as T[]}
            columns={columns}
            pagination={paging.pager.pagination}
            loading={loading}
            rowKey={"id"}
            size={size !== undefined ? size : ("medium" as SizeType)}
            style={style}
            onChange={handleTableChange}
        />
    );
};
