import { Button, Switch, Table, Typography } from "antd";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { ReactComponent as EditIcon } from "../../../assets/icons/svg/edit.svg";
import { CompanyCard } from "../../../common/components/CompanyCard";
import { UserCard } from "../../../common/components/UserCard";
import { OrderForm } from "../../../types/OrderFormTypes";
import { formatUTCDate } from "../../date/DateOp";
import { OrderFormUpdateModal } from "./OrderFormUpdateModal";

interface OrderFormTableProps {
    onChange?: (orderForm: OrderForm) => void;
    orderForm?: OrderForm;
    disabled?: boolean;
}

export const OrderFormTable: React.FC<OrderFormTableProps> = ({
    onChange,
    orderForm = {} as OrderForm,
    disabled,
}) => {
    const [showUpdate, setShowUpdate] = useState(false);

    return (
        <>
            <OrderFormUpdateModal
                open={showUpdate}
                orderForm={orderForm}
                onCancel={() => setShowUpdate(false)}
                onOk={(orderForm) => {
                    onChange?.(orderForm);
                    setShowUpdate(false);
                }}
            ></OrderFormUpdateModal>
            <Table
                dataSource={[orderForm]}
                pagination={false}
                columns={[
                    {
                        title: "Business owner",
                        dataIndex: "ownerId",
                        render: (value) => {
                            return <UserCard userId={value} />;
                        },
                    },
                    {
                        title: "Vendor",
                        dataIndex: "vendorKey",
                        render: (value) => {
                            return <CompanyCard vendorkey={value} />;
                        },
                    },
                    {
                        title: "Value",
                        dataIndex: "value",
                        render: (value) => {
                            return (
                                <NumberFormat
                                    value={value}
                                    thousandSeparator={true}
                                    displayType="text"
                                    prefix={"$"}
                                />
                            );
                        },
                    },
                    {
                        title: "Expiration",
                        dataIndex: "expirationDate",
                        render: (value) => {
                            return value && formatUTCDate(value);
                        },
                    },
                    {
                        title: "Order term",
                        dataIndex: "orderStartDate",
                        render: (value, row) => {
                            return !value && !row.orderEndDate
                                ? ""
                                : `${(value && formatUTCDate(value)) ?? "None"} - ${
                                      (row.orderEndDate && formatUTCDate(row.orderEndDate)) ??
                                      "None"
                                  }`;
                        },
                    },
                    {
                        title: "Migration length",
                        dataIndex: "migrationLength",
                    },
                    {
                        title: "Category",
                        dataIndex: "category",
                        render: (value) => {
                            return (
                                <Typography.Text
                                    style={{ maxWidth: 150 }}
                                    ellipsis={{ tooltip: value }}
                                >
                                    {value}
                                </Typography.Text>
                            );
                        },
                    },
                    {
                        title: "Auto renew",
                        dataIndex: "autoRenewal",
                        render: (value) => {
                            return <Switch checked={value}></Switch>;
                        },
                    },
                    {
                        title: "Actions",
                        render: () => {
                            return (
                                <Button
                                    type="link"
                                    icon={
                                        <EditIcon width={12} height={12} fill="#1890FF"></EditIcon>
                                    }
                                    onClick={() => setShowUpdate(true)}
                                    disabled={disabled}
                                ></Button>
                            );
                        },
                    },
                ]}
            ></Table>
        </>
    );
};
