import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Collapse, Row, Table } from "antd";
import { SvgIcon } from "../../../common/components/SvgIcon";
import {
    NegotiationStrategy,
    StrategyWithOpportunity,
    TotalOpportunity,
} from "../../../types/ReportTypes";
import { NegotiationStrategyModal } from "./NegotiationStrategyModal";
import { TotalOpportunityModal } from "./TotalOpportunityModal";
import styles from "./NegotiationStrategySummary.module.less";
import { HtmlSummaryEditModal } from "./HtmlSummaryEditModal";

const { Panel } = Collapse;
const PANEL_KEY = "singlePanel";

const savingsRender = (value: number, row: StrategyWithOpportunity) => {
    return row.isPercentage ? `${value}%` : `$${value}`;
};

interface NegotiationStrategySummaryProps {
    title: string;
    description: string;
    html: string;
    onUpdate?: (summary: string) => void;
    strategies?: StrategyWithOpportunity[];
    onChange?: (strategy: NegotiationStrategy) => void;
    totalOpportunity?: TotalOpportunity;
    onOpportunityChange?: (data: TotalOpportunity) => void;
    open?: boolean;
    editable?: boolean;
}

export const NegotiationStrategySummary: React.FC<NegotiationStrategySummaryProps> = ({
    title,
    description,
    strategies,
    html,
    onUpdate,
    onChange,
    totalOpportunity,
    onOpportunityChange,
    open,
    editable = false,
}) => {
    const [showEdit, setShowEdit] = useState(false);
    const [showStrategyModal, setShowStrategyModal] = useState(false);
    const [toEdit, setToEdit] = useState<NegotiationStrategy>();
    const [showOpportunityModal, setShowOpportunityModal] = useState(false);
    const [activeKey, setActiveKey] = useState([PANEL_KEY]);

    const dataSource = strategies?.concat([
        {
            name: "Total opportunity ($)",
            minSavings: totalOpportunity?.minTotalOpportunity,
            maxSavings: totalOpportunity?.maxTotalOpportunity,
            isOpportunity: true,
            rowClass: styles.opportunity,
        } as StrategyWithOpportunity,
        {
            name: "Total opportunity (%)",
            minSavings: totalOpportunity?.minTotalOpportunityPercentage,
            maxSavings: totalOpportunity?.maxTotalOpportunityPercentage,
            isOpportunity: true,
            isPercentage: true,
            rowClass: styles.opportunityPercentage,
        } as StrategyWithOpportunity,
    ]);

    const handleEditClick = (row: StrategyWithOpportunity) => {
        if (row.isOpportunity) {
            setShowOpportunityModal(true);
            return;
        }
        setToEdit(row);
        setShowStrategyModal(true);
    };

    const handleAddClick = () => {
        setToEdit({} as StrategyWithOpportunity);
        setShowStrategyModal(true);
    };

    const editButton = editable ? (
        <Col>
            <Button
                className={styles.edit}
                type="link"
                onClick={(e) => {
                    setShowEdit(true);
                    e.stopPropagation();
                }}
            >
                Edit
            </Button>
        </Col>
    ) : null;

    useEffect(() => {
        if (open) {
            setActiveKey([PANEL_KEY]);
        } else if (open === false) {
            setActiveKey([]);
        }
    }, [open]);

    const actionColumn = editable
        ? [
              {
                  title: (
                      <Button type="link" size="small" onClick={handleAddClick}>
                          Add
                      </Button>
                  ),
                  key: "actioin",
                  render: (_: any, row: NegotiationStrategy) => {
                      return (
                          <Button type="link" size="small" onClick={() => handleEditClick(row)}>
                              Edit
                          </Button>
                      );
                  },
              },
          ]
        : [];

    return (
        <>
            <HtmlSummaryEditModal
                open={showEdit}
                title={title}
                summary={html}
                onOk={(value) => {
                    onUpdate?.(value);
                    setShowEdit(false);
                }}
                onCancel={() => setShowEdit(false)}
                width={1200}
            />
            <NegotiationStrategyModal
                open={showStrategyModal}
                strategy={toEdit}
                title={title}
                width={1200}
                onCancel={() => setShowStrategyModal(false)}
                onOk={(data) => {
                    onChange?.(data);
                    setShowStrategyModal(false);
                }}
            />
            <TotalOpportunityModal
                open={showOpportunityModal}
                data={totalOpportunity}
                title="Total opportunity"
                onCancel={() => setShowOpportunityModal(false)}
                onOk={(data) => {
                    onOpportunityChange?.(data);
                    setShowOpportunityModal(false);
                }}
            />
            <Collapse
                className={styles.summary}
                bordered={false}
                collapsible="header"
                expandIconPosition="end"
                expandIcon={() => <SvgIcon name="down" size={20} className={styles.expandIcon} />}
                activeKey={activeKey}
                onChange={(value) => setActiveKey(value as string[])}
            >
                <Panel
                    header={
                        <Row align="middle">
                            <Col flex={1}>
                                <div className={styles.headerTitle}>{title}</div>
                                <div>{description}</div>
                            </Col>
                            {editButton}
                        </Row>
                    }
                    key={PANEL_KEY}
                >
                    <div className={styles.sectionTitle}>
                        Summary of leverage points and potential savings
                    </div>
                    <Table
                        dataSource={dataSource}
                        className={styles.strategyTable}
                        size="small"
                        pagination={false}
                        rowSelection={{
                            type: "checkbox",
                            hideSelectAll: true,
                            renderCell: (_, row) => {
                                return row.isOpportunity ? null : (
                                    <Checkbox
                                        checked={row.used}
                                        className={styles.check}
                                        onChange={(value) =>
                                            onChange?.({ ...row, used: value.target.checked })
                                        }
                                    />
                                );
                            },
                        }}
                        rowClassName={(item) => item.rowClass || ""}
                        columns={[
                            {
                                title: "Leverage point",
                                dataIndex: "name",
                                key: "name",
                            },
                            {
                                title: "Negotiation ask",
                                dataIndex: "ask",
                                key: "ask",
                                width: 620,
                            },
                            {
                                title: "Potential savings (Low end)",
                                dataIndex: "minSavings",
                                key: "minSavings",
                                render: savingsRender,
                            },
                            {
                                title: "Potential savings (High end)",
                                dataIndex: "maxSavings",
                                key: "maxSavings",
                                render: savingsRender,
                            },
                            ...actionColumn,
                        ]}
                    ></Table>
                    <div dangerouslySetInnerHTML={{ __html: html }}></div>
                </Panel>
            </Collapse>
        </>
    );
};
