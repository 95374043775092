import React from "react";
import { Space, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import styles from "./FinancialOverviewCard.module.less";

export interface FinancialOverviewCardProps {
    title: string;
    tip: string;
    data: number | string;
    dataColor?: string;
}

export const FinancialOverviewCard: React.FC<FinancialOverviewCardProps> = ({
    title,
    tip,
    data,
    dataColor,
}) => {
    return (
        <div className={styles.main}>
            <Space size={4}>
                <span className={styles.title}>{title}</span>
                <Tooltip title={tip}>
                    <ExclamationCircleOutlined className={styles.tipIcon} />
                </Tooltip>
            </Space>
            <div
                style={{
                    color: dataColor,
                }}
                className={styles.data}
            >
                {`$${data}`}
            </div>
        </div>
    );
};
