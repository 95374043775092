import React, { CSSProperties, useEffect, useState } from "react";
import { Col, Row, Segmented } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { getSupplierRisks } from "../../common/api/DashboardClientV2";
import { getFourQuarters, TimeQuarter } from "../../types/TimeTypes";
import { SupplierRisk } from "../../types/DashboardTypes";
import { TopSuppliresTable } from "./components/TopSuppliersTable";

interface TopSuppliersPanelProps {
    style?: CSSProperties;
}

export const TopSuppliersPanel: React.FC<TopSuppliersPanelProps> = ({ style }) => {
    const [selectedQuarter, setSelectedQuarter] = useState<TimeQuarter>();
    const quarters = getFourQuarters(new Date());
    const options = ["All", ...quarters.map((q) => `${q.year} Q${q.quarter}`)];
    const [data, setData] = useState([] as SupplierRisk[]);

    const refeshData = async () => {
        const data = await getSupplierRisks(selectedQuarter);
        setData(data);
    };

    const handleQuarterChange = (q: SegmentedValue) => {
        const idx = options.indexOf(q as string);
        if (idx > 0) {
            const quarter = quarters[idx - 1];
            setSelectedQuarter(quarter);
        } else {
            setSelectedQuarter(undefined);
        }
    };

    useEffect(() => {
        refeshData();
    }, [selectedQuarter]);

    return (
        <div style={style}>
            <Row align="middle">
                <Col flex={1}>
                    <div className="sectionTitle">Top Supplier Risks</div>
                    <div className="sectionSubtitle">
                        Identify and address potential risks that could lead to increased budget
                        expenditures.
                    </div>
                </Col>
                <Col>
                    <Segmented
                        options={options}
                        style={{ marginLeft: 12 }}
                        onChange={handleQuarterChange}
                    />
                </Col>
            </Row>
            <TopSuppliresTable data={data} />
        </div>
    );
};
