import { useEffect, useState } from "react";
import { DatePicker, Form, Input, InputNumber, Modal, ModalProps, Switch } from "antd";
import NumberFormat from "react-number-format";
import { UserSelect } from "../../../common/components/UserSelect";
import { OrderForm } from "../../../types/OrderFormTypes";
import { DATE_FORMAT, handleUTCDate } from "../../date/DateOp";
import { VendorInput } from "../../vendor/VendorInput";
import { useUserState } from "../../../common/UserContext";
import { isSuperAdmin } from "../../../types/UserGroup";

interface OrderFormUpdateModalProps extends Omit<ModalProps, "onOk"> {
    orderForm?: OrderForm;
    onOk?: (orderForm: OrderForm) => void;
}

export const OrderFormUpdateModal: React.FC<OrderFormUpdateModalProps> = ({
    orderForm,
    onOk,
    ...restProps
}) => {
    const { me } = useUserState();
    const [orderCopy, setOrderCopy] = useState({} as OrderForm);

    useEffect(() => {
        orderForm && setOrderCopy(orderForm);
    }, [orderForm]);

    return (
        <Modal
            title="Update Order Form"
            {...restProps}
            onOk={() => {
                onOk?.(orderCopy);
            }}
        >
            <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} layout="horizontal" size="small">
                <Form.Item label="Business owner">
                    <UserSelect
                        userId={orderCopy.ownerId}
                        onSelect={(value) => {
                            setOrderCopy((old) => ({ ...old, ownerId: value }));
                        }}
                    />
                </Form.Item>
                <Form.Item label="Vendor">
                    <VendorInput
                        value={orderCopy.vendorKey}
                        onSelect={(value) => {
                            setOrderCopy((old) => ({ ...old, vendorKey: value }));
                        }}
                    />
                </Form.Item>
                <Form.Item label="Value">
                    <NumberFormat
                        className={"ant-input"}
                        value={orderCopy.value}
                        thousandSeparator={true}
                        displayType={"input"}
                        prefix={"$"}
                        onValueChange={(value) => {
                            setOrderCopy((old) => ({ ...old, value: value.floatValue || 0 }));
                        }}
                    />
                </Form.Item>
                <Form.Item label="Expiration date">
                    <DatePicker
                        value={orderCopy.expirationDate && handleUTCDate(orderCopy.expirationDate)}
                        style={{ width: "100%" }}
                        format={DATE_FORMAT}
                        onChange={(date) => {
                            setOrderCopy((old) => ({
                                ...old,
                                expirationDate: date?.toISOString() as unknown as Date,
                            }));
                        }}
                    />
                </Form.Item>
                <Form.Item label="Order Term">
                    <Input.Group compact>
                        <DatePicker.RangePicker
                            allowEmpty={[false, false]}
                            allowClear={false}
                            value={
                                orderCopy.orderStartDate
                                    ? [
                                          handleUTCDate(orderCopy.orderStartDate),
                                          handleUTCDate(orderCopy.orderEndDate || undefined),
                                      ]
                                    : ([] as any)
                            }
                            format={DATE_FORMAT}
                            onCalendarChange={(dates, dateStrings) => {
                                if (dates !== undefined && dates!.length > 1) {
                                    setOrderCopy((old) => ({
                                        ...old,
                                        orderStartDate:
                                            dates?.[0]?.toISOString() as unknown as Date,
                                        orderEndDate: dates?.[1]?.toISOString() as unknown as Date,
                                    }));
                                }
                            }}
                        />
                    </Input.Group>
                </Form.Item>
                <Form.Item label="Migration Length">
                    <InputNumber
                        value={orderCopy.migrationLength}
                        onChange={(value) => {
                            setOrderCopy((old) => ({ ...old, migrationLength: value as number }));
                        }}
                    />
                </Form.Item>

                <Form.Item label="Category">
                    <Input
                        value={orderCopy.category}
                        onChange={(e) => {
                            setOrderCopy((old) => ({ ...old, category: e.target.value }));
                        }}
                    />
                </Form.Item>
                <Form.Item label="Auto renew">
                    <Switch
                        checked={orderCopy.autoRenewal}
                        onChange={(value) => {
                            setOrderCopy((old) => ({ ...old, autoRenewal: value }));
                        }}
                    ></Switch>
                </Form.Item>
                {isSuperAdmin(me.roles || []) ? (
                    <>
                        <Form.Item label="Increase">
                            <NumberFormat
                                className={"ant-input"}
                                value={orderCopy.increase}
                                thousandSeparator={true}
                                displayType={"input"}
                                prefix={"$"}
                                onValueChange={(value) => {
                                    setOrderCopy((old) => ({
                                        ...old,
                                        increase: value.floatValue || 0,
                                    }));
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="Savings">
                            <NumberFormat
                                className={"ant-input"}
                                value={orderCopy.decrease}
                                thousandSeparator={true}
                                displayType={"input"}
                                prefix={"$"}
                                onValueChange={(value) => {
                                    setOrderCopy((old) => ({
                                        ...old,
                                        decrease: value.floatValue || 0,
                                    }));
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="Optimized Score">
                            <InputNumber
                                controls={false}
                                value={orderCopy.optimizedScore}
                                formatter={(value) => `${value}%`}
                                parser={(value) => Number(value?.replace("%", ""))}
                                onChange={(value) => {
                                    setOrderCopy((old) => ({
                                        ...old,
                                        optimizedScore: value || 0,
                                    }));
                                }}
                            />
                        </Form.Item>
                    </>
                ) : null}
            </Form>
        </Modal>
    );
};
