import { useEffect, useState } from "react";
import { Card, Tabs } from "antd";
import ReviewList from "../review/ReviewList";
import { Vendor } from "../../types/VendorTypes";
import { OrderFormPageHeader } from "./components/OrderFormPageHeader";
import { OrderFormAddTopPanel } from "./OrderFormAddTopPanel";
import { OrderFormList } from "./components/OrderFormList";
import { useUserState } from "../../common/UserContext";
import { FeatureFlagType } from "../../types/FeatureFlagTypes";
import { isFlagEnabled, isOnboarding, isSuperAdmin } from "../../types/UserGroup";

export const OrderFormsView: React.FC = () => {
    const [refresh, setRefresh] = useState(0);
    const [vendorKeys, setVendorKeys] = useState<string[]>([]);
    const { me } = useUserState();
    const showUpload =
        isSuperAdmin(me.roles || []) || !isFlagEnabled(FeatureFlagType.Upload, me.flags);

    const showScore = !isOnboarding(me.roles || []);

    const handleVendorSelect = (vendor: Vendor[]) => {
        setVendorKeys(vendor.map((v) => v.vendorKey));
    };

    useEffect(() => {
        setRefresh((pre) => pre + 1);
    }, [vendorKeys]);

    const mainPanel = (
        <>
            <div className="contract-panel">
                <Card>
                    <OrderFormPageHeader onSelect={handleVendorSelect} />
                    <OrderFormAddTopPanel
                        style={{ marginTop: 20 }}
                        onUpload={() => {
                            setRefresh((pre) => pre + 1);
                        }}
                        showUpload={showUpload}
                        refresh={refresh}
                    ></OrderFormAddTopPanel>
                    <Tabs
                        items={[
                            {
                                label: "New",
                                key: "new",
                                children: (
                                    <ReviewList
                                        refresh={refresh}
                                        vendorKeys={vendorKeys}
                                        onDelete={() => {
                                            setRefresh((pre) => pre + 1);
                                        }}
                                    />
                                ),
                            },
                            {
                                label: "All",
                                key: "all",
                                children: (
                                    <OrderFormList
                                        vendorKeys={vendorKeys}
                                        refresh={refresh}
                                        showScore={showScore}
                                        onDelete={() => {
                                            setRefresh((pre) => pre + 1);
                                        }}
                                        showNotify
                                        showDelete
                                    />
                                ),
                            },
                        ]}
                    ></Tabs>
                </Card>
            </div>
        </>
    );

    return mainPanel;
};
