import React, { CSSProperties } from "react";
import { Table } from "antd";
import { formatNumberWithSuffix } from "../../../common/Number";
import { BalanceSheetStatement, CashFlowStatement } from "../../../types/FinanceTypes";
import styles from "./RetainedEarningSchedule.module.less";

const { Column } = Table;

interface EarningRow {
    label: string;
    value: string | number;
}

interface RetainedEarningScheduleProps {
    balanceSheets?: BalanceSheetStatement[];
    cashFlows?: CashFlowStatement[];
    style?: CSSProperties;
}

export const RetainedEarningSchedule: React.FC<RetainedEarningScheduleProps> = ({
    balanceSheets,
    cashFlows,
    style,
}) => {
    const balance = balanceSheets?.[0];
    const previousBalance = balanceSheets?.[1];
    const cash = cashFlows?.[0];

    const dataSource: EarningRow[] = [
        {
            label: "Retained earnings (previous year)",
            value: formatNumberWithSuffix(previousBalance?.retainedEarnings || 0),
        },
        {
            label: "Net income",
            value: formatNumberWithSuffix(cash?.netIncome || 0),
        },
        {
            label: "Stock repurchases",
            value: formatNumberWithSuffix(cash?.commonStockRepurchased || 0),
        },
        {
            label: "Dividend paid",
            value: formatNumberWithSuffix(cash?.commonDividendsPaid || 0),
        },
        {
            label: "Retained earnings",
            value: formatNumberWithSuffix(balance?.retainedEarnings || 0),
        },
        {
            label: "Other distributions",
            value: formatNumberWithSuffix(cash?.otherFinancingActivities || 0),
        },
    ];
    return (
        <div className={styles.container} style={style}>
            <div className={styles.title}>Retained earning schedule</div>
            <Table dataSource={dataSource} pagination={false} size="small">
                <Column title="Year" key="year" dataIndex="label"></Column>
                <Column
                    title={balance?.fiscalYear}
                    key="value"
                    dataIndex="value"
                    width={230}
                ></Column>
            </Table>
        </div>
    );
};
