import { AutoComplete, Avatar, Select } from "antd";
import React, { useEffect, useState } from "react";
import { BankOutlined } from "@ant-design/icons";
import { getVendors, loadVendorSuggestions } from "../../common/api/VendorClient";
import { Vendor } from "../../types/VendorTypes";
import { Link } from "react-router-dom";
import { NewVendorModal } from "./NewVendorModal";

interface VendorInputArgument {
    value?: string;
    onSelect?: (vendorKey: string, vendor?: Vendor) => void;
    disabled?: boolean;
}

interface VendorSelectArgument {
    onSelect?: (vendors: Vendor[]) => void;
}


interface VendorOption extends Vendor {
    isSelected?: boolean;
}

export const VendorSelectInput: React.FC<VendorSelectArgument> = ({ onSelect }) => {
    const [searchValue, setSearchValue] = useState("");
    const [selectedValues, setSelectedValues] = useState([] as Vendor[]);
    const [vendorOptions, setVendorOptions] = useState([] as VendorOption[]);

    useEffect(() => {
        const ac = new AbortController();
        loadVendorSuggestions(searchValue, { signal: ac.signal })
            .then((vendors) => {
                const selectedIds = selectedValues.map((v) => v.id);
                const filteredVendors = vendors.filter((v) => selectedIds.indexOf(v.id) === -1);
                setVendorOptions(filteredVendors);
            })
            .catch((error) => {});
        return () => ac.abort();
    }, [searchValue]);

    const handleOptionsChange = (value: string, isSelect = true) => {
        const selectedIndex = vendorOptions.findIndex((v) => v.vendorKey === value);
        const newOptions = [...vendorOptions];
        const toChange = newOptions[selectedIndex];
        if (toChange) {
            toChange.isSelected = isSelect;
        }
        setVendorOptions(newOptions);
        return toChange;
    };

    const handleSelect = (value: string) => {
        const selected = handleOptionsChange(value);
        const newSelected = [...selectedValues, selected];
        setSelectedValues(newSelected);
        onSelect?.(newSelected);
    };

    const handleDeselect = (value: string) => {
        handleOptionsChange(value, false);
        const newSelected = selectedValues.filter((v) => v.vendorKey !== value);
        setSelectedValues(newSelected);

        if (newSelected.length === 0) {
            setSearchValue("");
        }

        onSelect?.(newSelected);
    };

    const select = (
        <Select
            mode="multiple"
            showArrow
            maxTagCount={"responsive" as const}
            // tagRender={tagRender}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
            placeholder={"Vendor"}
            style={{ width: "100%" }}
            onSearch={(e) => {
                setSearchValue(e);
            }}
            options={selectedValues.concat(vendorOptions.filter((v) => !v.isSelected)).map((v) => {
                return { value: v.vendorKey, label: renderVendorHero(v, 20) };
            })}
        />
    );
    return select;
};
const renderVendorHero = (vendor: Vendor, size: number = 32) => {
    return (
        <>
            <Avatar
                shape="square"
                style={{ width: size, height: size }}
                src={vendor.hero ? vendor.hero : ""}
            />
            <span style={{ paddingLeft: 10 }}>{vendor.name}</span>
        </>
    );
};

export const VendorInput: React.FC<VendorInputArgument> = ({ value, onSelect, disabled }) => {
    const os = onSelect;
    const [searchValue, setSearchValue] = useState("");
    const [vendKey] = useState(value);
    const [vendorOptions, setVendorOptions] = useState([] as Vendor[]);
    const [showSelector, setShowSelector] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState({
        vendorKey: "",
        hero: "",
        name: "",
        url: "",
        description: "",
    } as Vendor);

    const [showNewVendorModal, setShowNewVendorModal] = useState(false);

    useEffect(() => {
        const ac = new AbortController();
        if (vendKey !== undefined && vendKey !== null && vendKey.length > 0) {
            getVendors([vendKey], { signal: ac.signal })
                .then((vendors) => {
                    if (vendors.length > 0) setSelectedVendor(vendors[0]);
                })
                .catch((error) => {});
        }
        return () => ac.abort();
    }, [vendKey]);

    useEffect(() => {
        const ac = new AbortController();
        loadVendorSuggestions(searchValue, { signal: ac.signal })
            .then((vendors) => {
                setVendorOptions(vendors);
            })
            .catch((error) => {});
        return () => ac.abort();
    }, [searchValue]);

    const newVendorModal = showNewVendorModal ? (
        <NewVendorModal
            onCreate={(vendor) => {
                setSelectedVendor(vendor);
                setShowNewVendorModal(false);
                setShowSelector(false);
                if (os) {
                    os(vendor.vendorKey);
                }
            }}
            onCancel={() => setShowNewVendorModal(false)}
            value={searchValue}
        />
    ) : (
        <></>
    );

    const input = (
        <AutoComplete
            size={"small"}
            options={Array.from(vendorOptions.values()).map((v) => {
                return { value: v.vendorKey, label: renderVendorHero(v) };
            })}
            style={{ minWidth: 100 }}
            onSelect={(value: any) => {
                if (os !== undefined) {
                    os(value as string);
                }
                const vendor = vendorOptions.filter((v) => v.vendorKey === value);
                if (vendor.length > 0) {
                    setSelectedVendor(vendor[0]);
                }
                setShowSelector(false);
            }}
            onSearch={(e) => {
                setSearchValue(e);
            }}
            onKeyDown={(e) => {
                if (e.key === "Escape") {
                    setShowSelector(false);
                    setSearchValue("");
                } else if (e.key === "Enter") {
                    setShowNewVendorModal(true);
                }
            }}
            disabled={disabled}
            placeholder={"Vendor Name"}
        ></AutoComplete>
    );

    const vendorView = (
        <>
            -&nbsp;
            <Link to={`/vendor/${selectedVendor.vendorKey}`}>
                <BankOutlined style={{ fontSize: "14px" }} />
            </Link>
        </>
    );

    const vendorLine = (
        <>
            <Avatar shape="square" src={selectedVendor.hero ? selectedVendor.hero : ""} />
            <span style={{ paddingLeft: 10 }}>{selectedVendor.name}</span>
        </>
    );
    const editLink = (
        <a
            href="http://example.com"
            onClick={(e) => {
                setShowSelector(true);
                e.preventDefault();
            }}
        >
            {vendorLine}
        </a>
    );
    const vendorHero = (
        <div>
            {disabled ? vendorLine : editLink}
            &nbsp;
            {selectedVendor.vendorKey.length > 0 ? vendorView : ""}
        </div>
    );
    return (
        <>
            {newVendorModal}
            {showSelector ? input : vendorHero}
        </>
    );
};
