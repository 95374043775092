import React, { useEffect, useState } from "react";
import { Button, Card, Col, notification, Row, Segmented, Select, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { TopSuppliersPanel } from "./TopSuppliersPanel";
import { CostChartPanel } from "./CostChartPanel";
import { VendorHeatmapPanel } from "./VendorHeatmapPanel";
import { getOrderForm } from "../../common/api/OrderFormClient";
import { getExpiringOrderNotifications } from "../../common/api/NotificationsClient";
import { OrderForm } from "../../types/OrderFormTypes";
import { UserGuideModal } from "../../common/components/UserGuideModal";
import { useUserState } from "../../common/UserContext";
import { User } from "../../types/UserGroup";
import { updateUser } from "../../common/api/UserClient";
import { getOrderExpirationYear } from "../../common/api/DashboardClientV2";
import { OrderExpirationYear } from "../../types/DashboardTypes";
import { handleError } from "../../common/ErrorHandling";
import { NegotiationIntelligencePanel } from "./NegotiationIntelligencePanel";
import { CalendarDashboard } from "./CalendarDashboard";

enum TabKey {
    Tactical = "Tactical",
    Overview = "Overview",
}

const tabKeys = Object.values(TabKey);

const NOTIFIED_ORDER_IDS_KEY = "notifiedOrderIds";

export const DashboardView = () => {
    const [orderForm, setOrderForm] = useState<OrderForm>();
    const navigate = useNavigate();
    const { me } = useUserState();
    const [showUserGuide, setShowUserGuide] = useState(false);
    const [tab, setTab] = useState(TabKey.Tactical);
    const [years, setYears] = useState<OrderExpirationYear[]>();
    const [selectedYear, setSelectedYear] = useState<number>();

    const yearSelectOptions = years?.map((item) => {
        return { label: item.year, value: item.year };
    });

    const loadExpiringOrder = async () => {
        const notifications = await getExpiringOrderNotifications();
        const notifiedOrderIds = JSON.parse(sessionStorage.getItem(NOTIFIED_ORDER_IDS_KEY) || "[]");
        const filteredNotifications = notifications.filter(
            (item) => !notifiedOrderIds.includes(item.resource.resourceId)
        );
        if (filteredNotifications.length) {
            const res = await getOrderForm(filteredNotifications[0].resource.resourceId);

            sessionStorage.setItem(
                NOTIFIED_ORDER_IDS_KEY,
                JSON.stringify([...notifiedOrderIds, res.orderForm.id])
            );
            setOrderForm(res.orderForm);
        }
    };

    const getYearSelectOptions = async () => {
        const data = await getOrderExpirationYear();
        setYears(data);
        const defaultYear =
            data.find((item) => item.year === new Date().getFullYear())?.year || data[0]?.year;
        setSelectedYear(defaultYear);
    };

    const updateUserGuide = async () => {
        try {
            await updateUser({
                ...me,
                guided: true,
            } as User);
        } catch (error) {
            handleError(error);
        } finally {
            setShowUserGuide(false);
        }
    };

    useEffect(() => {
        loadExpiringOrder();
        getYearSelectOptions();
    }, []);

    useEffect(() => {
        if (!me.guided) {
            setShowUserGuide(true);
        }
    }, [me.guided]);

    const alert = () => {
        if (orderForm) {
            const key = `expiring-orderForm-${orderForm.id}`;
            notification.warning({
                message: (
                    <>
                        <div style={{ color: "#FF8400", fontWeight: 500 }}>
                            You have a contract expiring soon.
                        </div>
                        <div style={{ color: "#000000", fontSize: 14 }}>
                            Your contract “{orderForm.name}” is set to expire soon. Review its
                            Utilization measure to see how you can improve your contract.
                        </div>
                        <Space>
                            <Typography.Link onClick={() => notification.close(key)}>
                                Close
                            </Typography.Link>
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => navigate(`/order/${orderForm.id}`)}
                            >
                                Go to contract
                            </Button>
                        </Space>
                    </>
                ),
                placement: "top",
                duration: 0,
                style: { width: 500 },
                key,
            });
        }
    };

    useEffect(() => {
        alert();
        return () => {
            notification.destroy();
        };
    }, [orderForm]);

    const tacticalPanel = (
        <>
            <UserGuideModal
                open={showUserGuide}
                onCancel={() => setShowUserGuide(false)}
                onSkip={() => updateUserGuide()}
                onOk={() => updateUserGuide()}
            />
            <NegotiationIntelligencePanel style={{ marginTop: 16 }} />
            <TopSuppliersPanel style={{ marginTop: 16 }} />
            <CalendarDashboard style={{ marginTop: 16 }} />
        </>
    );

    const overviewPanel = (
        <>
            <CostChartPanel style={{ marginTop: 16 }} selectedYear={selectedYear} />
            <VendorHeatmapPanel style={{ marginTop: 16 }} selectedYear={selectedYear} />
        </>
    );

    const content = tab === TabKey.Tactical ? tacticalPanel : overviewPanel;

    const panel = (
        <Card>
            <Row align="middle">
                <Col flex={1}>
                    <Segmented
                        options={tabKeys}
                        onChange={(value) => {
                            setTab(value as TabKey);
                        }}
                    />
                </Col>
                <Col>
                    <Select
                        options={yearSelectOptions}
                        value={selectedYear}
                        onChange={setSelectedYear}
                    />
                </Col>
            </Row>
            {content}
        </Card>
    );
    return <>{panel}</>;
};
