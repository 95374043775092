import React from "react";
import { SupplierBusinessDriver } from "../../../types/SupplierTypes";
import styles from "./Drivers.module.less";

interface DriversProps {
    data?: SupplierBusinessDriver[];
}

export const Drivers: React.FC<DriversProps> = ({ data }) => {
    if (!data?.length) return null;

    return (
        <>
            <div className={styles.title}> Key business drivers</div>

            <ul className={styles.content}>
                {data.map((item) => (
                    <li key={item.id}>
                        <span className={styles.itemName}>{item.name}: </span>
                        {item.description}
                    </li>
                ))}
            </ul>
        </>
    );
};
