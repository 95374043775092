import { UserAddOutlined, UsergroupAddOutlined } from "@ant-design/icons/lib/icons";
import { Select, Button, Card, Col, Form, Input, Modal, Row, Spin, Table } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    createGroup,
    createUser,
    listGroups,
    listUsers,
    updateGroup,
} from "../../common/api/UserClient";
import Avatar from "../../common/components/Avatar";
import { handleError } from "../../common/ErrorHandling";
import { Group, GroupUpdateRequest, User, UserRole } from "../../types/UserGroup";
import { userRoleToTag, userStatusToTag } from "../user/UserAttributes";

const { Column } = Table;

export interface InviteUserModalArgument {
    visible: boolean;
    onComplete: (data: User | undefined) => void;
}

export const InviteUserModal: React.FC<InviteUserModalArgument> = ({ visible, onComplete }) => {
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({} as User);
    const [form] = Form.useForm();

    useEffect(() => {
        setUser({} as User);
        setLoading(false);
        form.resetFields();
    }, [visible]);
    const modal = (
        <Modal
            title={"Invite User"}
            visible={visible}
            onOk={async () => {
                try {
                    const values = await form.validateFields();
                    setLoading(true);
                    onComplete(user);
                    console.log("Success:", values);
                } catch (errorInfo) {
                    console.log("Failed:", errorInfo);
                }
            }}
            onCancel={() => {
                form.resetFields();
                onComplete(undefined);
                setLoading(false);
            }}
        >
            <Spin spinning={loading}>
                <Form
                    form={form}
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 18 }}
                    layout="horizontal"
                    size={"small" as SizeType}
                >
                    <Form.Item name={["name"]} label="First Name" rules={[{ required: true }]}>
                        <Input
                            value={user.firstName}
                            onChange={(e) => {
                                user.firstName = e.target.value;
                                setUser({ ...user });
                            }}
                        />
                    </Form.Item>
                    <Form.Item name={["lastName"]} label="Last Name" rules={[{ required: true }]}>
                        <Input
                            value={user.lastName}
                            onChange={(e) => {
                                user.lastName = e.target.value;
                                setUser({ ...user });
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={["email"]}
                        label="Email"
                        rules={[{ type: "email", required: true }]}
                    >
                        <Input
                            value={user.email}
                            onChange={(e) => {
                                user.email = e.target.value;
                                setUser({ ...user });
                            }}
                        />
                    </Form.Item>
                    <Form.Item name={["roles"]} label="Roles">
                        <Select
                            mode="multiple"
                            onChange={(values) => {
                                user.roles = values as any[];
                                setUser({ ...user });
                            }}
                        >
                            {Object.values(UserRole).map((role) => (
                                <Select.Option value={role}>{role}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
    return modal;
};

export interface GroupOpModalArgument {
    visible: boolean;
    group: Group | undefined;
    onComplete: (group: Group | undefined) => void;
}

export const GroupOpModal: React.FC<GroupOpModalArgument> = ({ visible, group, onComplete }) => {
    const [loading, setLoading] = useState(false);
    const [eGroup, setEGroup] = useState({} as Group);
    const [form] = Form.useForm();

    useEffect(() => {
        if (group) {
            setEGroup({ ...group });
        }
    }, [group]);

    const modal = (
        <Modal
            title={group ? "Edit Group" : "New Group"}
            visible={visible}
            onOk={async () => {
                try {
                    if (!(eGroup as any).muttable) {
                        onComplete(undefined);
                        return;
                    }
                    const values = await form.validateFields();
                    setLoading(true);
                    onComplete(eGroup);
                    console.log("Success:", values);
                } catch (errorInfo) {
                    console.log("Failed:", errorInfo);
                }
            }}
            onCancel={() => {
                onComplete(undefined);
                setLoading(false);
            }}
        >
            <Spin spinning={loading}>
                <Form
                    form={form}
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 18 }}
                    layout="horizontal"
                    size={"small" as SizeType}
                >
                    <Form.Item label="Name" rules={[{ required: true }]}>
                        <Input
                            disabled={!(eGroup as any).muttable}
                            value={eGroup.name}
                            onChange={(e) => {
                                eGroup.name = e.target.value;
                                setEGroup({ ...eGroup });
                            }}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
    return modal;
};

export const AdminUserGroupsView: React.FC = () => {
    const [users, setUsers] = useState([] as User[]);
    const [groups, setGroups] = useState([] as Group[]);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [loadingGroups, setLoadingGroups] = useState(true);
    const [showInviteUser, setShowInviteUser] = useState(false);
    const [showGroupModal, setShowGroupModal] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(undefined as Group | undefined);
    const [reloadUsers] = useState(0);
    const [reloadGroups, setReloadGroups] = useState(0);

    useEffect(() => {
        setLoadingUsers(true);
        const loadUsers = async () => {
            const users = await listUsers();
            setUsers(users);
        };
        try {
            loadUsers();
        } catch (error: any) {
            handleError(error);
        } finally {
            setLoadingUsers(false);
        }
    }, [reloadUsers]);

    useEffect(() => {
        setLoadingGroups(true);
        const loadGroups = async () => {
            const groups = await listGroups();
            setGroups(groups);
        };
        try {
            loadGroups();
        } catch (error: any) {
            handleError(error);
        } finally {
            setLoadingGroups(false);
        }
    }, [reloadGroups]);

    const addUser = async (user: User) => {
        return await createUser(user);
    };

    const inviteUserModal = (
        <InviteUserModal
            visible={showInviteUser}
            onComplete={async (user) => {
                if (user) {
                    try {
                        const usr = await addUser(user);
                        setUsers(users.concat([usr]));
                        setShowInviteUser(false);
                    } catch (error: any) {
                        handleError(error);
                    }
                }
                setShowInviteUser(false);
            }}
        />
    );

    const createGroupAsync = async (group: Group): Promise<Group> => {
        return await createGroup(group);
    };
    const updateGroupAsync = async (group: Group): Promise<boolean> => {
        const updateRequest = {
            group: group,
            add: [] as number[],
            remove: [] as number[],
        } as GroupUpdateRequest;
        return updateGroup(updateRequest);
    };

    const groupModal = (
        <GroupOpModal
            group={selectedGroup}
            visible={showGroupModal}
            onComplete={async (group: Group | undefined) => {
                try {
                    if (group) {
                        if (group.id) {
                            const updatedGroup = await updateGroupAsync(group);
                            if (updatedGroup) {
                                setReloadGroups(reloadGroups + 1);
                            }
                        } else {
                            console.log("creating group");
                            const newGroup = await createGroupAsync(group);
                            setGroups(groups.concat([newGroup]));
                        }
                    }
                } catch (error: any) {
                    handleError(error);
                } finally {
                    setSelectedGroup({} as Group);
                    setShowGroupModal(false);
                }
            }}
        />
    );
    const usersPanel = (
        <Card
            size="small"
            title={<div>Users</div>}
            bodyStyle={{ padding: 0, minHeight: 400 }}
            extra={
                <Button type={"dashed"} onClick={() => setShowInviteUser(true)}>
                    <UserAddOutlined />
                </Button>
            }
        >
            <Table size="small" dataSource={users} loading={loadingUsers}>
                <Column
                    title=""
                    dataIndex="email"
                    render={(email: any) => {
                        return <Avatar email={email} />;
                    }}
                />
                <Column
                    title="email"
                    dataIndex="email"
                    render={(value: any, record: User) => {
                        return <Link to={`/admin/users/${record.id}`}>{value}</Link>;
                    }}
                />
                <Column title="First Name" dataIndex="firstName" />
                <Column title="Last Name" dataIndex="lastName" />
                <Column
                    title="Status"
                    dataIndex="status"
                    render={(value: any) => {
                        return userStatusToTag(value);
                    }}
                />
                <Column
                    title="Roles"
                    dataIndex="roles"
                    render={(value: any) => {
                        return value
                            ? value
                                  .filter((role: UserRole) => role !== UserRole.POC)
                                  .map((role: UserRole) => userRoleToTag(role))
                            : "";
                    }}
                />
            </Table>
        </Card>
    );
    const groupsPanel = (
        <Card
            size="small"
            title={<div>Groups</div>}
            bodyStyle={{ padding: 0, minHeight: 400 }}
            extra={
                <Button
                    type={"dashed"}
                    onClick={() => {
                        setShowGroupModal(true);
                    }}
                >
                    <UsergroupAddOutlined />
                </Button>
            }
        >
            <Table size="small" dataSource={groups} loading={loadingGroups}>
                <Column
                    title=""
                    dataIndex="name"
                    render={(value: any) => {
                        return <Avatar email={value} />;
                    }}
                />
                <Column
                    title="Name"
                    dataIndex="name"
                    render={(value: any, record: Group) => {
                        return (
                            <Button
                                type="link"
                                onClick={() => {
                                    setSelectedGroup(record);
                                    setShowGroupModal(true);
                                }}
                            >
                                {value}
                            </Button>
                        );
                    }}
                />
            </Table>
        </Card>
    );
    const content = (
        <div style={{ paddingTop: 10 }}>
            {groupModal}
            {inviteUserModal}
            <Card>
                <Row gutter={[8, 8]}>
                    <Col span={16}>{usersPanel}</Col>
                    <Col span={8}>{groupsPanel}</Col>
                </Row>
            </Card>
        </div>
    );
    return content;
};
