import React from "react";
import { Col, Row, Space, Typography, Button } from "antd";
import NumberFormat from "react-number-format";
import { SupplierWithEmployee } from "../../../types/SupplierTypes";
import { Quote } from "../../../types/FinanceTypes";
import { getTrendDataColor, roundToTwoDecimals } from "../../../common/Number";
import { SvgIcon } from "../../../common/components/SvgIcon";
import { CompanyImage } from "../../../common/components/CompanyImage";
import { ESGScore } from "./ESGScore";
import { SupplierScore } from "./SupplierScore";
import styles from "./SupplierBase.module.less";

interface SupplierBaseProps extends Partial<SupplierWithEmployee> {
    showPdfButton?: boolean;
    onPdfButtonClick?: () => void;
    quotes?: Quote[];
    showStockInfo?: boolean;
}

export const SupplierBase: React.FC<SupplierBaseProps> = ({
    name = "",
    logo = "",
    address = "",
    esgScore = 0,
    currentEmployee,
    employeeDifference = 0,
    website,
    linkedIn,
    twitter,
    showPdfButton = true,
    onPdfButtonClick,
    quotes,
    showStockInfo = true,
}) => {
    const home = website?.startsWith("http") ? website : `https://www.${website}`;

    const _showStockInfo = showStockInfo && quotes?.length;
    const quote = quotes?.[0];

    const socialNetworks = [
        {
            url: home,
            testid: "supplier-profile-website",
            icon: <SvgIcon name="global" size={20} />,
        },
        {
            url: linkedIn,
            testid: "supplier-profile-linkedin",
            icon: <SvgIcon name="linkedin" size={20} />,
        },
        {
            url: twitter,
            testid: "supplier-profile-twitter",
            icon: <SvgIcon name="x" size={20} />,
        },
    ];

    const priceChangeColor = getTrendDataColor(quote?.change || 0);

    const priceBlock = (
        <div className={styles.priceBlock}>
            <div className={styles.symbol}>
                {quote?.symbol} / {quote?.exchange}
            </div>
            <div>
                <Space size={12}>
                    <span className={styles.price}>{quote?.price} USD</span>
                    <span className={styles.priceChange} style={{ color: priceChangeColor }}>
                        {quote?.change}({roundToTwoDecimals(quote?.changePercentage || 0)}%)
                    </span>
                </Space>
            </div>
        </div>
    );

    return (
        <Row className={styles.container}>
            <Col>
                <CompanyImage logo={logo} name={name} size="xl" />
            </Col>
            <Col flex={1}>
                <div className={styles.info}>
                    <div className={styles.nameWrapper}>
                        <div className={styles.name}>{name}</div>
                        {_showStockInfo ? (
                            priceBlock
                        ) : (
                            <Typography.Link
                                className={styles.address}
                                href={`https://maps.google.com/?q=${encodeURIComponent(address)}`}
                                target="_blank"
                            >
                                {address}
                            </Typography.Link>
                        )}
                    </div>
                    {_showStockInfo ? null : (
                        <>
                            <Space direction="vertical" size={8}>
                                ESG score
                                <ESGScore score={esgScore} />
                            </Space>
                            <Space direction="vertical" size={8}>
                                Supplier score
                                <SupplierScore score={roundToTwoDecimals(esgScore / 20)} />
                            </Space>
                            <Space direction="vertical" size={8}>
                                Number of employees
                                <Space className={styles.employee}>
                                    <NumberFormat
                                        value={currentEmployee}
                                        className={styles.employeeNumber}
                                        displayType="text"
                                        thousandSeparator
                                    ></NumberFormat>
                                    {`${roundToTwoDecimals(employeeDifference * 100)}% Increase`}
                                </Space>
                            </Space>
                            <div className={styles.social}>
                                {socialNetworks.map((item) => {
                                    return item.url ? (
                                        <a
                                            href={item.url}
                                            target="_blank"
                                            rel="noreferrer"
                                            data-testid={item.testid}
                                            key={item.testid}
                                        >
                                            {item.icon}
                                        </a>
                                    ) : null;
                                })}
                            </div>
                        </>
                    )}
                    {showPdfButton ? (
                        <div className={styles.pdfButton}>
                            <Button type="primary" onClick={onPdfButtonClick}>
                                Create PDF
                            </Button>
                        </div>
                    ) : null}
                </div>
            </Col>
        </Row>
    );
};
