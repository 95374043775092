import React from "react";
import { Col, Row, Segmented } from "antd";
import { Period } from "../../../types/SupplierTypes";
import { IncomeStatement } from "../../../types/FinanceTypes";
import { formatNumberWithSuffix, getTrendDataColor } from "../../../common/Number";
import { FinancialOverviewCard } from "./FinancialOverviewCard";
import styles from "./FinancialOverview.module.less";

const periodOptions = Object.keys(Period);

interface FinancialOverviewProps {
    period: Period;
    data?: IncomeStatement[];
    onPeriodChange?: (period: Period) => void;
}

export const FinancialOverview: React.FC<FinancialOverviewProps> = ({
    data = [],
    period,
    onPeriodChange,
}) => {
    const currentIncome = data?.[0];
    const previousIncome = data?.[1];
    const overviews = [
        {
            title: "Revenue",
            tip: "The total income generated from goods sold or services provided during a certain time frame.",
            data: formatNumberWithSuffix(currentIncome?.revenue || 0),
            dataColor: getTrendDataColor(
                Number(currentIncome?.revenue) - Number(previousIncome?.revenue)
            ),
        },
        {
            title: "Gross profit",
            tip: "The profit made after subtracting all (variable) costs that are related to manufacturing of its products or services. The gross profit can be calculated by deducting the cost of goods sold (COGS) from total sales.",
            data: formatNumberWithSuffix(currentIncome?.grossProfit || 0),
            dataColor: getTrendDataColor(
                Number(currentIncome?.grossProfit) - Number(previousIncome?.grossProfit)
            ),
        },
        {
            title: "Net income",
            tip: "The total profit of the company after all expenses, including taxes and other deductions, have been taken into account.",
            data: formatNumberWithSuffix(currentIncome?.netIncome || 0),
            dataColor: getTrendDataColor(
                Number(currentIncome?.netIncome) - Number(previousIncome?.netIncome)
            ),
        },
    ];

    const currency = data.length > 0 && data?.[0].reportedCurrency !== "USD" ?
                    <small>
                        (Financial figures are in <b>{data?.[0]?.reportedCurrency}</b>)
                    </small> :
                    <></>

    return (
        <>
            <Row className={styles.titleRow}>
                <Col flex={1} className={styles.title}>
                    Overview {currency}
                </Col>

                <Col>
                    <Segmented
                        options={periodOptions}
                        value={period}
                        onChange={(value) => onPeriodChange?.(value as Period)}
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                {overviews.map((item) => (
                    <Col span={8} key={item.title}>
                        <FinancialOverviewCard {...item} />
                    </Col>
                ))}
            </Row>
        </>
    );
};
