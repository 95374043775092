import React from "react";
import { Card } from "antd";
import ReviewList from "./ReviewList";

const ReviewListPage: React.FC = () => {
    const mainPanel = (
        <div className="contract-panel" style={{ paddingTop: 10 }}>
            <Card>
                <ReviewList />
            </Card>
        </div>
    );
    return mainPanel;
};

export default ReviewListPage;
