import React, { useEffect, useState } from "react";
import { Form, Input, Modal, ModalProps } from "antd";
import { NegotiationStrategy } from "../../../types/ReportTypes";
import NumberFormat from "react-number-format";

interface NegotiationStrategyModalProps extends Omit<ModalProps, "onOk"> {
    strategy?: NegotiationStrategy;
    onOk?: (strategy: NegotiationStrategy) => void;
}
export const NegotiationStrategyModal: React.FC<NegotiationStrategyModalProps> = ({
    strategy,
    onOk,
    ...restProps
}) => {
    const [form, setForm] = useState({} as NegotiationStrategy);

    useEffect(() => {
        strategy && setForm(strategy);
    }, [strategy]);

    const modal = (
        <>
            <Modal
                {...restProps}
                onOk={() => {
                    onOk?.(form);
                }}
            >
                <Form layout="horizontal" size="small" labelCol={{ span: 4 }}>
                    <Form.Item label="Leverage point">
                        <Input
                            value={form.name}
                            onChange={(e) => {
                                setForm((old) => ({
                                    ...old,
                                    name: e.target.value,
                                }));
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Negotiation ask">
                        <Input
                            value={form.ask}
                            onChange={(e) => {
                                setForm((old) => ({
                                    ...old,
                                    ask: e.target.value,
                                }));
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Potential savings (Low end)">
                        <NumberFormat
                            className={"ant-input"}
                            value={form.minSavings || 0}
                            thousandSeparator={true}
                            displayType={"input"}
                            prefix={"$"}
                            onValueChange={(value) => {
                                setForm((old) => ({
                                    ...old,
                                    minSavings: value.floatValue || 0,
                                }));
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Potential savings (High end)">
                        <NumberFormat
                            className={"ant-input"}
                            value={form.maxSavings || 0}
                            thousandSeparator={true}
                            displayType={"input"}
                            prefix={"$"}
                            onValueChange={(value) => {
                                setForm((old) => ({
                                    ...old,
                                    maxSavings: value.floatValue || 0,
                                }));
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
    return <>{modal}</>;
};
