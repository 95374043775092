import React from "react";
import { Table, Tag } from "antd";
import { Link } from "react-router-dom";
import { CompanyImageCell } from "../../../common/components/CompanyImageCell";
import { formatNumberWithSuffix, roundToTwoDecimals } from "../../../common/Number";
import { SvgIcon } from "../../../common/components/SvgIcon";
import { ExpirationDateCell } from "../../../common/components/ExpirationDateCell";
import { SupplierRisk } from "../../../types/DashboardTypes";
import { NegotiationStrategy } from "../../../types/ReportTypes";
import { SimulationScore } from "./SimulationScore";

interface TopSuppliersTableProps {
    data: SupplierRisk[];
}

export const TopSuppliresTable: React.FC<TopSuppliersTableProps> = ({ data }) => {
    const columns = [
        {
            title: "Vendor name",
            dataIndex: "name",
            key: "Name",
            render: (value: string, row: SupplierRisk) => {
                return (
                    <CompanyImageCell
                        nameWrap
                        name={value}
                        logo={row.logo}
                        domain={row.website}
                        link={`/vendor/${row.vendorKey}`}
                        linkedin={row.linkedIn}
                        twitter={row.twitter}
                    />
                );
            },
        },
        {
            title: "Contract name",
            dataIndex: "orderFormName",
            key: "orderFormName",
            render: (value: string, row: SupplierRisk) => {
                return <Link to={`/order/${row.orderFormId}`}>{value}</Link>;
            },
        },
        {
            title: "Contract score",
            dataIndex: "orderScore",
            key: "orderScore",
            width: 132,
            render: (value: number, row: SupplierRisk) => {
                return (
                    <Link to={`/order/score/${row.orderFormId}`}>
                        <SimulationScore score={roundToTwoDecimals(value || 0)} />
                    </Link>
                );
            },
        },
        {
            title: "Contract value",
            dataIndex: "value",
            key: "value",
            width: 130,
            render: (value: number, row: SupplierRisk) => {
                return (
                    <Link to={`/order/${row.orderFormId}`}>
                        {`$${formatNumberWithSuffix(value)}`}
                    </Link>
                );
            },
        },
        {
            title: "Estimated savings",
            dataIndex: "decrease",
            key: "decrease",
            width: 154,
            render: (value: string, row: SupplierRisk) => {
                return (
                    <Link to={`/order/${row.orderFormId}`}>
                        <Tag color="processing">{`$${formatNumberWithSuffix(Number(value))}`}</Tag>
                    </Link>
                );
            },
        },
        {
            title: "Expiration",
            dataIndex: "expirationDate",
            key: "expirationDate",
            width: 130,
            render: (value: string | undefined, row: SupplierRisk) => {
                return <ExpirationDateCell date={value} orderFormId={row.orderFormId} />;
            },
        },
        {
            title: "Top negotiation strategies",
            dataIndex: "strategies",
            key: "strategies",
            render: (value: NegotiationStrategy[], row: SupplierRisk) => {
                if (!value?.length) return null;
                return (
                    <>
                        <div>{value?.[0]?.ask}</div>
                        <Link to={`/order/${row.orderFormId}`}>View more</Link>
                    </>
                );
            },
        },
        {
            title: "",
            key: "action",
            width: 100,
            render: (_: any, row: SupplierRisk) => {
                const { negotiationId = "", id = "" } = row;
                return (
                    <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                        <Link to={`/order/${row.orderFormId}`}>
                            <SvgIcon name="forms" size={12} />
                        </Link>
                        <Link to={`/negotiations/${negotiationId || ""}`}>
                            <SvgIcon name="group" size={12} />
                        </Link>
                        <Link to={`/supplier/${id}`}>
                            <SvgIcon name="briefs" size={12} />
                        </Link>
                    </div>
                );
            },
        },
    ];

    return (
        <Table
            dataSource={data}
            rowKey="orderFormId"
            columns={columns}
            pagination={false}
            scroll={{ y: 420 }}
        />
    );
};
