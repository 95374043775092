import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useUserState } from "./common/UserContext";
import { UserPreferencesView } from "./components/user/UserPreferencesView";
import { Calendar } from "./components/calendar/Calendar";
import { AdminUserGroupsView } from "./components/admin/AdminUserGroupsView";
import { AdminUserView } from "./components/admin/AdminUserView";
import { InviteView } from "./components/user/InviteView";
import UploadSummaryView from "./components/orderform/UploadSummaryView";
import { ReviewListCompleted } from "./components/review/ReviewList";
import ReviewListPage from "./components/review/ReviewListPage";
import ReviewDetail from "./components/review/ReviewDetail";
import { SupplierView } from "./components/supplier/SupplierView";
import { DashboardView } from "./components/dashboard-v2/DashboardView";
import { OrderFormScoreView } from "./components/orderform/OrderFormScoreView";
import { WristbandLogin } from "./components/login/WristbandLogin";
import { Login } from "./components/login/Login";
import { FeatureFlagsView } from "./components/admin/FeatureFlagsView";
import { OrderFormDeteailsV2 } from "./components/orderform/OrderFormDetailsV2";
import { isOnboarding, isSuperAdmin, UserRole } from "./types/UserGroup";
import { UserImpersonationView } from "./components/admin/UserImpersonationView";
import { IndustryView } from "./components/superadmin/IndustryView";
import { SupplierDetailViewV2 } from "./components/supplier/SupplierDetailViewV2";
import AppLayout from "./components/layout/AppLayout";
import { Redirect } from "react-router6-redirect";
import ProtectedRoute from "./components/layout/ProtectedRoute";
import { NegotiationDetailsView } from "./components/negotiations/NegotiationDetailsView";
import { NegotiationsView } from "./components/negotiations/NegotiationsView";
import { NotificationsView } from "./components/notifications/NotificationsView";
import OrderFormsView from "./components/orderform/OrderFormsView";
import { OrganizationView } from "./components/vendor/OrganizationView";
import { VendorDetails } from "./components/vendor/VendorDetails";
import { VendorsView } from "./components/vendor/VendorsView";
import { OrderFormsView as OrderFormsViewV2 } from "./components/orderform/OrderFormsViewV2";
import { DashboardView as DashboardViewV3 } from "./components/dashboard-v3/DashboardView";
import { POCReportView } from "./components/vendor/POCReportView";
import { CustomerSelector } from "./components/extraction/CustomerSelector";
import { CustomerContainer } from "./components/extraction/CustomerContainer";

export const AppRoutes: FC = () => {

    const { me } = useUserState()

    const homePageElement = 1==1 || isSuperAdmin(me.roles ?? []) ? <DashboardViewV3/> : <DashboardView/>;
    
    const indexElement = isOnboarding(me.roles ?? []) ? <Navigate to="/order/new"/> : homePageElement;

    return (
        <>
            <Routes>
                <Route path="internal">
                    <Route path="login" element={<Login />} />
                </Route>
                <Route path="/" element={<AppLayout/>} >
                    {/* These routes are always available */}
                    <Route index element={ indexElement } />
                    <Route path="login" element={<WristbandLogin />} />
                    <Route path="preferences" element={<UserPreferencesView />} />

                    {/* This route is only available to people onboarding or admins */}
                    <Route element={<ProtectedRoute accessType={"GRANT"} roles={[UserRole.ONBOARDING, UserRole.ADMIN]}/>}>
                        <Route path="order/new" element={<OrderFormsViewV2 />} />
                    </Route>

                    {/* These routes are only available if you are NOT onboarding */}
                    <Route element={<ProtectedRoute accessType={"DENY"} roles={[UserRole.ONBOARDING]} />}>
                        <Route path="calendar" element={<Calendar />} />
                        <Route path="dashboard" element={<Navigate to="/" />}/>
                        <Route path="notifications" element={<NotificationsView />} />
                        <Route path="invite/accept/:inviteId" element={<InviteView />} />

                        <Route path="order">
                            <Route path=":orderFormId" element={<OrderFormDeteailsV2 />} />
                            <Route path="score/:orderFormId" element={<OrderFormScoreView />} />
                            <Route path="list" element={<OrderFormsView />} />
                        </Route>

                        <Route path="review">
                            <Route index element={<ReviewListPage />} />
                            <Route path="completed" element={<ReviewListCompleted />} />
                            <Route path=":documentId" element={<ReviewDetail />} />
                        </Route>

                        <Route path="vendor">
                            <Route index element={<VendorsView />} />
                            <Route path=":vendorKey" element={<VendorDetails />} />
                        </Route>
                        
                        <Route path="negotiations">
                            <Route index element={<NegotiationsView />} />
                            <Route path=":negotiationId" element={<NegotiationDetailsView />} />
                        </Route>
                        
                        <Route path="supplier">
                            <Route index element={<SupplierView />} />
                            <Route path=":id" element={<SupplierDetailViewV2 />} />
                        </Route>

                        {/* These routes are only available to admins */}
                        <Route element={<ProtectedRoute accessType={"GRANT"} roles={[UserRole.ADMIN]}/>}>
                            <Route path="order/new/:uploadId" element={<UploadSummaryView />} />
                            
                            <Route path="admin">
                                <Route path="users">
                                    <Route index element={<AdminUserGroupsView />} />
                                    <Route path=":userId" element={<AdminUserView />} />
                                </Route>
                            </Route>
                        </Route>

                        {/* These routes are only available to Super Admins */}
                        <Route element={<ProtectedRoute accessType={"GRANT"} roles={[UserRole.SUPER_ADMIN]}/>}>
                            <Route path="superadmin" >
                              <Route path="industry" element={<IndustryView />} />
                              <Route path="report/:orderFormId" element={<POCReportView />} />
                              <Route path="extraction" element={<CustomerSelector/>} />
                              <Route path="extraction/customer/:customerId" element={<CustomerContainer/>} />
                            </Route>

                            <Route path="admin">
                                <Route path="orgs" element={<OrganizationView />} />
                                <Route path="flags" element={<FeatureFlagsView />} />
                                <Route path="impersonation" element={<UserImpersonationView />} />
                            </Route>
                            
                        </Route>
                    </Route>
                    
                    {/* These are just redirects from old paths with "/app" in them which has been deprecated */}
                    <Route path="app">
                        <Route index element={ <Redirect to="/" /> } />
                        <Route path="review" element={<Redirect to="/review" />} />
                        <Route path="review/completed" element={<Redirect to="/review/completed" />} />
                        <Route path="review/:documentId" element={<Redirect to="/review/:documentId" />} />
                        <Route path="order/:orderFormId" element={<Redirect to="/order/:orderFormId" />} />
                        <Route path="order/list" element={<Redirect to="/order/list" />} />
                        <Route path="order/new" element={<Redirect to="/order/new" />} />
                        <Route path="order/new/:uploadId" element={<Redirect to="/order/new/:uploadId" />} />
                        <Route path="order/score/:orderFormId" element={<Redirect to="/order/score/:orderFormId" />} />
                        <Route path="calendar" element={<Redirect to="/calendar" />} />
                        <Route path="dashboard" element={<Redirect to="/" />}/>
                        <Route path="vendor/:vendorKey" element={<Redirect to="/vendor/:vendorKey" />} />
                        <Route path="vendor" element={<Redirect to="/vendor" />} />
                        <Route path="notifications" element={<Redirect to="/notifications" />} />
                        <Route path="preferences" element={<Redirect to="/preferences" />} />
                        <Route path="login" element={<Redirect to="/login" />} />
                        <Route path="negotiations" element={<Redirect to="/negotiations" />} />
                        <Route path="negotiations/:negotiationId" element={<Redirect to="/negotiations/:negotiationId" />} />
                        <Route path="supplier" element={<Redirect to="/supplier" />} />
                        <Route path="supplier/:id" element={<Redirect to="/supplier/:id" />} />
                        <Route path="internal/supplier/:id" element={<Redirect to="/internal/supplier/:id" />} />
                    </Route>
                    
                    {/* Any unknown path redirects to the index path */}
                    <Route path="*" element={<Navigate to="/"/>}/>
                </Route>
            </Routes>
        </>
    )
}
