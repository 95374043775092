import { CSSProperties } from "react";
import { Col, Row, Image } from "antd";
import NumberFormat from "react-number-format";
import { fallbackImage } from "../../common/ImagesUtil";
import { OrderForm } from "../../types/OrderFormTypes";
import { Vendor } from "../../types/VendorTypes";
import styles from "./VendorMiniPanel.module.less";

export interface VendorMiniPanelArguments {
    vendor: Vendor;
    orders: OrderForm[];
    style?: CSSProperties;
}

export const VendorMiniPanel: React.FC<VendorMiniPanelArguments> = ({ vendor, orders, style }) => {
    const content = (
        <Row align={"middle"} key={`minipanel-${vendor.vendorKey}`} style={style}>
            <Col>
                <Image width={24} preview={false} src={vendor.hero} fallback={fallbackImage} />
            </Col>
            <Col className={styles.contractValue}>
                <Row>
                    <NumberFormat
                        value={orders.reduce((p: number, v: OrderForm) => p + v.value, 0)}
                        thousandSeparator={true}
                        displayType={"text"}
                        prefix={"$"}
                    />
                </Row>
            </Col>
        </Row>
    );
    return <>{content}</>;
};
