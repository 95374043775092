import { Button, Card, Col, Form, Input, Layout, Radio, Result, Row, Spin } from "antd";
import { Content } from "antd/lib/layout/layout";
import { GoogleOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { AuthStrategy, User } from "../../types/UserGroup";
import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";
import { getUserInvite, registerUser } from "../../common/api/UserClient";
import { handleError } from "../../common/ErrorHandling";

export const InviteView: React.FC = () => {
    const { inviteId } = useParams();
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({} as User);
    const [usePassword, setUsePassword] = useState(true);
    const [password, setPassword] = useState("");
    const [notFound, setNotFound] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (inviteId === undefined) {
            setNotFound(true);
        }
        const getInvite = async () => {
            try {
                const user = await getUserInvite(inviteId as string);
                setUser(user);
            } catch (error: any) {
                console.log(error);
                setNotFound(true);
            } finally {
                setLoading(false);
            }
        };
        getInvite();
    }, []);

    const register = async () => {
        setLoading(true);
        try {
            const result = await registerUser(inviteId!, {
                user: user,
                password: password,
                strategy: usePassword ? AuthStrategy.PWD : AuthStrategy.GOOGLE,
            });
            if (result) {
                navigate("/login");
            }
        } catch (error: any) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const pwd = (
        <>
            <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: "Please input your password!" }]}
            >
                <Input.Password
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }}
                />
            </Form.Item>
        </>
    );
    const userPanel = (
        <Form
            name="login"
            initialValues={{ remember: true }}
            action={"/login/password"}
            onFinish={() => {}}
            autoComplete="off"
        >
            <Form.Item label={"First Name"} style={{ paddingTop: 10 }}>
                <Input
                    value={user.firstName}
                    onChange={(e) => {
                        setUser({ ...user, firstName: e.target.value });
                    }}
                />
            </Form.Item>
            <Form.Item label={"Last Name"}>
                <Input
                    value={user.lastName}
                    onChange={(e) => {
                        setUser({ ...user, lastName: e.target.value });
                    }}
                />
            </Form.Item>
            <Form.Item label="email">
                <Input value={user.email} disabled={true} />
            </Form.Item>

            <Form.Item label="Authentication" name="auth">
                <Radio.Group
                    defaultValue="password"
                    buttonStyle="solid"
                    onChange={(e) => {
                        if (e.target.value !== "password") {
                            setUsePassword(false);
                        } else {
                            setUsePassword(true);
                        }
                    }}
                >
                    <Radio.Button value="password">
                        <LockOutlined />
                        &nbsp;Password
                    </Radio.Button>
                    <Radio.Button value="google">
                        <GoogleOutlined />
                        &nbsp;Google
                    </Radio.Button>
                </Radio.Group>
            </Form.Item>
            {usePassword ? pwd : <></>}
        </Form>
    );

    const content = (
        <Row align={"middle"} justify="center" style={{ height: "100vh" }}>
            <Col style={{ height: "100vh" }}>
                {notFound ? (
                    <Result
                        style={{ top: "20%" }}
                        status="404"
                        title="404"
                        subTitle="Sorry, the page you visited does not exist."
                    />
                ) : (
                    <Card
                        style={{ top: "20%" }}
                        title={"Complete your registration"}
                        actions={[
                            <Button type="primary" onClick={() => register()}>
                                Complete registration
                            </Button>,
                        ]}
                    >
                        <Spin spinning={loading}>
                            <Row>
                                <Col span={24}>{userPanel}</Col>
                            </Row>
                        </Spin>
                    </Card>
                )}
            </Col>
        </Row>
    );

    const layour = (
        <Layout
            style={{
                minHeight: "100vh",
                backgroundImage: `url("https://www.infinityloop.ai/assets/mountains.png")`,
            }}
        >
            <Spin spinning={loading}>
                <Content>{content}</Content>
            </Spin>
        </Layout>
    );

    return layour;
};
