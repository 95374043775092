import React from "react";
import { Divider, Table } from "antd";
import { Ratio } from "../../types/FinanceTypes";
import { roundToTwoDecimals } from "../../common/Number";
import styles from "./SupplierRatiosPanel.module.less";

const { Column } = Table;

interface RatioRow {
    label: string;
    value?: number;
    numerator: string;
    denominator: string;
    description: string;
}

interface SupplierRatiosPanelProps {
    ratios?: Ratio[];
}

export const SupplierRatiosPanel: React.FC<SupplierRatiosPanelProps> = ({ ratios }) => {
    const ratio = ratios?.[0];
    const dataSource: RatioRow[] = [
        {
            label: "Current Ratio",
            value: roundToTwoDecimals(ratio?.currentRatio || 0),
            numerator: "CurrentAssets",
            denominator: "CurrentLiabilities",
            description:
                "A current ratio of 1.0 or greater is an indication that the company is well-positioned to cover its current or short-term liabilities.",
        },
        {
            label: "Price-to-Earnings ratio (P/E)",
            value: roundToTwoDecimals(ratio?.priceToEarningsRatio || 0),
            numerator: "price",
            denominator: "(netIncome / shareNumber)",
            description:
                "The financial reporting of both companies and investment research services use a basic earnings per share (EPS) figure divided into the current stock price to calculate the P/E multiple (i.e. how many times a stock is trading (its price) per each dollar of EPS).",
        },
        {
            label: "Price to Sales Ratio",
            value: roundToTwoDecimals(ratio?.priceToSalesRatio || 0),
            numerator: "price",
            denominator: "(revenue / shareNumber)",
            description:
                "The P/E ratio and P/S reflects how many times investors are paying for every dollar of a company's sales. Since earnings are subject, to one degree or another, to accounting estimates and management manipulation, many investors consider a company's sales (revenue) figure a more reliable ratio component in calculating a stock's price multiple than the earnings figure.",
        },
        {
            label: "Price-to-Cash Flow ratio (P/CF)",
            value: roundToTwoDecimals(ratio?.priceToFreeCashFlowRatio || 0),
            numerator: "price",
            denominator: "(operatingCashFlow / shareNumber)",
            description:
                "The price/cash flow ratio is used by investors to evaluate the investment attractiveness, from a value standpoint, of a company's stock.",
        },
    ];
    return (
        <Table dataSource={dataSource} pagination={false}>
            <Column
                title="Ratio"
                key="ratio"
                render={(_, row: RatioRow) => {
                    return (
                        <>
                            <div className={styles.label}>{row.label}</div>
                            <div>{row.value}</div>
                        </>
                    );
                }}
            ></Column>
            <Column
                title="Formula"
                key="formula"
                width={360}
                render={(_, row: RatioRow) => {
                    return (
                        <>
                            <div className={styles.numerator}>{row.numerator}</div>
                            <Divider className={styles.divider} />
                            <div className={styles.denominator}>{row.denominator}</div>
                        </>
                    );
                }}
            ></Column>
            <Column
                title="Description"
                dataIndex="description"
                key="description"
                width={700}
            ></Column>
        </Table>
    );
};
